import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Swiper from 'react-id-swiper'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SwiperCore, { A11y, Controller, Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import SlideInfo from '../Subscription/components/Plans/components/SlideInfo'

import { formatDate } from '../../constants/validate'

import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Spinner from '../../components/Spinner'

import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/swiper.scss'
import './index.scss'

SwiperCore.use([Controller, Navigation, Pagination, Scrollbar, A11y])

/*
  Exemplo de estrutura da lista de planos
  {id:number,header:"",description:"",value:"",numberOfNotifications:"",info:[bool,bool,bool,bool,bool],discount:{tag:"",originalValue:""},recommended:bool}
*/
function MyPlan() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { singlePlan } = useSelector(state => state.subscription)
  const { user } = useSelector(state => state.login)
  const { cicleDate } = user.subscription

  const [plansSubscriptions, setPlansSubscriptions] = useState([])
  const [planChecked, setPlanChecked] = useState(0)
  const [isScreenXl, setIsScreenXL] = useState(false)
  const [showModalCancelPlan, setShowModalCancelPlan] = useState(false)
  const [loading, setLoading] = useState(false)

  // Observa redimencionamento da página
  useEffect(() => {
    const resizeListener = () => {
      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (screenWidth >= 960) {
        setIsScreenXL(true)
      } else {
        setIsScreenXL(false)
      }
    }
    resizeListener()
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(user.token)) {
      setLoading(true)
      axios
        .get('/user/my-plan')
        .then(response => {
          setLoading(false)
          setPlansSubscriptions(response.data.plans)
        })
        .catch(err => {
          console.log(err)
          showError('Ocorreu um erro')
        })
    }
  }, [user])

  // Seleciona o plano atual caso usuário esteja logado
  useEffect(() => {
    if (user.subscription.planId) {
      // dispatch({ type: 'CHANGE_SUBSCRIPTION_SINGLEPLAN', payload: false })
      const currentPlan = plansSubscriptions.findIndex(e => e.planId === user.subscription.planId)
      setPlanChecked(currentPlan)
    }
  }, [dispatch, plansSubscriptions, user])

  // function setCoupon (value) {
  //   dispatch({ type: 'CHANGE_SUBSCRIPTION_COUPON', payload: value})
  // }

  function showError(error, subError = false) {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
    }, 5000)
  }

  const returnPlansList = () => {
    return plansSubscriptions
  }

  function handleCheckPlan(index) {
    setPlanChecked(index)
  }
  function handleSelectPlan(plan) {
    setShowModalCancelPlan(true)
  }
  function handleCancelPlan() {
    axios
      .delete('/subscription/cancel')
      .then(response => {
        dispatch({ type: 'CHANGE_LOGIN_SUBSCRIPTION' })
        //changeStep(5)
        history.push('/planos/cancelado')
        removeSubscriptionFromStorage(user)
      })
      .catch(e => {
        showError('Erro ao cancelar o plano. Tente novamente mais tarde.')
      })
  }

  function removeSubscriptionFromStorage(newUser) {
    newUser.subscription.planId = null
    newUser.subscription.id = null
    newUser.subscription.maxQuota = null
    newUser.subscription.plan = null
    newUser.subscription.status = 'canceled'
    localStorage.setItem('user', JSON.stringify(newUser))
  }

  const showMonth = () => (!singlePlan ? '/mês' : '')

  return (
    <div className='my-plan'>
      <div className='my-plan__container'>
        <div className='my-plan__header'>
          <h2>Meu plano</h2>
          <p>
            As notificações compradas são acumulativas. Você pode cancelar sua assinatura mensal
            quando quiser.
          </p>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className='my-plan'>
            <div className='my-plan__container'>
              <Swiper
                className='my-plan__swiper'
                //spaceBetween={8}
                slidesPerView={'auto'}
                centeredSlides
                rebuildOnUpdate
                breakpoints={{
                  1: {
                    slidesPerView: 'auto',
                    spaceBetweenSlides: 50,
                  },
                  720: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 50,
                  },
                  960: {
                    slidesPerView: 3,
                    spaceBetweenSlides: 50,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetweenSlides: 64,
                  },
                }}>
                {returnPlansList().map((e, i) => (
                  <SwiperSlide
                    key={i}
                    className={`my-plan__swiper__slide ${
                      planChecked === i ? 'my-plan__swiper__slide--selected' : ''
                    } swiper-no-swiping`}
                    onClick={() => handleCheckPlan(i)}>
                    <div className='my-plan__swiper__slide__info'>
                      <SlideInfo
                        element={e}
                        showMonth={showMonth}
                        handleSelectPlan={handleSelectPlan}
                        user={user}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </div>

      <Modal
        show={showModalCancelPlan}
        title='Deseja mesmo cancelar seu plano?'
        titleWidth={256}
        renderFooter={
          <div>
            <Button action={true} type={6} onClick={() => setShowModalCancelPlan(false)}>
              Voltar
            </Button>
            <Button action={true} type={7} onClick={handleCancelPlan}>
              Cancelar plano
            </Button>
          </div>
        }>
        <p>
          O cancelamento passa a vigorar ao fim desse ciclo de cobrança:{' '}
          <strong>{formatDate(cicleDate, false, true)}.</strong>
        </p>
        <br />
        <p>Você ainda poderá enviar notificações não utilizadas neste mês.</p>
      </Modal>
    </div>
  )
}

export default MyPlan
