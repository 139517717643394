import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import './index.scss'

function Login({ goto }) {
  let history = useHistory()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [loginMessage, setLoginMessage] = useState('')

  const [showForgetPassword, setShowForgetPassword] = useState(false)

  useEffect(() => {
    setLoginError('')
  }, [showForgetPassword])

  function handleClickNewPassword() {
    setLoading(true)
    axios
      .post('/user/email-password', { email: email })
      .then(response => {
        setLoading(false)
        clearUserCredentials()
        setLoginMessage('Enviamos um email para você redefinir sua senha.')
        setLoginError('')
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        clearUserCredentials()
        setLoginMessage('')
        setLoginError('Redefinição incompleta. Tente novamente mais tarde.')
      })
  }

  function clearUserCredentials() {
    setEmail('')
    setPassword('')
  }

  function getGreeting() {
    const hour = new Date().getHours()

    if (hour > 5 && hour < 12) return 'Bom dia'
    else if (hour >= 12 && hour < 18) return 'Boa tarde'
    else return 'Boa noite'
  }

  function setUser(user) {
    dispatch({ type: 'CHANGE_LOGIN_USER', payload: user })
  }

  function setFetch(bool) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_FETCH', payload: bool })
  }

  function setNotifications(notifications) {
    dispatch({
      type: 'CHANGE_NOTIFICATIONS_NOTIFICATIONS',
      payload: notifications,
    })
  }

  async function login(e) {
    e.preventDefault()
    setLoading(true)
    try {
      let userResponse = await axios.post('/user/login', { email, password })

      localStorage.setItem('user', JSON.stringify(userResponse.data))
      axios.defaults.headers.common['authentication'] = userResponse.data.token
      setUser(userResponse.data)
      history.push('/notificacoes')
      // setFetch(true)

      // axios
      //   .post('/notifications/retrieve-notifications')
      //   .then(response => {
      //     setNotifications(response.data.notifications)
      //     history.push('/notificacoes')
      //     setLoading(false)
      //     setFetch(false)
      //   })
      //   .catch(e => {
      //     console.log(e)
      //   })
    } catch (e) {
      console.log(e)
      setLoginError('Usuário e/ou senha inválidos')
      setLoading(false)
    }
  }

  function changeToForgetPassword(boll) {
    setLoginMessage('')
    setLoginMessage('')
    setShowForgetPassword(boll)
  }

  return (
    <div className='login'>
      <section>
        <header>
          <div className='login__logo'>
            <Logo />
          </div>
        </header>
        {!showForgetPassword && (
          <main className='login__form'>
            <p>Fazer login</p>
            <div className='login__form__greetings'>
              {getGreeting()}. Ficamos felizes em tê-lo conosco.
            </div>
            <form className='login__container' onSubmit={e => login(e)}>
              <Input
                type='2'
                placeholder='Email'
                onChange={setEmail}
                value={email}
                inputType='email'
              />
              <Input type='6' placeholder='Senha' onChange={setPassword} value={password} />
              <div
                className={
                  loginMessage
                    ? 'login__form__message login__form__message--visible'
                    : 'login__form__message'
                }
                dangerouslySetInnerHTML={{ __html: loginMessage }}
              />
              <div
                className={
                  loginError
                    ? 'login__form__error login__form__error--visible'
                    : 'login__form__error'
                }
                dangerouslySetInnerHTML={{ __html: loginError }}
              />
              <Button
                type='1'
                id='submit'
                chevronRight={true}
                loading={loading}
                submit={true}
                login={true}
                style={{ minHeight: 58, fontSize: 14, width: '100%' }}>
                Entrar
              </Button>
            </form>
            <div className='login__form__footer'>
              <div
                className='login__form__forget__password'
                onClick={() => changeToForgetPassword(true)}>
                Esqueceu sua senha?
              </div>
              <a
                className='login__form__forget__password'
                href='https://semprocesso.com.br/notificca/home?utm_id=site_org&utm_source=site_login&utm_medium=botao_tela-login&utm_campaign=semprocesso_notificca&utm_content=site_organico_geral&utm_term=&utm_marketing_tactic=organico'
                target='_blank'
                rel='noopener noreferrer'>
                Quero uma conta
              </a>
            </div>
          </main>
        )}
        {showForgetPassword && (
          <main className='login__form'>
            <p>Esqueci minha senha</p>
            <div className='login__form__greetings'>Informe seu email e aguarde nosso contato</div>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleClickNewPassword()
              }}>
              <Input
                type='2'
                placeholder={'Email'}
                inputType='email'
                value={email}
                onChange={setEmail}
              />
              <div
                className={
                  loginMessage
                    ? 'login__form__message login__form__message--visible'
                    : 'login__form__message'
                }
                dangerouslySetInnerHTML={{ __html: loginMessage }}
              />
              <div
                className={
                  loginError
                    ? 'login__form__message login__form__message--visible'
                    : 'login__form__message'
                }
                dangerouslySetInnerHTML={{ __html: loginError }}
              />
              <Button
                type='1'
                id='submit'
                chevronRight={true}
                loading={loading}
                onClick={e => {
                  e.preventDefault()
                  handleClickNewPassword()
                }}
                style={{ minHeight: 58, fontSize: 14, width: '100%' }}>
                Redefinir Senha
              </Button>
            </form>
            <div
              className='login__form__forget__password'
              onClick={() => changeToForgetPassword(false)}>
              Voltar para o login
            </div>
          </main>
        )}
        <footer>
          {/* Ao fazer login você concorda com a nossa <a href='#' target='_blank'>Política de privacidade</a>. */}
        </footer>
      </section>
      <div className='login__image'>
        {/* NASDAQ 2025
        <span role="img" aria-label="rocket">🚀</span> */}
      </div>
    </div>
  )
}

export default Login
