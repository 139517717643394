import React from "react";
import Footer from "../../components/Footer";
import { cancellationPolicy } from './constants'
import './index.scss'

const CampaignPolicy = () => {

  return (
    <div className="campaign-policy">
      <div className='campaign-policy__title'>
        <div className='campaign-policy__title__content'>REGULAMENTO</div>
      </div>
      {cancellationPolicy.map((item, index) => {
        let char = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n']
          return (
            <div className="campaign-policy__text">
              {item.header&&<div className="campaign-policy__header">{item.header}</div>}
              {item.paragraphs &&
                item.paragraphs.map(text => {
                  return (<div className="campaign-policy__paragraph" dangerouslySetInnerHTML={{ __html: text}}></div>)
                })}
              {item.list &&
                item.list.map((text, i) => {
                  return (<div className="campaign-policy__paragraph">({char[i]})&nbsp;{text}</div>)
                })}
              {item.dots &&
                item.dots.map((dot) => {
                  return (<li className="campaign-policy__dots">{dot}</li>)
                })}
              {item.subparagraphs &&
                item.subparagraphs.map(text => {
                  return (<div className="campaign-policy__subparagraph" dangerouslySetInnerHTML={{ __html: text}}/>)
                })}
            </div>
          )
      })}
      <Footer/>
    </div>

  )
}

export default CampaignPolicy
