import React, { useMemo } from 'react'
import './index.scss'

/**
 * Avatar component
 * @param {{
 * name: string
 * size: 'small'|'medium'|'large'
 * }} props Props
 * @returns {React.Element} - the Avatar component
 */
function Avatar({ name = '', size = 'medium' }) {
  const colors = [
    '#EF5350',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#F8E53F',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#607D8B',
  ]
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]
  const color = useMemo(() => {
    if (name) {
      const index = alphabet.findIndex(e => e === name[0].toUpperCase())
      if (colors.length <= index) return String(colors[index - colors.length])
      else return colors[index]
    }
  }, [name])

  function getInitials() {
    const splitName = name.split(' ')

    if (splitName.length === 1) {
      return `${splitName[0][0]}`.toUpperCase()
    }
    return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`.toUpperCase()
  }

  return (
    <div className={`avatar avatar--${size}`} style={{ backgroundColor: color }}>
      {getInitials()}
    </div>
  )
}

export default Avatar
