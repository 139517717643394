import React from 'react'
import terms from '../../assets/documents/aviso-de-privacidade.pdf'

const PrivacyPolicy = () => {
  return (
    <div style={{ height: '100vh', overflowY: 'hidden' }}>
      <iframe src={terms} height='100%' width='100%' />
    </div>
  )
}

export default PrivacyPolicy
