import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, LogIn } from 'react-feather'
import _ from 'lodash'

import './index.scss'

import Logo from '../../assets/logo.svg'
import LogoIcon from '../../assets/logo-icon.svg'
import MiniBanner from '../MiniBanner'

const Header = () => {
  const login = useSelector(state => state.login)
  const app = useSelector(state => state.app)
  const dispatch = useDispatch()
  const { visible, user } = login
  const { menu } = app

  function setLoginVisible(bool) {
    dispatch({ type: 'CHANGE_LOGIN_VISIBLE', payload: bool })
  }

  function setMenuVisible(bool) {
    dispatch({ type: 'CHANGE_APP_MENU', payload: bool })
  }

  return (
    <div>
      <div className='header'>
        <div className='header__logo'>
          <Link to='/notificacoes'>
            <img className='header__logo--desktop' src={Logo} alt='Logo' />
            <img className='header__logo--mobile' src={LogoIcon} alt='Logo' />
          </Link>
          {/* <p>
            POWERED BY &nbsp;
            <a
              target="_blank noopener noreferrer"
              href="https://www.semprocesso.com.br"
            >
              SEM PROCESSO
            </a>
          </p> */}
        </div>
        <nav>
          {/* !_.isEmpty(user.email) &&
          <div className={_.isEmpty(user) ? "header__link header__link--mobile" : "header__link"}>
            <Button to="/notificar" type="2" width="200px" height="50px">Quero Notificar</Button>
          </div>
        */}
          {_.isEmpty(user.email) && (
            <div className='header__link' onClick={() => setLoginVisible(!visible)}>
              <p>Login</p>
              <LogIn size={25} />
            </div>
          )}
          <div className='header__link header__link--mobile' onClick={() => setMenuVisible(!menu)}>
            <p>Menu</p>
            <Menu size={25} />
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
