import React, { useState } from 'react'
import axios from 'axios'
import Button from '../Button'
import {ReactComponent as EmptyStar} from '../../assets/empty-star.svg'
import {ReactComponent as FilledStar} from '../../assets/filled-star.svg'
import TextArea from '../TextArea'
import { ArrowLeft } from 'lucide-react'
import Spinner from '../Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { base64 } from '../../constants/validate'
import download from 'downloadjs'

import './index.scss'

function Rating({reset}) {
  const [stars, setStars] = useState(Array(5).fill(false))
  const [step, setStep] = useState(1)
  const [suggestion, setSuggestion] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const notify = useSelector(state => state.notify)
  const { certification, certificationName } = notify

  function handleDownload(){
    download(base64(certification), certificationName, 'application/pdf')
  }

  function fillStar(currentIndex) {
    const newStars = stars.map((star, index) => index <= currentIndex)
    setStars(newStars)
    if(currentIndex === 4) {
      handleSubmit(newStars)
    } else {
      setStep(2)
    }
  }

  function maximumRating(starsReceived) {
    let allStarsFilled = true
    let starsToCheck = starsReceived ?? stars
    starsToCheck.forEach((star) => {
      if(!star) {
        allStarsFilled = false
      }
    })

    return allStarsFilled
  }

  function returnNumberOfStars() {
    let numberOfStars = 0
    stars.forEach((star) => {
      if(star) {
        numberOfStars += 1
      }
    })

    return numberOfStars
  }

  function renderStart(isFill, index) {
    if(isFill) {
      return <FilledStar key={index} onClick={() => fillStar(index)}/>
    }

    return <EmptyStar key={index} onClick={() => fillStar(index)}/>
  }

  function showError(error, subError = false) {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
    }, 5000);
  }

  async function handleSubmit(stars = null) {
    setIsLoading(true)
    let body
    if(stars && maximumRating(stars)) {
      body = {
        rate: 5,
      }
    } else {
      body = {
        rate: returnNumberOfStars(),
        commentary: suggestion
      }
    }

    await axios.post('/user/rating', body).then(() => {
      setStep(3)
      let userLogged = JSON.parse(localStorage.getItem('user'))
      userLogged.rated = true
      localStorage.setItem('user', JSON.stringify(userLogged))
      dispatch({type: 'CHANGE_LOGIN_USER', payload: userLogged})
    }).catch((error) => {
        console.log(error)
        showError('Houve um erro na avaliação')
    }).finally(() => {
        setIsLoading(false)
    })
  
  }

  return (
    <div className='rating'>
      <div className='rating__text'>
        <h1>Notificação enviada</h1>
        <p>
          Você recebeu o certificado de envio por email. Qualquer atualização será informada. No recebimento, bloqueio ou visualização por parte do remetente, enviaremos um certificado atualizado.
        </p>
      </div>
      <div className='rating__buttons'>
      <Button 
        type='2' 
        scrollLink={true} 
        height='50px' 
        width="270px"
        onClick={() => handleDownload()}
      >
        Download Certificado
      </Button>
      <Button 
        type='1' 
        scrollLink={true} 
        height='50px' 
        width="270px"
        onClick={() => reset()}
      >
        Enviar outra notificação
      </Button>
      </div>
      {step === 1 && (
        <div className='rating__rate rating__rate--stars'>
          <h2>Como você avalia o envio?</h2>
          <div className='rating__rate__stars'>
            {stars.map((star, index) => renderStart(star, index))}
          </div>
          <div className='rating__rate__indicators'>
            <span>Muito ruim</span>
            <span>Muito bom</span>
          </div>
          {isLoading && <Spinner />}
        </div>
      )}
      {step === 2 && (
        <div className='rating__rate'>
          <div className='rating__rate__title-container'>
            <ArrowLeft onClick={() => setStep(1)}/>
            <h2>O que podemos melhorar?</h2>
          </div>
          
          <TextArea
            placeholder="Escreva sua sugestão"
            autoResize
            value={suggestion}
            onChange={(value) => setSuggestion(value)}
            style={{minHeight: 105, marginTop: 24, marginBottom: 8}}
          />
          <Button 
            scrollLink={true} 
            type='1' 
            height='40px' 
            onClick={() => handleSubmit()}
            loading={isLoading}
          >
            Enviar
          </Button>
        </div>
      )}
      {step === 3 && (
        <div className='rating__rate'>
          <div className='rating__rate__success-message'>
            <p>Obrigado!</p>
            {!maximumRating() && <p>Recebemos sua sugestão de melhoria.</p>}
            {maximumRating() && <p>Recebemos sua avaliação.</p>}
          </div>
        </div>
      )}
    </div>
  )
}

export default Rating