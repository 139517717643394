import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import axios from 'axios'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { logout } from './constants/others'

import env from './env'
import reducers from './reducers/reducers'

if (env === 'production') {
  axios.defaults.baseURL = 'https://server.notificca.com.br'
  // axios.defaults.baseURL = "https://marreta-server.notificca.com.br/";
  // axios.defaults.baseURL = 'http://notificcaserver-env-staging.sa-east-1.elasticbeanstalk.com'
} else {
  axios.defaults.baseURL = 'http://localhost:3001'
  // axios.defaults.baseURL = 'https://server.notificca.com.br'
  // axios.defaults.baseURL = 'http://notificcaserver-env-staging.sa-east-1.elasticbeanstalk.com'
}

let user = localStorage.getItem('user')
if (typeof user === 'string') {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        localStorage.removeItem('user')
        window.location.href = '/login'
      }
      return Promise.reject(error)
    }
  )
}

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
