import React, { useEffect, useState, useRef } from "react";
import { Eye, EyeOff } from "react-feather";

import "./index.scss";

const Input = ({
  value = "",
  label = "Sample",
  placeholder = "Sample",
  type = "text",
  required = false,
  error = false,
  disabled = false,
  maxLength = 1000,
  autoComplete = true,
  onBlur = (value = "") => true,
  onChange = (value = "") => {},
  onPressEnter = () => {},
  onClick,
  style,
}) => {
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [hasError, setHasError] = useState(error);
  const [hasValue, setHasValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => setHasError(error), [error]);

  function handleClickTogglePassword() {
    setShowPassword(!showPassword);
  }

  function handleOnBlur(evt) {
    const value = evt.target.value;
    if (onBlur) {
      const response = onBlur(value);
      if (response === false) {
        setHasError(true);
        inputRef.current.setCustomValidity("Valor inválido");
      } else {
        setHasError(false);
        inputRef.current.setCustomValidity("");
      }
    }
    setIsFocus(false);
  }

  function handleOnChange(evt) {
    const value = evt.target.value;
    onChange && onChange(value, evt);
    setHasError(error);
    inputRef.current.setCustomValidity("");
  }

  function handlePressEnter(evt) {
    if (evt.key === "Enter") onPressEnter && onPressEnter();
  }

  function returnLabelClassName() {
    let currentClassName = "new-input__label";
    if (isFocus || hasValue || value)
      currentClassName += " new-input__label--open";
    if (isFocus) currentClassName += " new-input__label--focus";
    return currentClassName;
  }

  function returnClassName() {
    let currentClassName = "new-input__wrapper";
    if (hasError) currentClassName += " new-input__wrapper--error";
    if (disabled) currentClassName += " new-input__wrapper--disabled";
    return currentClassName;
  }

  return (
    <div className={returnClassName()}>
      <span className={returnLabelClassName()}>
        {label}
        {required && " *"}
      </span>
      <input
        ref={inputRef}
        className="new-input"
        value={value}
        placeholder={placeholder}
        type={type === "password" && showPassword ? "text" : type}
        style={style}
        onMouseDown={() => setIsFocus(true)}
        onClick={() => setIsFocus(true)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => setIsFocus(true)}
        onKeyPress={handlePressEnter}
        onAnimationStart={(e) =>
          e.animationName === "onAutoFillStart"
            ? setHasValue(true)
            : setHasValue(false)
        }
        required={required}
        onInvalid={() => setHasError(true)}
        maxLength={maxLength}
        autoComplete={autoComplete ? "on" : "none"}
      />
      {type === "password" && (
        <span
          className={`new-input__password-eye`}
          onClick={handleClickTogglePassword}
        >
          {showPassword ? <EyeOff /> : <Eye />}
        </span>
      )}
    </div>
  );
};

export default Input;
