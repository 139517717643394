import React from "react"
import { useSelector } from 'react-redux'
import download from 'downloadjs'
import './index.scss'

import { base64 } from '../../constants/validate'
import Button from '../Button'

const Success = ({reset, ...props}) => {

  const notify = useSelector(state => state.notify)
  const { certification, certificationName } = notify

  function handleDownload(){
    download(base64(certification), certificationName, 'application/pdf')
  }


  return (
    <div className="success">
      <h1>Parabéns sua notificação foi enviada!</h1>
      <h2>Você recebeu por email o seu certificado e também pode baixá-lo no botão abaixo</h2>
      <h3>Qualquer atualização será informada. No recebimento, bloqueio ou visualização por parte do remetente, iremos te enviar por email um certificado atualizado.</h3>
      <Button type='5' scrollLink={true} height='50px' downloadCloud={true} onClick={() =>handleDownload()}>Download Certificado</Button>
      <div className="success__reset">Deseja enviar outra notificação? <span onClick={() => reset()}>Clique aqui</span></div>
    </div>
  )
}

export default Success
