import React from 'react'
import terms from '../../assets/documents/termos-de-uso.pdf'
import './index.scss'

const Terms = () => {
  return (
    <div style={{ height: '100vh', overflowY: 'hidden' }}>
      <iframe src={terms} height='100%' width='100%' />
    </div>
  )
}

export default Terms
