import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./index.scss";
import Mail from "../../assets/mail.svg";
import Footer from "../../components/Footer";

const Confirmation = () => {
  const { id, hash } = useParams();

  useEffect(() => {
    axios
      .post(`/notifications/update-certification`, {
        notificationId: id,
        status: "confirmed",
        confirmationHash: hash,
      })
      .then((response) => {})
      .catch((e) => {
        console.log(e);
      });

    return () => {};
  });

  return (
    <div>
      <div className="confirmation">
        <img src={Mail} alt="sending a letter" />
        <h1>Obrigado pela Confirmação</h1>
        <h3>
          Ao confirmar o recebimento da notificação, não é necessário realizar
          nenhuma outra ação. Neste momento, o notificante foi informado da sua
          confirmação. Obrigado por utilizar o Notificca!
        </h3>
        {/* <span>Qualquer dúvida entre em &nbsp;<a>contato conosco</a></span> */}
      </div>
      <Footer />
    </div>
  );
};

export default Confirmation;
