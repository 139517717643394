import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import _ from "lodash";
import "./index.scss";

import ProgressBar from "../../components/ProgressBar";
import Sender from "../../components/Sender";
import Document from "../../components/Document";
import Recipient from "../../components/Recipient";
import Success from "../../components/Success";
import VerifyEmail from "../../components/VerifyEmail";
import Warning from "../../components/Warning";
import Confirm from "../../components/Confirm";
import Button from "../../components/Button";
import Footer from "../../components/Footer";

import env from "../../env";
import Rating from "../../components/Rating";

const Notify = () => {
  const notify = useSelector((state) => state.notify);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { pdf, notificationId, code, step } = notify;
  const { user } = login;

  // useEffect(() => {
  //   if (user && user.gpt && user.gptQuota > 0) {
  //     history.push("/notificar-gpt");
  //   }
  // }, [user]);

  useEffect(() => {
    dispatch({ type: "CHANGE_APP_MINIBANNER", payload: false });
  }, [dispatch]);

  const reset = () => {
    dispatch({ type: "RESET_NOTIFY" });
  };

  const showError = (error, subError = false) => {
    dispatch({ type: "CHANGE_APP_ERROR", payload: error });

    if (subError) {
      dispatch({ type: "CHANGE_APP_SUB_ERROR", payload: subError });
    }

    setTimeout(() => {
      dispatch({ type: "CHANGE_APP_ERROR", payload: "" });
      dispatch({ type: "CHANGE_APP_SUB_ERROR", payload: "" });
    }, 7000);
  };

  const changeStep = (newStep) => {
    dispatch({ type: "CHANGE_NOTIFY_STEP", payload: newStep });

    if (env === "production") {
      if (newStep > step) {
        window.mixpanel.track("Advance step", { step: newStep });
      } else {
        window.mixpanel.track("Return step", { step: newStep });
      }
    }
  };

  function setLoading(bool) {
    dispatch({ type: "CHANGE_NOTIFY_LOADING", payload: bool });
  }

  function setCertification(certification) {
    dispatch({ type: "CHANGE_NOTIFY_CERTIFICATION", payload: certification });
  }

  function setCertificationName(certificationName) {
    dispatch({
      type: "CHANGE_NOTIFY_CERTIFICATION_NAME",
      payload: certificationName,
    });
  }

  function setConfirmModal(bool) {
    dispatch({ type: "CHANGE_NOTIFY_CONFIRM_MODAL", payload: bool });
  }

  async function sendForm() {
    setLoading(true);
    let formData = new FormData();
    let document = pdf;

    formData.append("notificationId", notificationId);
    formData.append("document", document);
    formData.append("verificationCode", code);

    if (!_.isEmpty(user.email)) {
      formData.append("loggedUser", true);
    } else {
      formData.append("loggedUser", false);
    }

    axios
      .post("/notifications/send", formData)
      .then((response) => {
        setConfirmModal(false);

        if (login.user.rated) {
          changeStep(5);
        } else {
          changeStep(6);
        }
        setCertification(response.data.certification);
        setCertificationName(response.data.name);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          showError(
            <h1 className="notify__error notify__error--max">
              Você atingiu o seu limite de notificações{" "}
              <Button
                to="/planos"
                type={10}
                chevronRight={true}
                height="50px"
                width="180px"
              >
                Ver Planos
              </Button>
            </h1>,
            "Assine um plano com mais notificações e ganhe também outros benefícios exclusivos"
          );
        } else if (e.response.status === 401) {
          showError("Código de verificação incorreto");
        } else {
          showError("Ocorreu um erro");
        }
        setLoading(false);
      });
  }

  function returnClassName() {
    let className = "notify";

    if (step === 6) className += " notify--rating";

    return className;
  }

  return (
    <div>
      <div className={returnClassName()}>
        {step !== 6 && <ProgressBar step={step} />}
        {step === 1 && <Sender setStep={changeStep} setError={showError} />}
        {step === 2 && <Document setStep={changeStep} setError={showError} />}
        {step === 3 && <Recipient setStep={changeStep} setError={showError} />}
        {/* {step === 4 && <Payment term={term} setTerm={setTerm} pdf={pdf} coupon={coupon} setCoupon={setCoupon} cardName={cardName} setCardName={setCardName} cardNumber={cardNumber} setCardNumber={setCardNumber} cardDate={cardDate} setCardDate={setCardDate} cardCode={cardCode} setCardCode={setCardCode} senderDocument={senderDocument} senderEmail={senderEmail} senderName={senderName} recipientName={recipientName} recipientEmail={recipientEmail} recipientDocument={recipientDocument} setStep={changeStep} setError={showError} setCertification={setCertification} setCertificationName={setCertificationName} loading={loading} setLoading={setLoading}/>} */}
        {step === 4 && (
          <VerifyEmail setStep={changeStep} setError={showError} />
        )}
        {step === 5 && <Success reset={reset} />}
        {step === 6 && <Rating reset={reset} />}
        <Warning />
        <Confirm onClick={sendForm} />
      </div>
      <Footer />
    </div>
  );
};

export default Notify;
