import React from "react";
import { Check } from 'lucide-react'
import { useSelector } from 'react-redux'
import './index.scss'
import Button from '../../../../components/Button'
import { StepperLinear } from '../../../../components/Stepper'
import _ from 'lodash'

const Confirmation = () => {
  const { user } = useSelector(state => state.login)
  const { plan } = useSelector(state => state.subscription)

  function stepperList() {
    if (user?.token?.length > 0)
      return ['Informações', '', 'Pagamento', 'Checkout']
    else
      return ['Informações', 'Validação', 'Pagamento', 'Checkout']
  }
  return (
    <div className="subscription__confirm">
      {!plan.isFree &&
      <div className="subscription__stepper">
        <StepperLinear currentStep={5} steppersList={stepperList()} />
      </div>
      }
      <div className="subscription__confirm__container">
        <div className="subscription-confirm">
          <div className='subscription-confirm__icon'>
            <Check />
          </div>
          {(_.isEmpty(user.email)) ?
          <>
          <h1>Bem-vindo ao Notificca!</h1>
          <h2>Parabéns! Agora você pode aproveitar todos os benefícios da plataforma.</h2>
          </>
          :
          <>
          {plan.isFree ? <h1>Plano grátis adicionado!</h1> : <h1>Compra concluída!</h1>}  
          <h2>{plan.numberOfNotifications} notificações foram adicionados ao seu saldo.</h2>
          </>
          }
          {/*<h2>Precisa de ajuda?&nbsp;<p>Fale com nosso atendimento</p></h2>*/}
          <Button height='50px' type={1}>Voltar à Home</Button>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
