import React from "react";
import axios from "axios";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";

import Input from "../../components/Input";
import Button from "../../components/Button";

import {
  email as emailFormat,
  formatDocument,
  onlyNumber,
  validateCpf,
  validateCnpj,
  validadeEin,
} from "../../constants/validate";

const Recipient = ({ setStep, setError }) => {
  const notify = useSelector((state) => state.notify);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const {
    recipientName,
    recipientEmail,
    recipientDocument,
    senderName,
    senderDocument,
    senderEmail,
    loading,
  } = notify;
  const { user } = login;

  function setRecipientName(name) {
    dispatch({ type: "CHANGE_NOTIFY_RECIPIENT_NAME", payload: name });
  }

  function setRecipientDocument(document) {
    dispatch({ type: "CHANGE_NOTIFY_RECIPIENT_DOCUMENT", payload: document });
  }

  function setRecipientEmail(email) {
    dispatch({ type: "CHANGE_NOTIFY_RECIPIENT_EMAIL", payload: email });
  }

  function setLoading(bool) {
    dispatch({ type: "CHANGE_NOTIFY_LOADING", payload: bool });
  }

  function setNotificationId(id) {
    dispatch({ type: "CHANGE_NOTIFY_NOTIFICATION_ID", payload: id });
  }

  function setConfirmModal(bool) {
    dispatch({ type: "CHANGE_NOTIFY_CONFIRM_MODAL", payload: bool });
  }

  function handleClick() {
    let allowed = false;

    if (recipientName.length > 1) {
      if (
        onlyNumber(recipientDocument).length === 6 ||
        (onlyNumber(recipientDocument).length === 9 &&
          validadeEin(recipientDocument)) ||
        (onlyNumber(recipientDocument).length === 11 &&
          validateCpf(recipientDocument)) ||
        (onlyNumber(recipientDocument).length === 14 &&
          validateCnpj(recipientDocument))
      ) {
        if (emailFormat(recipientEmail) !== "Email Inválido") {
          allowed = true;
        }
      }
    }

    return allowed;
  }

  function handleEmail() {
    if (
      emailFormat(recipientEmail) === "Email Inválido" &&
      recipientEmail !== ""
    ) {
      setError("Email inválido");
    } else {
      setError("");
    }
  }

  function handleDocument() {
    if (
      onlyNumber(recipientDocument).length === 9 &&
      !validadeEin(recipientDocument)
    ) {
      setError("EIN inválido.");
    }
    if (
      onlyNumber(recipientDocument).length === 11 &&
      !validateCpf(recipientDocument)
    ) {
      setError("CPF inválido.");
    }
    if (
      onlyNumber(recipientDocument).length === 14 &&
      !validateCnpj(recipientDocument)
    ) {
      setError("CNPJ inválido.");
    }
  }

  async function handleSubmit() {
    setLoading(true);
    let sender = {
      name: senderName,
      email: senderEmail,
      documentNumber: senderDocument,
    };

    let recipient = {
      name: recipientName,
      email: recipientEmail,
      documentNumber: recipientDocument,
    };

    let response;
    let error = "";

    try {
      response = await axios.post("/notifications/create", {
        sender,
        recipient,
      });
      if (_.isEmpty(user.email)) {
        await axios.post("/notifications/send-verification", {
          notificationId: response.data,
        });
      } else {
        setConfirmModal(true);
      }
    } catch (e) {
      console.log(e);
      error = e;
    }

    if (error.length === 0) {
      if (_.isEmpty(user.email)) {
        setStep(4);
      }
      setLoading(false);
      setNotificationId(response.data);
    }
  }

  return (
    <form
      className="recipient"
      onSubmit={() => (handleClick() ? handleSubmit() : "")}
    >
      <h2>Qual as informações do destinatário?</h2>
      <h3>
        Informe o nome, email e o documento do destinatário, ou seja, a pessoa
        ou a empresa que você está notificando (Notificado).
      </h3>
      <Input
        type="2"
        placeholder="Escreva aqui o nome do destinatário"
        value={recipientName}
        onChange={setRecipientName}
      />
      <Input
        inputType="email"
        type="2"
        placeholder="Escreva aqui o email do destinatário"
        value={recipientEmail}
        onChange={setRecipientEmail}
        validate={emailFormat}
        onBlur={handleEmail}
      />
      <Input
        type="2"
        placeholder="Escreva aqui o número do documento do destinatário"
        value={recipientDocument}
        onChange={setRecipientDocument}
        format={formatDocument}
        number={true}
        onBlur={handleDocument}
      />
      <Button
        loading={loading}
        scrollLink={true}
        type={handleClick() ? "1" : "4"}
        fontSize="16px"
        arrowRight={true}
        onClick={() => (handleClick() ? handleSubmit() : "")}
      >
        Ir para a próxima etapa
      </Button>
      <div className="back-button" onClick={() => setStep(2)}>
        Voltar
      </div>
    </form>
  );
};

export default Recipient;
