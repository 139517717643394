import React from 'react'
import { useDispatch } from 'react-redux'

import { ReactComponent as OpenAILogo } from '../../../../../../assets/openai.svg'

import './index.scss'

function ButtonGPT() {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch({ type: 'CHANGE_APP_CONTACT_US_MODAL', payload: true })
    dispatch({ type: 'CHANGE_APP_CONTACT_US_MODAL_GPT', payload: true })
  }
  return (
    <button className='button-gpt' onClick={handleClick}>
      <span>Quero adicionar o ChatGPT</span>
      <OpenAILogo />
    </button>
  )
}

export default ButtonGPT
