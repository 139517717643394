import React, { useState, useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { X, Check, DownloadCloud } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios'

import './index.scss'

import Button from '../../components/Button'
import Warning from '../../components/Warning'
import Footer from '../../components/Footer'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Validate = () => {
  const [isValid, setIsValid] = useState('')
  const [hash, setHash] = useState('')
  const [error, setError] = useState('')
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: 'validar' })
    return () => {
    }
  }, [])

  const showError = (error) => {
    setError(error)

    setTimeout(() => { setError('') }, 7000);
  }

  const onDrop = useCallback(async(acceptedFiles) => {
    if(acceptedFiles[0]) {
      let formData = new FormData()
      let response

      formData.append('document', acceptedFiles[0])

      try {
        response = await axios.post('/notifications/validate-certification', formData)

        setFile(URL.createObjectURL(acceptedFiles[0]))
        setIsValid(response.data.isValid)
        setHash(response.data.hash)
      }
      catch (e) {
        console.log(e)
        showError('Ocorreu um erro')
      }

      setLoading(false)
      return
    }

    showError('Arquivo não é valido')
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'application/pdf',
    multiple: false,
    onDrop: onDrop
  })

  return (
    <div>
    <div className="validate">
      <div className="validate__container">
        <h2>Validar Certificado</h2>
        <h3>Insira abaixo o certificado a ser validado. Lembre-se o documento precisa ser .PDF e não possuir um tamanho maior que 10mb.</h3>
        {isValid === '' &&
          <div className="validate__dropzone" {...getRootProps()}>
            <input id="fileButton" {...getInputProps()} />
            <DownloadCloud size={60} />
            <p>Arraste o certificado para este box</p>
            <p>ou</p>
            <Button scrollLink={true} height='50px' type='5' fontSize="16px" loading={loading} onClick={() => document.getElementById('fileButton').click()}>Escolher Certificado</Button>
          </div>
        }
        {isValid !== '' &&
          <div className={isValid ? "validate__response" : "validate__response validate__response--invalid"}>
            <div className="validate__background">
              <div className="validate__file">
                <Document file={file}
                  onLoadError={e => console.log(e)}>
                  <Page pageNumber={1} style={{width: '40'}} />
                </Document>
              </div>
              {isValid &&
                <div className="validate__content">
                  <p className="validate__title"><span><Check /></span>O Certificado é válido</p>
                  <p>O certificado é valido e, cosequentente suas informações também. Qualquer modificação, mesmo que infima, modifica o hash do seu documento. Sendo assim, ao ser comparado com o original em nosso banco de dados, podemos garantir a autenticidade do certificado.</p>
                  <p>O hash do seu certificado é este:</p>
                  <p className="validate__hash">{hash}</p>
                </div>
              }
              {!isValid &&
                <div className="validate__content">
                  <p className="validate__title"><span><X /></span>O Certificado não é válido</p>
                  <p>O certificado é inválido e, cosequentente suas informações também. Qualquer modificação, mesmo que infima, modifica o hash do seu documento. Sendo assim, ao ser comparado com o original em nosso banco de dados, podemos garantir a autenticidade do certificado.</p>
                  <p>Fraude é o crime, verifique se você fez o upload do documento correto.</p>
                  <p>Por favor, em caso de dúvida  entre em contato com nossa equipe de suporte.</p>
                </div>
              }
              <X className="validate__close" onClick={() => setIsValid('')}/>
            </div>
          </div>
        }
        <Warning error={error} />
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Validate
