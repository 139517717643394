import React from 'react'
import creditCardType from 'credit-card-type'
import ReCAPTCHA from 'react-google-recaptcha'
import useRecaptcha from '../../../../hooks/useRecaptcha'
import './index.scss'

import Form from '../../../../components/Form'
import Card from '../../../../components/Card'
import { Lock } from 'lucide-react'

import {
  expiredAt,
  creditCard,
  cvvValidator,
  onlyNumber,
  phone as phoneFormat,
} from '../../../../constants/validate'

const CreditCard = ({
  card,
  changeCardField,
  address,
  changeAddressField,
  phone,
  changePhoneField,
}) => {
  const { number, holderName, expirationDate, cvv } = card
  const { zipcode, neighborhood, street, streetNumber } = address
  const { number: phoneNumber } = phone
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha()

  let formsArray = [
    {
      value: expirationDate,
      onChange: changeCardField,
      title: 'Expira Em',
      format: expiredAt,
      field: 'expirationDate',
    },
    { value: cvv, onChange: changeCardField, title: 'CVV', format: cvvValidator, field: 'cvv' },
  ]

  let addressArray = [
    {
      value: streetNumber,
      onChange: changeAddressField,
      title: 'Número',
      format: onlyNumber,
      field: 'streetNumber',
    },
    {
      value: zipcode,
      onChange: changeAddressField,
      title: 'CEP',
      format: onlyNumber,
      field: 'zipcode',
    },
  ]

  return (
    <div className='credit-card'>
      <div className='credit-card__form__card'>
        <div className='credit-card__form'>
          <Form
            title='Número do Cartão'
            value={number}
            onChange={changeCardField}
            format={creditCard}
            number={onlyNumber}
            field={'number'}
          />
          <Form
            title='Nome no Cartão'
            value={holderName}
            onChange={changeCardField}
            field={'holderName'}
          />
          <Form formsArray={formsArray} twoForms={true} />
          <Form
            title='Telefone'
            value={phoneNumber}
            onChange={changePhoneField}
            field={'number'}
            format={phoneFormat}
          />
          <Form title='Rua' value={street} onChange={changeAddressField} field={'street'} />
          <Form formsArray={addressArray} twoForms={true} />
          <Form
            title='Bairro'
            value={neighborhood}
            onChange={changeAddressField}
            field={'neighborhood'}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey='6LctzyEqAAAAAMyWvVKya5kg4ixSBXyuThCTNAaT'
            onChange={handleRecaptcha}
          />
        </div>
        <div className='credit-card__form__image--mb'>
          <div className='credit-card__form__image'>
            <Card cardInfos={creditCardType(onlyNumber(number))} />
          </div>
          <div className='credit-card__secure credit-card__secure'>
            <Lock size={20} />
            <p>Compra Segura</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
