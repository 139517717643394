import React, { useState, useEffect, useRef } from 'react'

import { ArrowRight } from 'lucide-react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './index.scss'

const NotificationsToolTip = ({ children, plan, quota = 0, planQuota = 0, maxPlanQuota }) => {
  const notificationsToolTipRef = useRef(null)
  const dispatch = useDispatch()
  let history = useHistory()
  const [active, setActive] = useState(false)
  const [timer, setTimer] = useState(null)

  const handleMouseEnter = () => {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setActive(true)
  }

  const handleMouseLeave = () => {
    const newTimer = setTimeout(() => {
      setActive(false)
      setTimer(null)
    }, 800)
    setTimer(newTimer)
  }

  useEffect(() => {
    const element = notificationsToolTipRef.current
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter)
      element.addEventListener('mouseleave', handleMouseLeave)
    }
    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter)
      element.removeEventListener('mouseleave', handleMouseLeave)
      clearTimeout(timer)
    }
  }, [notificationsToolTipRef.current, timer])

  function switchPage(page) {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: page })
    history.push(`/${page}`)
  }

  return (
    <div className='notifications-tooltip' ref={notificationsToolTipRef}>
      {children}
      {active && (
        <div className='notifications-tooltip__content'>
          <div>
            <h4>Notificações disponíveis</h4>
            <ul>
              <li>
                Plano:{' '}
                <span>
                  {planQuota}/{plan ? maxPlanQuota : 0}
                </span>
              </li>
              <li>
                Unitárias: <span>{quota}</span>
              </li>
            </ul>
          </div>
          <hr />
          <article>
            <button
              onClick={() => {
                switchPage('planos')
              }}>
              Adquirir mais <ArrowRight size='12px' color='#ffffff' />{' '}
            </button>
          </article>
          <div className='triangle'></div>
        </div>
      )}
    </div>
  )
}

export default NotificationsToolTip
