import React from 'react'
import { Check, X } from 'lucide-react'

import { ReactComponent as Openai } from '../../assets/openai.svg'

import './index.scss'

const PlanBenefits = ({ element, isHorizontal }) => {
  const e = element

  const getBenefitIcon = (element, key) => {
    //TODO: verificar se esse if esta sendo usado
    if (element.numberOfNotifications) {
      if (key === 3) {
        return <Openai style={{ width: '16px', height: '16px' }} />
      }
    }

    if (element.info) {
      if (element.info[key]) {
        return <Check size={16} />
      } else {
        return <X size={16} />
      }
    }
  }

  const getBenefitClassName = (element, key) => {
    if (element.info) {
      if (element.info[key]) {
        return 'plan_benefit'
      } else {
        return 'plan_benefit--disabled'
      }
    }
  }

  return (
    <ul className={`plan_benefit__list ${isHorizontal ? 'plan_benefit__list--horizontal' : ''}`}>
      <li className={getBenefitClassName(e, 0)}>{getBenefitIcon(e, 0)}Visão gerencial</li>
      <li className={getBenefitClassName(e, 1)}>{getBenefitIcon(e, 1)}Suporte preferencial</li>
      <li className={getBenefitClassName(e, 2)}>{getBenefitIcon(e, 2)}Email personalizado</li>
    </ul>
  )
}

export default PlanBenefits
