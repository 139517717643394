import React, {useState} from "react"
import './index.scss'
import { Eye, EyeOff } from 'lucide-react'


const Form = ({ title, subtitle, value, onChange, format, twoForms = false, formsArray, number, column = false, field = false, password = false, disabled = false }) => {
  const [passwordVisible,setPasswordVisible] = useState(false)
  function handleChange(value) {
    if (format) {
      if (number) {
        return format(number(value))
      }
      else {
        return format(value)
      }
    }
    else {
      if (number) {
        return number(value)
      }
      else {
        return value
      }
    }
  }

  function handleMultiChange(item, value) {
    if (item.format) {
      if (item.number) {
        return item.format(item.number(value))
      }
      else {
        return item.format(value)
      }
    }
    else {
      if (item.number) {
        return item.number(value)
      }
      else {
        return value
      }
    }
  }

  if (!twoForms) {
    return (
      <div className="form">
        <span>{title}</span>
        <input disabled={disabled} type={password && 'password'} value={value} onChange={(e) => field ? onChange(handleChange(e.target.value), field) : onChange(handleChange(e.target.value))} />
      </div>
    )
  }
  else {
    return (
      <div className={column ? "form__two form__two--column" : "form__two"}>
        {formsArray.map((item,index) => {
          return (
            <div key={index} className={column ? 'form form--column' : "form form--two"}>
              <span>{item.title}</span>
              <div className="form__input">
              <input disabled={item.disabled} type={(item.password&&!passwordVisible) && 'password'} value={item.value} onChange={(e) => item.field ? item.onChange(handleMultiChange(item, e.target.value), item.field) : item.onChange(handleMultiChange(item, e.target.value))} />
              {item.title === 'Senha' &&
                (passwordVisible ? <EyeOff size={20} onClick={() => setPasswordVisible(false)}/> : <Eye size={20} onClick={()=>setPasswordVisible(true)}/> ) 
              }
              </div>
            </div>
          )
        })}

      </div>
    )
  }

}

export default Form
