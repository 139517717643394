import axios from 'axios'
import download from 'downloadjs'
import { Download } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { base64 } from '../../constants/validate'

import Paperplane from '../../assets/paperplane.svg'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import Spinner from '../../components/Spinner'
import Warning from '../../components/Warning'
import Pagination from '../../components/Pagination'
import Dropdown from '../../components/Dropdown'

import PaginationControl from '../../components/PaginationControl'
import NotificationsCard from './components/NotificationsCard'
import NotificationsToolTip from './components/NotificationsToolTip'

import './index.scss'

const Notifications = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingIcon, setLoadingIcon] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('')
  const [notificationsPerPage, setNotificationsPerPage] = useState(
    JSON.parse(localStorage.getItem('userPreferences'))?.notificationsPerPage || 5
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [maxPlanQuota, setMaxPlanQuota] = useState(0)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960)
  const notification = useSelector(state => state.notification)
  const login = useSelector(state => state.login)
  const dispatch = useDispatch()
  let history = useHistory()

  const { notifications, search, filter, index, totalNotifications, modalVisible, modalInfos } =
    notification
  const { user, quota } = login
  const totalNotificationsQuota = quota?.planQuota + quota?.quota || 0

  useEffect(() => {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: 'notificacoes' })
    let source = axios.CancelToken.source()
    let pagination = {
      pageNumber: currentPage - 1,
      notificationsPerPage: notificationsPerPage,
    }
    setLoadingIcon('fetch')
    setFetch(true)
    axios
      .post(
        '/notifications/retrieve-notifications',
        { filter, keyword: search, index, pagination },
        {
          cancelToken: source.token,
        }
      )
      .then(response => {
        setNotifications(response.data.notifications)

        setLoadingIcon('')
        setTotalNotifications(response.data.totalNotifications)
        setTotalPages(response.data.totalPages)
        setMaxPlanQuota(response.data.maxPlanQuota)
        setQuota(response.data.planQuota, response.data.quota)
        setFetch(false)
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setLoadingIcon('')
          setFetch(false)
        }
      })

    return () => {
      source.cancel()
      // window.removeEventListener('scroll', handleScroll)
    }
  }, [filter, search, user, index, notificationsPerPage])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [notificationsPerPage])

  function setSearch(search) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_SEARCH', payload: search })
  }

  function setNotifications(notifications) {
    dispatch({
      type: 'CHANGE_NOTIFICATIONS_NOTIFICATIONS',
      payload: notifications,
    })
  }

  function setFetch(fetch) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_FETCH', payload: fetch })
  }

  function setIndex(number) {
    if (totalNotifications !== notifications.length || totalNotifications === 0) {
      dispatch({ type: 'CHANGE_NOTIFICATIONS_INDEX', payload: number })
    }
  }

  function setTotalNotifications(totalNotifications) {
    dispatch({
      type: 'CHANGE_NOTIFICATIONS_TOTAL_NOTIFICATIONS',
      payload: totalNotifications,
    })
  }

  function setFilter(field, bool) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_FILTER', payload: { field, bool } })
    dispatch({ type: 'CHANGE_NOTIFICATIONS_INDEX', payload: 1 })
  }

  function handleSetFilter(value) {
    setSelectedFilter(value)
    setFilter(value, true)
  }

  function setQuota(planQuota, quota) {
    const _quota = { planQuota, quota }
    dispatch({ type: 'CHANGE_LOGIN_QUOTA', payload: _quota })
  }

  function setShowModal(bool) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_MODAL', payload: bool })
  }

  function exportNotifications() {
    setLoadingIcon('export')
    let pagination = { pageNumber: currentPage - 1, notificationsPerPage: notificationsPerPage }

    axios
      .post('/notifications/retrieve-notifications', {
        filter,
        keyword: search,
        index,
        excel: true,
        pagination,
      })
      .then(response => {
        setLoadingIcon('')
        download(base64(response.data), `${user.name}.xlsx`, 'application/x-www-form-urlencoded')
      })
      .catch(e => {
        console.log(e)
      })
  }

  function downloadAllLastCertifications(email) {
    setLoadingIcon('notifications')
    axios
      .post('/notifications/retrieve-notifications', {
        filter,
        keyword: search,
        index,
        zip: true,
      })
      .then(response => {
        const base64 = response.data
        const name = `${email}.zip`
        const type = 'application/zip;'

        const byteCharacters = atob(base64)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const file = new Blob([byteArray], { type })

        setLoadingIcon('')
        download(file, name, type)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const showError = (error, subError = false) => {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
    }, 7000)
  }

  function handleResendNotifications() {
    setLoading(true)
    axios
      .post('/notifications/resend', { email, notificationId: modalInfos.id })
      .then(response => {
        setLoading(false)
        document.querySelector('#resend').innerHTML = 'Enviado!'
        setTimeout(() => {
          dispatch({ type: 'CHANGE_NOTIFICATIONS_MODAL', payload: false })
          document.querySelector('#resend').innerHTML = 'Reenviar'
        }, 4000)
      })
      .catch(e => {
        if (e.response.status === 403) {
          showError('Você atingiu o seu limite de notificações')
        } else if (e.response.status === 401) {
          showError('Código de verificação incorreto')
        } else {
          showError('Ocorreu um erro')
        }
        setLoading(false)
      })
  }

  function switchToPageNotify(e) {
    e.preventDefault()
    dispatch({ type: 'CHANGE_APP_PAGE', payload: 'notificar' })
    // if (user.gpt) {
    //   history.push('/notificar-gpt')
    // } else {
    history.push(`/notificar`)
    // }
  }

  function handlePaginationClick(e) {
    setCurrentPage(e)
    dispatch({ type: 'CHANGE_NOTIFICATIONS_INDEX', payload: e })

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <Modal
        title={'Reenviar'}
        show={modalVisible}
        close={() => setShowModal(false)}
        renderFooter={
          <>
            <Button
              id='resend'
              type={6}
              scrollLink={true}
              loading={loading}
              onClick={() => handleResendNotifications()}>
              Reenviar
            </Button>
          </>
        }>
        <div className='modal__container__contents'>
          <h3>E-MAIL NOTIFICADO</h3>
          <p>{modalInfos?.recipient?.email}</p>
          <h3>E-MAIL DE REENVIO</h3>
          <Input
            type='2'
            style={{ margin: '15px 0 -25px' }}
            placeholder='Email'
            onChange={setEmail}
            value={email}
            inputType='email'
          />
        </div>
      </Modal>
      <div className='notifications'>
        <div className='notifications__header'>
          <header>
            <div className='notifications__header__title'>
              <h1>Notificações</h1>
              <p>
                Exibindo {notifications.length} de {totalNotifications}{' '}
                {loadingIcon === 'export' ? (
                  <Spinner style={{ marginLeft: '0' }} />
                ) : (
                  <button
                    title='Baixar relatório de notificações'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      exportNotifications()
                    }}>
                    <Download size='20' color='#26a69a' />
                  </button>
                )}
              </p>
            </div>
            <div className='notifications__header__action-search'>
              {!isMobile && (
                <Input
                  onChange={setSearch}
                  value={search}
                  placeholder='Pesquisar'
                  type='2'
                  height={'43px'}
                  style={{
                    height: '43px',
                    minHeight: '43px',
                    border: '1px solid transparent',
                    backgroundColor: '#F5F5F5',
                    color: '#959595',
                    textAlign: 'start',
                    padding: '16px',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeigth: '20px',
                    letterSpacing: '0.25px',
                    margin: '0',
                  }}
                />
              )}

              <Button height={'43px'} plus onClick={switchToPageNotify}>
                Nova notificação
              </Button>
            </div>
          </header>
          <div className='notifications__header__bottom'>
            <div style={{ width: '146px' }}>
              <Dropdown
                select={selectedFilter}
                setSelect={value => {
                  handleSetFilter(value)
                  setCurrentPage(1)
                }}
                options={[
                  { label: 'Tudo', value: '' },
                  { label: 'Confirmado', value: 'confirmedAt' },
                  { label: 'Aberto', value: 'openedAt' },
                  { label: 'Recebido', value: 'receivedAt' },
                  { label: 'Não recebido', value: 'bouncedAt' },
                ]}
                placeholder={'Status'}></Dropdown>
            </div>
            <div className='notifications__header__bottom__buttons'>
              <NotificationsToolTip
                plan={login.user.subscription.id}
                quota={quota?.quota}
                planQuota={quota?.planQuota}
                maxPlanQuota={maxPlanQuota}>
                <p>
                  Notificações: <span>{totalNotificationsQuota}</span>
                </p>
              </NotificationsToolTip>
            </div>
          </div>
        </div>
        <div className='notifications__area'>
          <div className='notifications__area__list'>
            {loadingIcon === 'fetch' ? (
              <Spinner type='green' />
            ) : notifications.length > 0 ? (
              notifications.map((notification, idx) => (
                <NotificationsCard key={idx} notification={notification} />
              ))
            ) : (
              <>
                <div className='notifications__area__list--empty'>
                  <img src={Paperplane} alt='Imagem de um avião de papel.' draggable={false} />
                  <p>Nenhuma notificação disponível...</p>
                </div>
              </>
            )}
          </div>
          <div className='notifications__area__pagination'>
            <PaginationControl
              currentPage={currentPage}
              notificationsPerPage={notificationsPerPage}
              setNotificationsPerPage={value => {
                setNotificationsPerPage(value)
                setCurrentPage(1)
              }}
              totalNotifications={totalNotifications}
            />

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onClickAction={handlePaginationClick}
            />
          </div>
        </div>
      </div>
      <Warning />
      <Footer />
    </div>
  )
}

export default Notifications
