import React from 'react'
import { Mail, MailCheck, MailOpen, MailWarning, SendHorizonal } from 'lucide-react'

const NotifyStatus = ({ status }) => {
  return (
    <>
      {status === 'Confirmado' && (
        <div style={{ backgroundColor: '#EAFFDA' }}>
          <MailCheck color={'#2D6A00'} />
        </div>
      )}

      {status === 'Aberto' && (
        <div style={{ backgroundColor: '#FFF5DC' }}>
          <MailOpen color={'#7C5800'} />
        </div>
      )}

      {status === 'Enviado' && (
        <div style={{ backgroundColor: '#fafafa' }}>
          <SendHorizonal color={'#26a69a'} />
        </div>
      )}

      {status === 'Recebido' && (
        <div style={{ backgroundColor: '#D9D9D9' }}>
          <Mail color={'#3B3939'} />
        </div>
      )}

      {status === 'Não recebido' && (
        <div style={{ backgroundColor: '#FFEAEA' }}>
          <MailWarning color={'#E73E39'} />
        </div>
      )}
    </>
  )
}

export default NotifyStatus
