import React from 'react'
import terms from '../../assets/documents/politica-de-cancelamento.pdf'
import './index.scss'

const CancellationPolicy = () => {
  return (
    <div style={{ height: '100vh', overflowY: 'hidden' }}>
      <iframe src={terms} height='100%' width='100%' />
    </div>
  )
}

export default CancellationPolicy
