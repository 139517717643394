const INITIAL_STATE = {
  step: 0,
  plan: {},
  singlePlan: false,
  coupon: {
    free: false,
  },
  card: {
    number: '',
    holderName: '',
    expirationDate: '',
    cvv: '',
  },
  customer: {
    name: '',
    email: '',
    document: '',
    telephone: '',
    password: '',
    confirmPassword: '',
    confirmationCode: '',
  },
  address: {
    zipcode: '',
    neighborhood: '',
    street: '',
    streetNumber: '',
  },
  phone: {
    number: '',
  },
  recaptcha: '',
  terms: false,
  regulation: false,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_SUBSCRIPTION_STEP':
      return { ...state, step: action.payload }
    case 'CHANGE_SUBSCRIPTION_PLAN':
      return { ...state, plan: action.payload }
    case 'CHANGE_SUBSCRIPTION_COUPON':
      return { ...state, coupon: action.payload }
    case 'CHANGE_SUBSCRIPTION_CARD_FIELD':
      return { ...state, card: { ...state.card, [action.field]: action.payload } }
    case 'CHANGE_SUBSCRIPTION_CUSTOMER_FIELD':
      return { ...state, customer: { ...state.customer, [action.field]: action.payload } }
    case 'CHANGE_SUBSCRIPTION_ADDRESS_FIELD':
      return { ...state, address: { ...state.address, [action.field]: action.payload } }
    case 'CHANGE_SUBSCRIPTION_PHONE_FIELD':
      return { ...state, phone: { ...state.phone, [action.field]: action.payload } }
    case 'CHANGE_SUBSCRIPTION_TERMS':
      return { ...state, terms: action.payload }
    case 'CHANGE_REGULATION_TERMS':
      return { ...state, regulation: action.payload }
    case 'CHANGE_SUBSCRIPTION_SINGLEPLAN':
      return { ...state, singlePlan: action.payload }
    case 'CHANGE_SUBSCRIPTION_RECAPTCHA':
      return { ...state, recaptcha: action.payload }
    case 'RESET_SUBSCRIPTION':
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
