import React, { useEffect, useState, useRef, forwardRef } from "react";

import "./index.scss";

/**
 * @param {String} value - Input value
 * @param {String} label - The label
 * @param {String} placeholder - Text that appears in the input when it has no value set
 * @param {String} type - Type of input
 * @param {Boolean} required - Flag indicating required
 * @param {String} name - Input name
 * @param {Boolean} error - Flag indicating error
 * @param {Boolean} disabled - Flag indicating disabled
 * @param {Number} maxLength - Maximum number of characters of value
 * @param {Boolean} autoComplete - Enable/disable browser autocomplete feature
 * @param {Boolean} resizeVertical - Defines if an element is vertically resizable
 * @param {Boolean} resizeHorizontal - Defines whether an element is horizontally resizable
 * @param {Boolean} hasLabel - Defines if textarea has a label
 * @param {Boolean} autoResize - Defines if the textarea is resizable
 * @param {Boolean} bgColorSeparator - Separator color
 * @param {Function} onBlur - Callback on blur
 * @param {Function} onChange - Callback on change
 * @param {Function} onPressEnter - Function to be called when pressing enter
 * @param {Function} onInvalid - Callback on invalid
 * @param {Object}  style - Styling object
 *
 * @returns {React.ReactElement}
 */

const TextArea = ({
  value = "",
  label = "Sample",
  placeholder = "Sample",
  required = false,
  name,
  error = false,
  disabled = false,
  readOnly = false,
  maxLength = 1000,
  autoComplete = true,
  resizeVertical = false,
  resizeHorizontal = false,
  hasLabel = false,
  autoResize = false,
  withoutBorder = false,
  bgColorSeparator = "#fff",
  onBlur = (value = "") => true,
  onChange = (value = "") => {},
  onPressEnter = () => {},
  style,
  height = false,
}, ref) => {
  const [isFocus, setIsFocus] = useState(false);
  const [hasError, setHasError] = useState(error);
  const [hasValue, setHasValue] = useState(false);
  const fallbackRef = useRef(null);
  const textAreaRef = ref || fallbackRef

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
      let scrollHeight = textAreaRef.current.scrollHeight;
      if (scrollHeight < 52) {
        scrollHeight = 52;
      }
      textAreaRef.current.style.height = scrollHeight + "px";
      if (height) {
        textAreaRef.current.style.height = `${height}px`;
      } else {
        textAreaRef.current.style.height = `${Math.min(scrollHeight, 100)}px`;
      }

      if (scrollHeight > 100) {
        textAreaRef.current.style.overflowY = "scroll";
      } else {
        textAreaRef.current.style.overflowY = "hidden";
      }
    }
  }, [value]);

  useEffect(() => setHasError(error), [error]);

  function handleOnBlur(evt) {
    const value = evt.target.value;
    if (onBlur) {
      const response = onBlur(value);
      if (response === false) {
        setHasError(true);
        textAreaRef.current.setCustomValidity("Valor inválido");
      }
    }
    setIsFocus(false);
  }

  function handleOnChange(evt) {
    const value = evt.target.value;

    onChange && onChange(value);
    setHasError(error);
    textAreaRef.current.setCustomValidity("");
  }

  function handlePressEnter(evt) {
    if (evt.key === "Enter") onPressEnter && onPressEnter();
  }

  function returnLabelClassName() {
    let currentClassName = "textarea__label";
    if (isFocus || hasValue || value)
      currentClassName += " textarea__label--open";
    if (isFocus) currentClassName += " textarea__label--focus";
    return currentClassName;
  }

  function returnClassName() {
    let currentClassName = "textarea__wrapper";
    if(withoutBorder) currentClassName += " textarea__wrapper--without-border"
    if (hasError) currentClassName += " textarea__wrapper--error";
    if (disabled) currentClassName += " textarea__wrapper--disabled";
    if (resizeVertical)
      currentClassName += " textarea__wrapper--resize-vertical";
    if (resizeHorizontal)
      currentClassName += " textarea__wrapper--resize-horizontal";
    if (!hasLabel) currentClassName += " textarea__wrapper--without-label";
    if (autoResize) currentClassName += " textarea__wrapper--auto-resize";
    return currentClassName;
  }

  return (
    <div className={returnClassName()}>
      {hasLabel && (
        <span className={returnLabelClassName()}>
          {label}
          {required && " *"}
        </span>
      )}
      <div
        className="textarea__wrapper__separator"
        style={{ backgroundColor: bgColorSeparator }}
      />
      <textarea
        ref={textAreaRef}
        className="textarea"
        value={value}
        placeholder={placeholder}
        style={{ style }}
        onMouseDown={() => setIsFocus(true)}
        onClick={() => setIsFocus(true)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => setIsFocus(true)}
        onKeyPress={handlePressEnter}
        readOnly={readOnly}
        onAnimationStart={(e) =>
          e.animationName === "onAutoFillStart"
            ? setHasValue(true)
            : setHasValue(false)
        }
        required={required}
        disabled={disabled}
        onInvalid={() => setHasError(true)}
        maxLength={maxLength}
        autoComplete={autoComplete ? "on" : "none"}
        name={name}
      />
    </div>
  );
};

export default React.forwardRef(TextArea);
