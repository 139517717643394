import React, { useState } from 'react'

import { Eye, EyeOff } from 'lucide-react'

import './index.scss'

import { onlyNumber } from '../../constants/validate'

const Input = ({
  value,
  placeholder,
  onChange,
  inputType,
  type = '1',
  validate = false,
  format,
  number,
  style,
  onBlur = false,
  onPressEnter,
  fullWidth,
}) => {
  const [typeOfInput, setTypeOfInput] = useState(type)
  const [isPassword, setIsPassword] = useState(type === '6' ? true : false)
  const [showPassword, setShowPassword] = useState(false)

  function handleChange(value) {
    if (format) {
      if (number) {
        return format(onlyNumber(value))
      } else {
        return format(value)
      }
    } else {
      if (number) {
        return onlyNumber(value)
      } else {
        return value
      }
    }
  }
  function handlePressEnter(evt) {
    if (evt.key === 'Enter') {
      onPressEnter && onPressEnter()
    }
  }
  function handleShowPassword() {
    setShowPassword(!showPassword)
    if (showPassword) setTypeOfInput('6')
    else setTypeOfInput('2')
    console.log(type)
  }

  return (
    <div className='input__container'>
      <input
        className={`input input--type-${type}`}
        value={value}
        placeholder={placeholder}
        style={style}
        onChange={e => onChange && onChange(handleChange(e.target.value))}
        onBlur={() => onBlur && onBlur()}
        type={typeOfInput === '6' ? 'password' : inputType}
        //autoComplete='new-password'
        disabled={type === '3' ? true : false}
        onKeyPress={handlePressEnter}
      />
      {isPassword && (
        <div className='input__password' onClick={handleShowPassword}>
          {showPassword ? <EyeOff color='#26a69a' /> : <Eye color='#26a69a' />}
        </div>
      )}
    </div>
  )
}

export default Input
