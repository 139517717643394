import { Root, Thumb } from '@radix-ui/react-switch'
import React from 'react'

import './index.scss'

function Switch({ labelLeft, labelRight, checked, onCheckedChange }) {
  return (
    <form>
      <div className='switch'>
        {labelLeft && (
          <span className={!checked ? 'switch__label switch__label--checked' : 'switch__label'}>
            {labelLeft}
          </span>
        )}
        <Root className='switch__root' checked={checked} onCheckedChange={onCheckedChange}>
          <Thumb className='switch__thumb' />
        </Root>
        {labelRight && (
          <span className={checked ? 'switch__label switch__label--checked' : 'switch__label'}>
            {labelRight}
          </span>
        )}
      </div>
    </form>
  )
}

export default Switch
