import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, NavLink } from "react-router-dom"
import { validatePassword } from '../../constants/validate'
import Button from '../../components/Button'
import Input from '../../components/Input'
import axios from 'axios'
import './index.scss'


function NewPassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordNotFound, setPasswordNotFound] = useState(false)
  const { email, password } = useParams()

  const { user } = useSelector(state => state.login)

  useEffect(() => {
    if (password) {
      history.replace('/nova-senha')
      axios.post('user/verify-recover-password', { email, password })
        .then(response => {
          localStorage.setItem('user', JSON.stringify(response.data))
          axios.defaults.headers.common['authentication'] = response.data.token
          setUser(response.data)
        })
        .catch(err=>{
          setPasswordNotFound(true)
          console.log(err)
        })
    }
    else {
      history.push('/')
    }
  }, [])

  function setUser(user) {
    dispatch({ type: 'CHANGE_LOGIN_USER', payload: user })
  }

  function validateNewPassword() {
    if (!newPassword) {
      setError('Senha obrigatória.')
    } else if (newPassword !== confirmNewPassword) {
      setError('Senhas não coincidem.')
    } else if(!validatePassword(newPassword)) {
      setError('Sua senha deve ter no mínimo 8 caracteres contendo letras maiúsculas e minúsculas, números e caracteres especiais.')
    } else {
      setError('')
      changePassword()
    }
  }
  function changePassword() {
    setLoading(true)
    axios.put('user/change-password', { password: newPassword, email: user.email })
      .then(response => {
        setLoading(false)
        history.push('/notificacoes')
      })
      .catch(err=>{
        setError("Ocorreu erro, tente novamente.")
        console.log(err)
      })
  }

  function handlenewPassword(value) {
    setNewPassword(value)
  }
  function handleconfirmNewPassword(value) {
    setConfirmNewPassword(value)
  }
  function handleClickResetPassword() {
    validateNewPassword()
  }

  return (
    <div className="new-password">
      {!passwordNotFound ? (
        <>
          <h1>Redefina a sua senha</h1>
          <p>Por favor, por motivos de segurança recomendamos que você altere a sua senha padrão.</p>
          <div className="new-password__form">
            <Input type='2' placeholder='Senha' onChange={handlenewPassword} value={newPassword} password={true} />
            <Input type='2' placeholder='Confirmar Senha' onChange={handleconfirmNewPassword} value={confirmNewPassword} password={true} />
            <Button type='1' scrollLink chevronRight={true} loading={loading} onClick={handleClickResetPassword} style={{ minHeight: 58, fontSize: 14 }}>Redefinir</Button>
            <NavLink className="new-password__form__reset-later" to="/notificacoes" onClick={() => { }}><p>Redefinir depois</p></NavLink>
            <div className={error ? "login__error login__error--visible" : "login__error"}>
              <h3>{error}</h3>
            </div>
          </div>
        </>
      ) : (
          <>
            <h1>Link expirado</h1>
            <p>Por favor, utilize um link mais recente.</p>
            <NavLink className="new-password__form__go-home" to="/" onClick={() => { }}><p>Voltar para home</p></NavLink>
          </>
        )}
    </div>
  )
}

export default NewPassword
