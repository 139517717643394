import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import Warning from '../../../../components/Warning'

import PlansStep from './components/StepPlans'

import { freePlan, rollup } from '../../../../constants/others'
import { formatDate } from '../../../../constants/validate'

import { ReactComponent as AlertCircle } from '../../../../assets/alert-circle.svg'

import './index.scss'

const Plans = ({ coupon, changePlan, handleCancelPlan, openContactModal }) => {
  const login = useSelector(state => state.login)
  const dispatch = useDispatch()
  const { cicleDate } = login.user.subscription
  const [showModalCancelPlan, setShowModalCancelPlan] = useState(false)
  useEffect(() => {
    rollup()
  }, [])

  function handleShowModalCancelPlan() {
    setShowModalCancelPlan(true)
  }

  function handleCloseModalCancelPlan() {
    setShowModalCancelPlan(false)
  }

  function hancleClickFreeTest() {
    if (login.user.subscription.planId === 999) {
      showError('Você já está no plano gratuito', 'Agora é só usar!')
    } else {
      changePlan(freePlan)
    }
    // if (!_.isEmpty(coupon.plans)){
    //   coupon.plans.map(plan => {
    //     if (plan.isFree) changePlan(freePlan)
    //   })
    // }
    // else {
    //   history.push(`/planos?cupom=NOTIFICCA3`)
    //   window.location.reload()
    // }
  }

  function showError(error, subError = false) {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
    }, 5000)
  }

  return (
    <div className='plans'>
      <div className='plans__container'>
        <div className='plans__header'>
          <div className='plans__header__content'>
            <h2>Faça o Upgrade do seu plano!</h2>
            <p>Aproveite o melhor com o plano ideal para você.</p>
          </div>
        </div>

        <PlansStep
          handleCancelPlan={handleShowModalCancelPlan}
          handleChangePlan={plan => changePlan(plan)}
          handleOpenContactModal={() => openContactModal(true)}
        />

        <Modal
          show={showModalCancelPlan}
          //close={handleCloseModalCancelPlan}
          title='Deseja mesmo cancelar seu plano?'
          titleWidth={256}
          //modalWidth={800}
          renderFooter={
            <div>
              <Button action={true} type={6} onClick={handleCloseModalCancelPlan}>
                Voltar
              </Button>
              <Button action={true} type={7} onClick={handleCancelPlan}>
                Cancelar plano
              </Button>
            </div>
          }>
          <p>
            O cancelamento passa a vigorar ao fim desse ciclo de cobrança:{' '}
            <strong>{formatDate(cicleDate, false, true)}.</strong>
          </p>
          <br />
          <p>Você ainda poderá enviar notificações não utilizadas neste mês.</p>
        </Modal>
      </div>
      <Warning />
    </div>
  )
}

export default Plans
