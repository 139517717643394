import React from 'react'
import './index.scss'
import PlansIcon from '../../../../assets/plansIcon.svg'

import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../components/Checkbox'
// import { Button } from 'react-scroll'
// import Button from '../../../../components/Button'
// import Input from "../../../../components/Input"

function Checkout() {
    const dispatch = useDispatch()
    const { plan, customer, card, terms, regulation, singlePlan } = useSelector(state => state.subscription)
    const { user } = useSelector(state => state.login)

    const cardNumber = () => "**** " + card.number.replace(/\D/g, "").slice(12, 16)

    const handleAcceptTerms = () => dispatch({ type: "CHANGE_SUBSCRIPTION_TERMS", payload: !terms })
    const handleAcceptRegulation = () => dispatch({ type: "CHANGE_REGULATION_TERMS", payload: !regulation })

    function addZeroes(str) {
      if (parseFloat(str) % 1) {
        return (parseFloat(str)).toFixed(2).replace('.',',')
      } else {
        return str
      }
    }

    return (
      <div className="subscription__checkout__container__card">
        <div className="subscription__checkout__container__card__header">
          <div className="subscription__checkout__container__card__header__left">
            <img src={PlansIcon} alt="" />
            <h2>{plan.header}</h2>
            {plan.discount.tag&&<span>{plan.discount.tag}</span>}
          </div>
        </div>
        <div className="subscription__checkout__container__card__separator"></div>
        <h5>Cadastro</h5>
        <div className="subscription__checkout__container__card__info">
          <div>
            <p>Nome:</p>
            <p>{customer.name || user.name}</p>
            <p>E-mail:</p>
            <p>{customer.email || user.email}</p>
          </div>
          <div>
            <p>CPF/CNPJ:</p>
            <p>{customer.document || user.document}</p>
          </div>
        </div>

        {!plan.isFree &&
        <>
          <div className="subscription__checkout__container__card__separator" />
          <div className="subscription__checkout__container__card__payment">
            <h5>Pagamento</h5>
            <h4>Cartão <span>{cardNumber()}</span></h4>
          </div>
        </>
        }
        <div className="subscription__checkout__container__card__separator" />
        {/* <div className="subscription__checkout__container__card__discount">
          <h4>Cupom de Desconto</h4>
          <div className="subscription__checkout__container__card__discount__buttons">
            <Input type='5' placeholder='Código' inputType='coupon' />
            <Button type='11' width='79px' height='36px' fontSize='12px'>Adicionar</Button>
          </div>
        </div> */}
        <div className="subscription__checkout__container__card__subtotal">
          <div>
            <h4>Subtotal</h4>
            {plan.discount.originalValue&&<h4>Desconto assinatura</h4>}
            {plan.isFree&&<h4>Desconto Teste Grátis</h4>}
          </div>
          <div>
            <h4>R$ {plan.discount.originalValue ? addZeroes(plan.discount.originalValue) : addZeroes(plan.value)}</h4>
            {plan.discount.originalValue&&<h4>-R$ {addZeroes(Math.abs(plan.value-plan.discount.originalValue))}</h4>}
            {plan.isFree&&<h4>-R$ {addZeroes(Math.abs(plan.value))}</h4>}
            {/* {plan.discount&&<h4>-R$ {Math.abs(plan.value.replace(/[\D]+/g,'')-plan.discount.originalValue.replace(/[\D]+/g,''))}</h4>} */}
            {/* {plan.discount && <h4>-R$ {plan.discount}</h4>} */}
            {/* {!plan.discount && <h4>R$ 0</h4>} */}
          </div>
        </div>
        <div className="subscription__checkout__container__card__total">
          <h2>Total</h2>
          {plan.isFree?
            // <h4>R$ <span>*0</span> {!singlePlan&&"/mês"}</h4>
            <h4>R$ <span>0</span> {!singlePlan&&"/mês"}</h4>
          :
            <h4>R$ <span>{addZeroes(plan.value)}</span> {!singlePlan&&"/mês"}</h4>
          }
        </div>

        {/* {plan.isFree&&
          <div className="subscription__checkout__container__card__free">
            {`*Você será cobrado R$${addZeroes(plan.discount?.originalValue)} após os ${plan.discount?.tag}.`}
          </div>
        } */}

        <div className="subscription__checkout__container__card__terms">
          <Checkbox isChecked={terms} onChange={() => handleAcceptTerms()} />
          <span>Aceito os <a href='https://notificca.com.br/termos-de-uso' target='_blank' rel="noopener noreferrer">&nbsp;termos de uso&nbsp;</a> da plataforma.</span>
        </div>
        {plan.isFree &&
        <div className="subscription__checkout__container__card__terms">
          <Checkbox isChecked={regulation} onChange={() => handleAcceptRegulation()} />
          <span>Aceito o <a href='https://notificca.com.br/regulamento-campanha' target='_blank' rel="noopener noreferrer">&nbsp;regulamento&nbsp;</a> da campanha.</span>
        </div>
        }
      </div>
    )
}

export default Checkout
