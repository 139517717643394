import axios from 'axios'
import _ from 'lodash'
import { Lock, Mail } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  formatDocument,
  onlyNumber,
  validadeEin,
  validateCnpj,
  validateCpf,
  validateEmail,
  validatePassword,
} from '../../constants/validate'
import { cancelSubscription, getUserPlan } from '../../services/planService'
import {
  resendCodeEmail,
  sendCodeEmail,
  updatePassword,
  updateUserInfo,
  verifyCodeEmail,
} from '../../services/profileService'

import Button from '../../components/Button'
import { CodeInput } from '../../components/CodeInput'
import { Alert } from '../../components/DesignSystem/Alert'
import Input from '../../components/DesignSystem/Input'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { ModalCancelPlan } from '../../components/ModalCancelPlan'
import Spinner from '../../components/Spinner'
import Warning from '../../components/Warning'

import ModalForm from './components/ModalForm'
import PlanCard from './components/PlanCard'
import ResendCodeTimer from './components/ResendCodeTimer'

import './index.scss'

const Profile = () => {
  const { user } = useSelector(state => state.login)
  const { email, name, document, subscription } = user
  const { cicleDate } = subscription
  const dispatch = useDispatch()
  let history = useHistory()

  const [activeModal, setActiveModal] = useState('')
  const [userName, setUserName] = useState(name)
  const [documentNumber, setDocumentNumber] = useState(document)
  const [userEmail, setUserEmail] = useState(email)
  const [plan, setPlan] = useState()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [code, setCode] = useState('')
  const [isVerify, setIsVerify] = useState(false)
  const [isSuccessProfileData, setIsSuccessProfileData] = useState(false)
  const [isSuccessNewEmail, setIsSuccessNewEmail] = useState(false)
  const [isSuccessNewPassword, setIsSuccessNewPassword] = useState(false)
  const [showModalCancelPlan, setShowModalCancelPlan] = useState(false)
  const [isLoadingPlan, setIsLoadingPlan] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [hasResentCode, setHasResentCode] = useState(false)
  const [showTimer, setShowTimer] = useState(false)

  const CODE_LENGTH = new Array(6).fill(0)

  const showError = useCallback(
    (error, subError = false) => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

      if (subError) {
        dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
      }

      setTimeout(() => {
        dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
        dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
      }, 5000)
    },
    [dispatch]
  )

  useEffect(() => {
    if (!_.isEmpty(user.token)) {
      const getPlan = async () => {
        setIsLoadingPlan(true)
        try {
          const { plans } = await getUserPlan()
          setPlan(plans[0])
        } catch (e) {
          console.error(e)
          showError('Ocorreu um erro ao cancelar plano')
        } finally {
          setIsLoadingPlan(false)
        }
      }

      getPlan()
    }
  }, [showError, user.token])

  useEffect(() => {
    setUserName(name)
    setUserEmail(email)
    setDocumentNumber(document)
  }, [document, email, name])

  useEffect(() => {
    if (hasResentCode) {
      const resentCodeTimer = setTimeout(() => {
        setShowTimer(false)
        setHasResentCode(false)
      }, 90000)

      return () => {
        clearTimeout(resentCodeTimer)
      }
    }
  }, [hasResentCode])

  function switchPage(page) {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: page })
    history.push(`/${page}`)
  }

  function handleChangeCode(e) {
    const value = e.target.value
    if (code.length >= CODE_LENGTH.length) {
      return null
    } else {
      setCode((code + value).slice(0, CODE_LENGTH.length))
    }
  }

  function handleKeyUpCode(e) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCode(code.slice(0, code.length - 1))
    }
  }

  function handleChangePlan() {
    switchPage('planos')
  }

  async function handleCancelPlan() {
    try {
      await cancelSubscription()
      dispatch({ type: 'CHANGE_LOGIN_SUBSCRIPTION' })
      history.push('/planos/cancelado')
      removeSubscriptionFromStorage(user)
    } catch (e) {
      showError('Erro ao cancelar o plano. Tente novamente mais tarde.')
    }
  }

  function removeSubscriptionFromStorage(newUser) {
    newUser.subscription.planId = null
    newUser.subscription.id = null
    newUser.subscription.maxQuota = null
    newUser.subscription.plan = null
    newUser.subscription.status = 'canceled'
    localStorage.setItem('user', JSON.stringify(newUser))
  }

  async function handleSaveProfileData() {
    setIsLoading(true)
    try {
      const { data } = await updateUserInfo(userName, documentNumber, password)
      dispatch({
        type: 'CHANGE_LOGIN_USER',
        payload: { ...user, name: data.name, document: data.documentNumber },
      })
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          name: data.name,
          document: data.documentNumber,
        })
      )
      setPassword('')
      setPasswordConfirmation('')
      setIsSuccessProfileData(true)
    } catch (e) {
      console.error(e)
      const { errors } = e.response.data
      showError(errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSaveNewPassword() {
    setIsLoading(true)
    try {
      await updatePassword(password, newPassword)
      setIsSuccessNewPassword(true)
      setPassword('')
      setNewPassword('')
      setNewPasswordConfirmation('')
    } catch (e) {
      console.error(e)
      const { errors } = e.response.data
      showError(errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSendCode() {
    setIsLoading(true)
    try {
      await sendCodeEmail(newEmail)
      setIsVerify(true)
      setShowTimer(true)
    } catch (e) {
      console.error(e)
      const { errors } = e.response.data
      showError(errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleResendCode() {
    setIsLoading(true)
    try {
      await resendCodeEmail(newEmail)
      setIsVerify(true)
      setShowTimer(true)
    } catch (e) {
      console.error(e)
      const { errors } = e.response.data
      showError(errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleVerifyCode() {
    setIsLoading(true)
    try {
      const { data, included } = await verifyCodeEmail(newEmail, code)

      axios.defaults.headers.common['authentication'] = included[0].token
      dispatch({
        type: 'CHANGE_LOGIN_USER',
        payload: { ...user, token: included[0].token, email: data.email },
      })
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, token: included[0].token, email: data.email })
      )
      setNewEmail('')
      setIsSuccessNewEmail(true)
    } catch (e) {
      console.error(e)
      const { errors } = e.response.data
      showError(errors[0].message)
    } finally {
      setIsLoading(false)
    }
  }

  function handleDocumentError(value) {
    let pass = false
    if (
      onlyNumber(value).length === 6 ||
      (onlyNumber(value).length === 9 && validadeEin(value)) ||
      (onlyNumber(value).length === 11 && validateCpf(value.replace(/\D/g, ''))) ||
      (onlyNumber(value).length === 14 && validateCnpj(value))
    ) {
      pass = true
    }

    return pass
  }

  function validateButton() {
    let error = false
    if (_.isEmpty(userName)) {
      error = true
    }
    if (!validateEmail(userEmail)) {
      error = true
    }
    if (!handleDocumentError(documentNumber)) {
      error = true
    }
    return error
  }

  function validateProfileDataModalButton() {
    let error = false
    if (
      _.isEmpty(password) ||
      _.isEmpty(passwordConfirmation) ||
      password !== passwordConfirmation
    ) {
      error = true
    }

    return error
  }

  function validateNewPasswordModalButton() {
    let error = false
    if (
      _.isEmpty(password) ||
      _.isEmpty(newPassword) ||
      _.isEmpty(newPasswordConfirmation) ||
      !validatePassword(newPassword) ||
      newPassword !== newPasswordConfirmation
    ) {
      error = true
    }

    return error
  }

  function validateNewEmailModalButton() {
    let error = false
    if (!validateEmail(newEmail)) {
      error = true
    }

    return error
  }

  return (
    <div className='profile-container'>
      <div className='profile'>
        <section className='profile__edit'>
          <header>
            <h1 className='title'>Editar perfil</h1>
            <p>Verifique se as informações estão corretas antes de salvar.</p>
          </header>
          <main>
            <Input
              label='Nome'
              placeholder=''
              type='text'
              value={userName}
              onChange={setUserName}
              required
            />
            <Input
              label='CPF/CNPJ'
              placeholder=''
              type='text'
              value={formatDocument(documentNumber)}
              onChange={setDocumentNumber}
              onBlur={handleDocumentError}
              required
            />
          </main>
          <footer>
            <Button
              height={36}
              width={156}
              disabled={validateButton()}
              onClick={() => {
                setActiveModal('profile-data')
              }}
              action={true}>
              Salvar mudanças
            </Button>
          </footer>
        </section>
        <section className='profile__auth'>
          <header className='profile__auth__header'>
            <div className='profile__auth__title'>
              <h2 className='title title--h2'>Dados de autenticação</h2>
              <p>Altere os dados de autenticação e confirme para validar as alterações</p>
            </div>
            <div className='profile__auth__actions'>
              <Button
                width={156}
                height={36}
                action={true}
                disabled={validateButton()}
                onClick={() => {
                  setActiveModal('new-email')
                  setNewEmail(userEmail)
                  setCode('')
                }}>
                <div className='profile__auth__actions__btn-content'>
                  <Mail size={16} />
                  <small>Trocar e-mail</small>
                </div>
              </Button>
              <Button
                width={156}
                height={36}
                type={3}
                action={true}
                disabled={validateButton()}
                onClick={() => setActiveModal('new-password')}>
                <div className='profile__auth__actions__btn-content'>
                  <Lock size={16} />
                  <small>Trocar senha</small>
                </div>
              </Button>
            </div>
          </header>
          <main>
            <Input
              label='E-mail'
              placeholder=''
              type='email'
              value={userEmail}
              onChange={setUserEmail}
              required
            />
          </main>
        </section>
        {plan && (
          <section className='profile__plan'>
            <header className='profile__plan__header'>
              <h2 className='title title--h2'>Faça um upgrade do seu plano atual</h2>
              <p>
                Aproveite o melhor do ChatGPT com o plano ideal para você. Entre em contato com
                nosso time de vendas.
              </p>
            </header>
            <main>
              {isLoadingPlan ? (
                <Spinner />
              ) : (
                <PlanCard
                  plan={plan}
                  onChangePlan={handleChangePlan}
                  onCancelPlan={() => setShowModalCancelPlan(true)}
                />
              )}
            </main>
          </section>
        )}

        <ModalCancelPlan
          show={showModalCancelPlan}
          setShow={setShowModalCancelPlan}
          onCancelPlan={handleCancelPlan}
          cicleDate={cicleDate}
        />

        {/* Confirm changes with password  */}
        <ModalForm
          show={activeModal === 'profile-data'}
          title={'Confirmar alteração de dados'}
          onClose={() => {
            setActiveModal('')
            setIsSuccessProfileData(false)
          }}
          onConfirm={handleSaveProfileData}
          isLoading={isLoading}
          disabledButton={validateProfileDataModalButton()}
          isSuccess={isSuccessProfileData}
          isSuccessTitle='Dados alterados com sucesso!'
          isSuccessText='Os dados foram alterados com sucesso e agora você poderá ver as novas informações na tela de configuração do perfil.'>
          <div className='profile__modal_form_content'>
            <Alert
              type='warn'
              title='Alteração de informações'
              text='Certificados gerados anteriormente não serão afetados por essa alteração de dados.'
              canClose={false}
            />
            <p>Preencha com sua senha</p>
            <Input
              label='Senha'
              placeholder=''
              type='password'
              value={password}
              onChange={setPassword}
              required
            />
            <Input
              label='Confirme a sua senha'
              placeholder=''
              type='password'
              value={passwordConfirmation}
              onChange={setPasswordConfirmation}
              required
            />
          </div>
        </ModalForm>

        {/* Email change modal */}
        <ModalForm
          show={activeModal === 'new-email'}
          title={'Trocar e-mail'}
          buttonText={isVerify ? 'Confirmar' : 'Enviar'}
          disabledButton={
            isVerify ? code.length !== CODE_LENGTH.length : validateNewEmailModalButton()
          }
          onClose={() => {
            setActiveModal('')
            setIsVerify(false)
            setIsSuccessNewEmail(false)
          }}
          onConfirm={isVerify ? () => handleVerifyCode() : () => handleSendCode()}
          isLoading={isLoading}
          isSuccess={isSuccessNewEmail}
          isSuccessTitle='E-mail confirmado com sucesso!'
          isSuccessText='O seu e-mail foi alterado com sucesso e agora você poderá ver os seus novos dados na tela de configuração do perfil.'>
          {isVerify ? (
            <div className='profile__modal_form_content profile__modal_form_content--verify'>
              <h3>Verifique o email {newEmail}</h3>
              <CodeInput
                codeLength={CODE_LENGTH}
                code={code}
                onChange={handleChangeCode}
                onKeyUp={handleKeyUpCode}
              />
              <p>
                Enviamos um email com um código de confirmação. Por favor, verifique o email "
                {newEmail}" e insira o código.
              </p>
              <button
                type='action'
                name='resend-code'
                onClick={handleResendCode}
                disabled={isLoading || hasResentCode}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    Reenviar Código{' '}
                    {showTimer && (
                      <ResendCodeTimer
                        hasResentCode={hasResentCode}
                        changeHasResentCode={status => setHasResentCode(status)}
                      />
                    )}
                  </>
                )}
              </button>
            </div>
          ) : (
            <div className='profile__modal_form_content'>
              <Alert
                type='warn'
                title='Alteração de e-mail'
                text='Certificados gerados anteriormente não serão afetados por essa alteração de e-mail.'
                canClose={false}
              />
              <p>Preencha com seu novo e-mail</p>
              <Input
                label='Novo E-mail'
                placeholder=''
                type='email'
                value={newEmail}
                onChange={setNewEmail}
                required
              />
            </div>
          )}
        </ModalForm>

        {/* Password change modal */}
        <ModalForm
          show={activeModal === 'new-password'}
          title={'Trocar senha'}
          onClose={() => {
            setActiveModal('')
            setIsSuccessNewPassword(false)
          }}
          onConfirm={handleSaveNewPassword}
          isLoading={isLoading}
          disabledButton={validateNewPasswordModalButton()}
          isSuccess={isSuccessNewPassword}
          isSuccessTitle='Senha alterada com sucesso!'
          isSuccessText='O sua senha foi alterada com sucesso e agora você poderá ver os seus novos dados na tela de configuração do perfil.'>
          <div className='profile__modal_form_content'>
            <Alert
              type='warn'
              title='Alteração de Senha'
              text='Sua nova senha necessita ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caracter especial.'
              canClose={false}
            />
            <p>Preencha com sua senha atual</p>
            <Input
              label='Senha'
              placeholder=''
              type='password'
              value={password}
              onChange={setPassword}
              required
            />
            <p>Preencha com sua nova senha</p>
            <Input
              label='Nova senha'
              placeholder=''
              type='password'
              value={newPassword}
              onChange={setNewPassword}
              required
            />
            <Input
              label='Repita a nova senha'
              placeholder=''
              type='password'
              value={newPasswordConfirmation}
              onChange={setNewPasswordConfirmation}
              required
            />
          </div>
        </ModalForm>
      </div>
      <Footer />
      <Warning />
    </div>
  )
}

export default Profile
