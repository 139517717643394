import React, { useState } from 'react'
import { X, CheckCircle, AlertCircle, AlertOctagon, XCircle } from 'lucide-react'

import './index.scss'

const AlertIcon = ({ type }) => {
  if (type === 'success') {
    return <CheckCircle size={16} color='#2d6a00' />
  }
  if (type === 'info') {
    return <AlertCircle size={16} color='#006192' />
  }
  if (type === 'warn') {
    return <AlertOctagon size={16} color='#7c5800' />
  }
  if (type === 'error') {
    return <XCircle size={16} color='#670300' />
  }
}

export const Alert = ({ type, title, text, canClose }) => {
  const [isHidden, setIsHidden] = useState(false)

  return (
    <div
      className={`alert__container alert__container--${type} ${
        isHidden && 'alert__container--hidden'
      }`}>
      <div className={`alert__icon-wrapper alert__icon-wrapper--${type}`}>
        <AlertIcon type={type} />
      </div>
      <div className='alert__content'>
        <div className='alert__header'>
          <h3>{title}</h3>

          {canClose && (
            <button className='alert__close' type='action' onClick={() => setIsHidden(true)}>
              <X size={12} color='#3B3B3B' />
            </button>
          )}
        </div>
        <p>{text}</p>
      </div>
    </div>
  )
}
