import React from 'react'
import { X } from 'lucide-react'

import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'

import './index.scss'

const ModalForm = ({
  show,
  title,
  buttonText = 'Salvar',
  disabledButton = false,
  onClose,
  onConfirm,
  isLoading,
  isSuccess,
  isSuccessTitle,
  isSuccessText,
  children,
}) => {
  return (
    <div className={`modal_form ${show && 'modal_form--visible'}`}>
      <div className={`modal_form__container ${show && 'modal_form__container--visible'}`}>
        <div className='modal_form__container__top'>
          <div className='modal_form__container__header'>
            <div className='modal_form__container__title'>
              <h2>{title}</h2>
            </div>
            {onClose && <X className='modal_form__container__close' size={16} onClick={onClose} />}
          </div>

          <div
            className={`modal_form__container__content ${
              isSuccess && 'modal_form__container__content--center'
            }`}>
            {isSuccess ? (
              <div className='modal_form__container__content__success'>
                <h2>{isSuccessTitle}</h2>
                <p>{isSuccessText}</p>
              </div>
            ) : (
              <>{children}</>
            )}
          </div>
        </div>

        <div className='modal_form__contaier__footer'>
          <div className='modal_form__container__footer__actions'>
            {isSuccess ? (
              <Button type={1} action={true} onClick={onClose} height={36} width={91}>
                Fechar
              </Button>
            ) : (
              <>
                <Button type={3} action={true} onClick={onClose} height={36} width={104}>
                  Cancelar
                </Button>
                <Button
                  type={1}
                  disabled={disabledButton || isLoading}
                  action={true}
                  onClick={onConfirm}
                  height={36}
                  width={87}>
                  {isLoading ? <Loading /> : buttonText}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalForm
