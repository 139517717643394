import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import Swiper from 'react-id-swiper'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import SwiperCore, { A11y, Controller, Navigation, Pagination, Scrollbar } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import env from '../../../../../../env'

import Spinner from '../../../../../../components/Spinner'

import ButtonGPT from '../ButtonGPT'
import PlansToggle from '../PlansToggle'
import SlideInfo from '../SlideInfo'

import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/swiper.scss'
import './index.scss'

SwiperCore.use([Controller, Navigation, Pagination, Scrollbar, A11y])

/*
  Exemplo de estrutura da lista de planos
  {id:number,header:"",description:"",value:"",numberOfNotifications:"",info:[bool,bool,bool,bool,bool],discount:{tag:"",originalValue:""},recommended:bool}
*/
const plansSingle = {
  production: [
    {
      planId: 1,
      header: '1 Notificação',
      value: 11.9,
      numberOfNotifications: '1',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
    },
    {
      planId: 2,
      header: '3 Notificações',
      value: 35,
      numberOfNotifications: '3',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
    },
    // #greenfriday plans
    //{ planId: 3, header: "10 notificações", value: "1", numberOfNotifications: "10", info: [true, false, false, false, false], discount: { tag: "#greenfriday", originalValue: "108", discount:{tag:null,originalValue:null} }, isPromo: true, discount:{tag:null,originalValue:null} },
    {
      planId: 3,
      header: '10 Notificações',
      value: 108,
      numberOfNotifications: '10',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
    },
    {
      planId: 4,
      header: '50 Notificações',
      value: 480,
      numberOfNotifications: '50',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
    },
    // { planId: "premium", header: "Personalizado", description: "Quantidade de notificações de acordo com sua demanda.", value: "Via contato", numberOfNotifications: "X", info: [true, true, true, true, true], discount:{tag:null,originalValue:null} }
  ],
  dev: [
    {
      planId: 3,
      header: 'Dev 1',
      value: 1,
      numberOfNotifications: '10',
      info: [true, false, false, false, false],
      discount: { tag: '#greenfriday', originalValue: 11.9 },
      isPromo: true,
    },
    {
      planId: 3,
      header: 'Dev 3',
      value: 1,
      numberOfNotifications: '10',
      info: [true, false, false, false, false],
      discount: { tag: '#greenfriday', originalValue: 11.9 },
      isPromo: true,
    },
    {
      planId: 4,
      header: 'Dev 10',
      value: 480,
      numberOfNotifications: '50',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
    },
    {
      planId: 4,
      header: 'Dev 50',
      value: 480,
      numberOfNotifications: '50',
      info: [true, false, false, false, false],
      discount: { tag: null, originalValue: null },
      description: 'Ideal para empresas/escritórios de pequeno porte.',
    },
    // { planId: "premium", header: "Personalizado", description: "Quantidade de notificações de acordo com sua demanda.", value: "Via contato", numberOfNotifications: "X", info: [true, true, true, true, true], discount:{tag:null,originalValue:null} }
  ],
}

function StepPlans({ handleCancelPlan, handleChangePlan, handleOpenContactModal }) {
  const dispatch = useDispatch()
  let history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const couponName = searchParams.get('cupom')
  const { singlePlan } = useSelector(state => state.subscription)
  const { user } = useSelector(state => state.login)

  const [loading, setLoading] = useState(false)
  const [isScreenXl, setIsScreenXL] = useState(false)

  const [couponVisible, setCouponVisible] = useState(false)
  const [currentCoupon, setCurrentCoupon] = useState('')
  const [validCoupon, setValidCoupon] = useState(false)

  const [planChecked, setPlanChecked] = useState(0)
  const [plansSubscriptions, setPlansSubscriptions] = useState([])

  // Auto aplicar cupom
  // useEffect(() => {
  //   if (!couponName) {
  //     history.push(`/planos?cupom=NOTIFICCA3`)
  //     window.location.reload()
  //   }
  // }, [])

  // Observa redimencionamento da página
  useEffect(() => {
    const resizeListener = () => {
      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (screenWidth >= 720) {
        setIsScreenXL(true)
      } else {
        setIsScreenXL(false)
      }
    }
    resizeListener()
    window.addEventListener('resize', resizeListener)

    if (user.token) {
      setLoading(true)
      axios.get('/plans').then(response => {
        setLoading(false)
        const newPlansSubscriptions = response.data.plans

        setPlansSubscriptions(newPlansSubscriptions)
      })
    }

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [user])

  // Seleciona o plano atual caso usuário esteja logado
  useEffect(() => {
    if (user.subscription.planId) {
      // dispatch({ type: 'CHANGE_SUBSCRIPTION_SINGLEPLAN', payload: false })
      const currentPlan = plansSubscriptions.findIndex(e => e.planId === user.subscription.planId)
      if (currentPlan !== -1) {
        setPlanChecked(currentPlan)
      } else {
        setPlanChecked(0)
      }
    }
  }, [plansSubscriptions, user])

  // useEffect(() => {
  //   axios
  //     .post('coupons/validate', { name: couponName })
  //     .then(response => {
  //       setCouponVisible(true)
  //       setValidCoupon(true)
  //       const responseList = response.data.plans
  //       dispatch({
  //         type: 'CHANGE_SUBSCRIPTION_COUPON',
  //         payload: response.data,
  //       })
  //       // dispatch({ type: 'CHANGE_SUBSCRIPTION_SINGLEPLAN', payload: false })
  //       setPlansSubscriptions(responseList)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  //   return () => {}
  // }, [])

  function showError(error, subError = false) {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
    }, 5000)
  }

  const returnPlansList = () => {
    const forceProduction = true
    if (env === 'production' || forceProduction) {
      if (singlePlan) {
        return plansSingle.production
      } else {
        return plansSubscriptions
      }
    } else {
      if (singlePlan) {
        return plansSingle.dev
      } else {
        return plansSubscriptions
      }
    }
  }

  const handleCheckPlan = index => {
    setPlanChecked(index)
  }
  const handleSelectPlan = plan => {
    //setPlanSelected(plan)
    if (plan.planId === 'premium') handleOpenContactModal()
    else if (Number(user.subscription.planId) === plan.planId) return handleCancelPlan()
    else handleChangePlan(plan)
    //console.log("selecionar plano", plan)
  }
  // function handleValidateCoupon() {
  //   // let prevPlansList = { ...plansList }//(env === 'production') ? {...plansList.production.subscription} : {...plansList.dev.subscription}
  //   //let prevPlansList = { ...plansSubscriptions }//(env === 'production') ? {...plansList.production.subscription} : {...plansList.dev.subscription}
  //   axios
  //     .post('coupons/validate', { name: currentCoupon })
  //     .then(response => {
  //       history.replace(`/planos?cupom=${response.data.name}`)
  //       setValidCoupon(true)
  //       const responseList = response.data.plans
  //       dispatch({
  //         type: 'CHANGE_SUBSCRIPTION_COUPON',
  //         payload: response.data,
  //       })
  //       // dispatch({ type: 'CHANGE_SUBSCRIPTION_SINGLEPLAN', payload: false })
  //       setPlansSubscriptions(responseList)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       if (err.response.status) {
  //         switch (err.response.data) {
  //           case 'Expired coupon':
  //             showError('Cupom expirado', 'O período de uso deste cupom acabou.')
  //             break
  //           case 'Coupon out of quantity':
  //             showError('Cupom expirado', 'A quantidade de uso deste cupom chegou ao limite.')
  //             break
  //           default:
  //             showError('Cupom inválido', 'Verifique o cupom e tente novamente')
  //             break
  //         }
  //       }
  //     })
  // }

  const showMonth = () => (!singlePlan ? '/mês' : '')

  function className(e, i) {
    let className = ['step-plans__swiper__slide']
    if (planChecked === i) className.push('step-plans__swiper__slide--selected')
    if (e.planId === 'premium') className.push('step-plans__swiper__slide--premium')
    return className.join(' ')
  }

  return (
    <div className='step-plans'>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {user.subscription.planId && (
            <div className='step-plans__info__discount'>
              <PlansToggle />
            </div>
          )}
          <div className='step-plans__container'>
            <Swiper
              className={'step-plans__swiper'}
              spaceBetween={8}
              slidesPerView={'auto'}
              centeredSlides={!isScreenXl}
              rebuildOnUpdate
              renderPrevButton={() => (
                <div className='step-plans__swiper__navigation__prev'>
                  <ChevronLeft />
                </div>
              )}
              renderNextButton={() => (
                <div className='step-plans__swiper__navigation__next'>
                  <ChevronRight />
                </div>
              )}
              breakpoints={{
                1: {
                  slidesPerView: 'auto',
                  spaceBetweenSlides: 50,
                },
                720: {
                  slidesPerView: 2,
                  spaceBetweenSlides: 50,
                },
                960: {
                  slidesPerView: 3,
                  spaceBetweenSlides: 50,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetweenSlides: 64,
                },
              }}
              navigation={{
                nextEl: '.step-plans__swiper__navigation__next',
                prevEl: '.step-plans__swiper__navigation__prev',
                disabledClass: 'step-plans__swiper__navigation__button--disabled',
              }}
              pagination={{
                el: '.step-plans__swiper__navigation__pagination',
                type: 'bullets',
                clickable: true,
                bulletClass: 'step-plans__swiper__navigation__pagination__bullet',
                bulletActiveClass: 'step-plans__swiper__navigation__pagination__bullet--active',
              }}>
              {returnPlansList().map((e, i) => (
                <SwiperSlide key={i} className={className(e, i)} onClick={() => handleCheckPlan(i)}>
                  <div
                    className={`step-plans__swiper__slide__info ${
                      planChecked === i ? 'step-plans__swiper__slide__info--selected' : ''
                    }`}>
                    <SlideInfo
                      element={e}
                      showMonth={showMonth}
                      handleSelectPlan={handleSelectPlan}
                      user={user}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  )
}

export default StepPlans
