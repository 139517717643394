import React, { createRef, useEffect, useState } from 'react'

import './index.scss'

export const CodeInput = ({ codeLength, code, onChange, onKeyUp }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [focus, setFocus] = useState(false)

  let input = createRef()
  let inputWidth = width >= 720 ? 65 : 35
  const selectedIndex = code.length < codeLength.length ? code.length : codeLength.length - 1
  const hideInput = !(code.length < codeLength.length)

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth)
  })

  function handleClick() {
    input.current.focus()
  }

  function updateWindowWidth() {
    setWidth(window.innerWidth)
  }

  function handleFocus() {
    setFocus(true)
  }

  function handleBlur() {
    setFocus(false)
  }

  return (
    <div className='verify-email__code' onClick={handleClick}>
      {codeLength.map((_, index) => {
        const selected = code.length === index
        const filled = code.length === codeLength.length && index === codeLength.length - 1

        return (
          <div
            key={index}
            className={
              (selected || filled) && focus
                ? 'verify-email__code__value verify-email__code__value--selected'
                : 'verify-email__code__value'
            }>
            {code[index]}
          </div>
        )
      })}
      <input
        value=''
        ref={input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        onKeyUp={onKeyUp}
        className='verify-email__code__input'
        style={{
          left: `${selectedIndex * inputWidth + 23 * selectedIndex}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
    </div>
  )
}
