import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Switch from '../../../../../../components/Switch'

import './index.scss'

function PlansToggle() {
  const dispatch = useDispatch()
  const { singlePlan } = useSelector(state => state.subscription)

  const handleChangeType = () =>
    dispatch({ type: 'CHANGE_SUBSCRIPTION_SINGLEPLAN', payload: !singlePlan })

  return (
    <div className='plans-toggle'>
      <Switch
        checked={!singlePlan}
        onCheckedChange={handleChangeType}
        labelLeft='Compra Unitária'
        labelRight='Assinatura Mensal'
      />
    </div>
  )
}

export default PlansToggle
