import axios from 'axios'

export async function getUserPlan() {
  const { data } = await axios.get('/user/my-plan')

  return data
}

export async function cancelSubscription() {
  await axios.delete('/subscription/cancel')
}
