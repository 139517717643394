export const cancellationPolicy = [
  {
    header: '“Campanha #FiqueEmCasa”',
    paragraphs: [
      '<b>SEM PROCESSO INTERMEDIAÇÕES DE ACORDOS S/A</b>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 24.463.567/0001-62, com sede na Cidade e Estado do Rio de Janeiro, na Rua Marquês de São Vicente, nº 225, A 233, Gávea, CEP 22.451-900, neste ato representada na forma de seus atos constitutivos, doravante denominada “<b>SEM PROCESSO</b>".',
      'O presente Regulamento visa definir os termos e condições do funcionamento da Campanha #FiqueEmCasa (“<u>Campanha</u>”).'
    ],
  },
  {
    header: 'I. DA CAMPANHA',
    paragraphs: [
      'I.1. A Campanha possui a finalidade de conceder uma licença de uso da Plataforma Notificca, de forma gratuita, precária e temporária, que permitirá que o Participante envie até 3 (três) notificações extrajudiciais por mês, gratuitamente, através da Plataforma Notificca, objetivando fomentar o distanciamento social em razão do agravamento da pandemia do coronavírus em território brasileiro.',
    ],
    subparagraphs: [
      'I.1.1. A Plataforma Notificca consiste em um software desenvolvido pela SEM PROCESSO na forma de uma plataforma de comunicação que objetiva simplificar e agilizar o envio de notificações extrajudiciais via e-mail, de forma célere e descomplicada.',
    ],
  },
  {
    noHeader: true,
    paragraphs: ['I.2. Poderão participar da Campanha (i) os usuários já assinantes do Plano III da Plataforma Notificca; e (ii) novos usuários que realizarem, durante o Prazo da Campanha, a assinatura deste Regulamento, optando pela assinatura temporária do Plano III (“<u>Participante(s)</u>”).'],
    subparagraphs: [
      'I.2.1. Para os fins deste Regulamento, entende-se por “Plano III” a assinatura da Plataforma Notificca que permite o envio de 3 (três) notificações extrajudiciais por mês.',
      'I.2.2. A participação na Campanha ocorrerá mediante assinatura do presente Regulamento e do aceite dos termos e condições de uso da Plataforma Notificca disponíveis em <a href="/termos-de-uso">"Termos e Condições de Uso"</a>.',
      'I.2.3. Os Termos e Condições de Uso da Plataforma Notificca faz parte integrante do presente Regulamento, declarando desde já o Participante, neste ato, estar de pleno acordo com os Termos e Condições de Uso.',
    ]
  },
  {
    header: 'II. FUNCIONAMENTO DA PLATAFORMA NOTIFICCA',
    paragraphs: [
      'II.1. O funcionamento da Plataforma Notificca dependerá exclusivamente de acesso à internet, não havendo qualquer necessidade de instalação de complementos ou hardware para sua utilização.',
      'II.2. Para acessar a Plataforma Notificca, o Participante deverá acessar o website <a href="https://notificca.com.br/">notificca.com.br</a>.',
      'II.3. Os Participantes, se novos usuários, deverão criar um login e senha de acesso à Plataforma Notificca, sendo estes de caráter pessoal e intransferível, para que passe(m) a ter acesso a área de trabalho da Plataforma. Para os Participantes que já aderiram ao Plano III, estes deverão aderir a Companhia e permanecerão com o seu login e senha já cadastrados, havendo apenas a suspensão da cobrança durante a vigência da Campanha.',
    ]
  },
  {
    noHeader: true,
    subparagraphs: [
      'II.3.1. No ato da realização do cadastro, o Participante deverá registrar-se com um endereço de e-mail válido e que seja acessado regularmente. Todos os cadastros realizados com endereço de e-mail de outras pessoas ou com endereços de e-mail temporários poderão ser bloqueados ou excluídos, sem aviso prévio.',
      'II.3.2. O cadastro do Participante é gratuito, nada sendo cobrado a título de adesão à Campanha.',
      'II.3.3. O Participante é o único responsável por guardar e zelar pelo seu login e senha, não tendo a SEM PROCESSO qualquer responsabilidade sobre estes.',
    ]
  },
  {
    noHeader: true,
    paragraphs: [
      'II.4. A SEM PROCESSO não se responsabiliza por qualquer inaptidão do(s) Participante(s) em se conectar à internet e/ou à Plataforma Notificca, bem como por eventuais softwares e/ou programas que o Participante utilize e que sejam incompatíveis com a Plataforma Notificca.',
      'II.5. A SEM PROCESSO poderá suspender ou encerrar o funcionamento da Plataforma Notificca a qualquer tempo, à seu exclusivo critério e independentemente da anuência prévia dos Participantes, mediante comunicação imediata através do e-mail cadastrado, ou através do próprio site. '
    ]
  },
  {
    header: 'III. DO PRAZO DA CAMPANHA',
    paragraphs: [
      'III.1. A Campanha entrará em vigor a partir do dia 30/03/2021 e permanecerá vigente pelo prazo determinado de 6 (seis) meses, encerrando-se em 30/09/2021 (“<u>Prazo</u>”).',
      'III.2. A SEM PROCESSO poderá, a seu exclusivo critério e por mera liberalidade, encerrar a Campanha antes do Prazo, mediante simples notificação aos Participantes, sem qualquer ônus à SEM PROCESSO. ',
      'III.3. Ao término da Campanha, os Participantes poderão optar por continuar a utilizar a Plataforma Notificca através da efetiva contratação dos Planos disponibilizados pela SEM PROCESSO, conforme condições a serem oportunamente disponibilizadas.',
    ],
    subparagraphs: [
      'III.3.1. Para os usuários já assinantes do Plano III da Plataforma Notificca que aderirem a Campanha, serão automaticamente retornadas as condições da contratação original da Plataforma Notificca, ao término da Campanha.',
    ]
  },
  {
    header: 'IV. OBRIGAÇÕES E RESPONSABILIDADES',
    paragraphs: [
      'IV.1. Sem prejuízo das demais obrigações previstas neste Regulamento, os Participantes deverão:',
    ],
    subparagraphs: [
      '(a) Responsabilizar-se integralmente pelos dados inseridos na Plataforma Notificca, mantendo a SEM PROCESSO indene e a salvo de quaisquer reclamações referentes a veracidade, validade e adequação dos dados;',
      '(b) Utilizar a Plataforma Notificca somente para os fins permitidos e nos limites estabelecidos neste Regulamento, nos Termos e Condições de Uso e na legislação em vigor;',
      '(c) Agir de acordo com a legislação, regulamentos e demais normas aplicáveis à consecução do objeto deste Regulamento;',
      '(d) Fornecer à SEM PROCESSO, sempre que ocorrerem quaisquer problemas ou erros com a Plataforma Notificca, desde que possível, toda a documentação, relatórios e demais informações que individualizem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos de correção e aprimoramento dos mesmos; ',
      '(e) Ressarcir e manter a SEM PROCESSO indene de quaisquer reclamações prestadas ou processos judiciais ajuizados em razão deste Regulamento, sem que haja qualquer ônus para a SEM PROCESSO, sendo certo que esta não possui qualquer obrigação no que diz respeito à relação entre os Participantes da Campanha e demais usuários da Plataforma Notificca; e',
      '(f) Não copiar, ceder, sublicenciar, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir, total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, a Plataforma Notificca.',
      'IV.1.1. Qualquer cópia que não seja a de backup, nos termos do art. 6º, I, da Lei nº 9.609/1998, deverá ser expressamente solicitada à SEM PROCESSO. Caso contrário, a cópia será considerada como não autorizada e sua mera existência será compreendida como violação aos direitos de propriedade intelectual, sujeitando os Participantes às penalidades previstas na legislação em vigor.',
    ]
  },
  {
    noHeader: true,
    paragraphs: [
      'IV.2. Sem prejuízo das demais obrigações previstas neste Regulamento, a SEM PROCESSO deverá:',
    ],
    subparagraphs: [
      '(a) Disponibilizar, para os Participantes que aderirem a Campanha, o uso gratuito do Plano III da Plataforma Notificca nos termos aqui descritos, durante o Prazo da Campanha;',
      '(b) Realizar prontamente a correção de eventuais erros ou falhas na Plataforma Notificca, de modo a assegurar aos Participantes o propósito da presente Campanha; e',
      '(c) Agir de acordo com a legislação, regulamentos e demais normas aplicáveis à consecução da Campanha objeto deste Regulamento.',
    ]
  },
  {
    noHeader: true,
    paragraphs: [
      'IV.3. A SEM PROCESSO não se responsabiliza por qualquer prejuízo direto, indireto, punitivo, acidental ou por qualquer outra espécie de dano oriundo de: (i) incapacidade de uso do website e de seus serviços; (ii) acesso não autorizado ou alterações na transmissão de dados de Participantes da Plataforma Notificca; e (iii) qualquer outro assunto referente à utilização da Plataforma Notificca, incluindo, mas não se limitando a danos oriundos da perda de conexão, receptação de arquivos corrompidos ou quaisquer danos pertinentes ao desempenho do website.',
      'IV.4. Os Participantes declaram estarem cientes de que a Plataforma Notificca, por natureza, não está livre de erros de programação e que, portanto, não pode a SEM PROCESSO garantir que a Plataforma Notificca funcionará de forma ininterrupta.',
    ],
    subparagraphs: [
      'IV.4.1. Na hipótese de eventual falha na disponibilidade da Plataforma Notificca, a SEM PROCESSO deverá regularizar o serviço ou promover sua prestação de forma alternativa em até 48 (quarenta e oito) horas contadas do conhecimento do problema.',
    ]
  },
  {
    noHeader: true,
    paragraphs: [
      'IV.5. Os Participantes estão cientes que a SEM PROCESSO realiza tão somente a ponte para envio das notificações extrajudiciais, não possuindo qualquer ingerência sobre seu conteúdo, não havendo que se falar em qualquer responsabilização por possíveis danos decorrentes de tais notificações, como prejuízos financeiros ou sanções de qualquer natureza gerados pela Campanha.',
    ],
  },
  {
    header: 'V. DISPOSIÇÕES GERAIS',
    paragraphs: [
      'V.1. A presente Campanha não importa na criação de qualquer vínculo trabalhista, societário, de parceria ou associativo entre o Participante e a SEM PROCESSO, sendo excluídas quaisquer presunções de solidariedade entre ambos no cumprimento de suas obrigações.',
      'V.2. O presente Regulamento não constitui qualquer obrigação de exclusividade entre as Partes, para qualquer fim ou por qualquer período.',
      'V.3. O teor das comunicações realizadas pelos Participantes durante o uso da Plataforma Notificca, será de integral e exclusiva responsabilidade do próprio Participante.',
      'V.4. Os Participantes assumem toda e qualquer responsabilidade sobre eventuais danos, morais e/ou materiais, que o seu uso da Plataforma Notificca, durante a Campanha, possa causar a terceiros, incluindo a SEM PROCESSO e/ou afiliadas, isentando-as de toda e qualquer responsabilidade sobre eventuais danos morais ou materiais que possam experimentar em decorrência de sua participação.',
      'V.5. A SEM PROCESSO e/ou terceiros a ela associados na realização da Campanha não serão responsáveis pelos acidentes e/ou danos que porventura venham ocorrer com o Participante por imperícia, imprudência e/ou negligência do mesmo durante a Campanha.',
      'V.6. Os casos omissos não previstos neste Regulamento ou qualquer dúvida que possa surgir do mesmo serão analisados e decididos pela SEM PROCESSO e/ou terceiros a ela associados na realização da Campanha, cujas decisões são soberanas e irrecorríveis.',
      'V.7. A SEM PROCESSO se reserva o direito de suspender a Campanha por razões judiciais, técnicas, operacionais, comerciais, caso fortuito ou força maior, inclusive crises sanitárias, ou alterar as condições do presente Regulamento no que for necessário, sem que qualquer indenização seja devida aos Participantes.',
      'V.8. Na hipótese de qualquer cláusula, termo ou disposição deste Regulamento ser declarada nula ou inexequível, tal nulidade não afetará quaisquer outras cláusulas, termos ou condições contidas no presente documento, os quais permanecerão em pleno vigor e efeito.',
      'V.9. Toda e qualquer comunicação escrita enviada pelos Participantes à SEM PROCESSO deverá ser encaminhada para o endereço de sua sede social ou para o endereço de e-mail <a href="mailto:contato@notificca.com.br">contato@notificca.com.br</a>. A SEM PROCESSO poderá enviar avisos, comunicações e notificações ao endereço físico e/ou eletrônico do Participante indicado no seu cadastro na Plataforma Notificca.',
      'V.10. A presente Campanha tem abrangência em todo território nacional.',
      'V.11. Todos os itens deste Regulamento são regidos pelas leis vigentes na República Federativa do Brasil.',
      'V.12. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a este Regulamento, as partes concordam em se submeter ao Foro Central da Comarca da Capital do Estado do Rio de Janeiro.',
      'V.13. O presente documento se encontra publicado, à disposição para consulta de todos os interessados, no website <a href="/regulamento-campanha">notificca.com.br/regulamento-campanha</a>.',
    ],
  },
]