import React,{ useState, useEffect } from 'react'
import './index.scss'
import {useDispatch,useSelector} from 'react-redux'
import {X} from 'lucide-react'

function MiniBanner({children}){
    const dispatch = useDispatch()
    const {miniBanner} = useSelector(state=>state.app)
    const {user} = useSelector(state=>state.login)

    const [hasToken, setHasToken] = useState(false)

    const handleCloseMiniBanner = () => dispatch({ type: 'CHANGE_APP_MINIBANNER', payload: false})

    useEffect(()=>{
        if(user.token) setHasToken(true)
    },[user])

    return(
        <div className={`mini-banner ${hasToken && miniBanner?"mini-banner--visible":""}`}>
            {children}
            <X onClick={handleCloseMiniBanner}/>
        </div>
    )
}

export default MiniBanner
