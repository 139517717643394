const INICIAL_STATE = {
    step: 0,
    showModalSelectColumns: false,
    showModalConfirmation: false,

    fileRecipient: null,
    jsonRecipient: null,

    recipientList: [],

    recipientRefused: [{
        name: "",
        email: "",
        documentNumber: ""
    }],
    recipientAccepted: [{
        name: "",
        email: "",
        documentNumber: ""
    }],

    fileDocument: '',

    pdfPreview: null,
    notificationsId: [],
    sendNotifications: false
}

export default function (state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'RESET_IMPORTER':
            return { ...INICIAL_STATE }
            
        case 'CHANGE_IMPORTER_STEP':
            return { ...state, step: action.payload }
        case 'CHANGE_IMPORTER_MODALSELECTCOLUMNS':
            return { ...state, showModalSelectColumns: action.payload }
        case 'CHANGE_IMPORTER_FILE_RECIPIENT':
            return { ...state, fileRecipient: action.payload }
        case 'CHANGE_IMPORTER_JSON_RECIPIENT':
            return { ...state, jsonRecipient: action.payload }

        case 'CHANGE_IMPORTER_RECIPIENT_LIST':
            return { ...state, recipientList: action.payload }


        case 'CHANGE_IMPORTER_FILE_DOCUMENT':
            return { ...state, fileDocument: action.payload }
        case 'CHANGE_IMPORTER_MODALCONFIRMATION':
            return { ...state, showModalConfirmation: action.payload }


        case 'CHANGE_IMPORTER_RECIPIENT_REFUSED':
            return { ...state, recipientRefused: action.payload }
        case 'CHANGE_IMPORTER_RECIPIENT_ACCEPTED':
            return { ...state, recipientAccepted: action.payload }


        case 'CHANGE_IMPORTER_PDFPREVIEW':
            return { ...state, pdfPreview: action.payload }
        case 'CHANGE_IMPORTER_NOTIFICAIONSID':
            return { ...state, notificationsId: action.payload }
        case 'CHANGE_IMPORTER_SENDNOTIFICATIONS':
            return { ...state, sendNotifications: action.payload }


        default:
            return state
    }
}
