const INICIAL_STATE = {
  email: '',
  password: '',
  visible: false,
  user: {
    subscription: {}
  },
  loading: false,
  error: '',
  quota: { 
  }
}

export default function (state = INICIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_LOGIN_EMAIL':
      return { ...state, email: action.payload }
    case 'CHANGE_LOGIN_PASSWORD':
      return { ...state, password: action.payload }
    case 'CHANGE_LOGIN_VISIBLE':
      return { ...state, visible: action.payload }
    case 'CHANGE_LOGIN_USER':
      return { ...state, user: action.payload }
    case 'CHANGE_LOGIN_LOADING':
      return { ...state, loading: action.payload }
    case 'CHANGE_LOGIN_ERROR':
      return { ...state, error: action.payload }
    case 'CHANGE_LOGIN_SUBSCRIPTION':
      return { ...state, user: { ...state.user, subscription: { planId: null, id: null, status: 'canceled', plan: null, maxQuota: null } } }
    case 'RESET_LOGIN':
      return { ...INICIAL_STATE }
    case 'CHANGE_LOGIN_QUOTA':
      return { ...state, quota: { ...action.payload } }
    case 'CHANGE_LOGIN_GPT_QUOTA':
      return { ...state, user: { ...state.user, gptQuota: action.payload } }
    default:
      return state
  }
}
