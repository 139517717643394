import React from "react";
import './index.scss'
import CancelIcon from '../../../../assets/cancel.svg'
import Button from '../../../../components/Button'


const CancelPlan = ({ resetSubscription }) => {
  return (
    <div className="cancel-plan">
      <div className='cancel-plan__icon'>
        <img src={CancelIcon} alt="cancel" />
      </div>
      <h1>Seu plano foi cancelado.</h1>
      <h2>Obrigado por utilizar o Notificca! Caso deseje assinar um plano diferente,&nbsp;<a href='https://www.notificca.com.br/planos' target='_blank' rel="noopener noreferrer">acesse nossa página de Planos</a></h2>
      <Button height='50px' type={1}>Voltar à Home</Button>
    </div>
  )
}

export default CancelPlan