import React, { useEffect,useState } from "react"
import cep from 'cep-promise'
import { Search } from 'lucide-react'
import _ from 'lodash'
import './index.scss'
import { formatDocument, phone, cepMask, onlyNumber } from '../../../../constants/validate/index'

import Form from '../../../../components/Form'
// import Input from '../../../../components/Input'

const Informations = ({ customer, changeCustomerField, address, changeAddressField, user }) => {
  const [cepString,setCepString] = useState('')
  const { name, email, document, telephone, password, confirmPassword } = customer
  // const { zipcode, city, neighborhood, street, streetNumber, complement } = address

  const [disableCity,setDisableCity] = useState(true)
  const [disableStreet,setDisableStreet] = useState(true)
  const [disableNeighborhood,setDisableNeighborhood] = useState(true)

  useEffect(() => {
    if (user.name) {
      changeCustomerField(user.name, 'name')
      changeCustomerField(user.email, 'email')
      changeCustomerField(user.document, 'document')
    }
    return () => {
    }
  }, [])

  let firstLine = [{ value: name, title: 'Nome Completo', onChange: changeCustomerField, field: 'name', disabled: user.name ? true : false }, { value: email, title: 'E-mail', onChange: changeCustomerField, field: 'email', disabled: user.email ? true : false }]
  let secondLine = [{ value: document, title: 'CPF/CNPJ', onChange: changeCustomerField, field: 'document', number: onlyNumber, format: formatDocument, disabled: user.document ? true : false }, { value: password, title: 'Senha', password: true, showPassword:true, onChange: changeCustomerField, field: 'password' }]
  // let secondLine = [{ value: document, title: 'CPF/CNPJ', onChange: changeCustomerField, field: 'document', number: onlyNumber, format: formatDocument, disabled: user.document ? true : false }, { value: telephone, title: 'Telefone', onChange: changeCustomerField, field: 'telephone', number: onlyNumber, format: phone }]
  // let thirdLine = [{ value: password, title: 'Senha', password: true, onChange: changeCustomerField, field: 'password' }, { value: confirmPassword, title: 'Confirmar Senha', password: true, onChange: changeCustomerField, field: 'confirmPassword' }]
  // let fourthLine = [{ value: city, title: 'Cidade', onChange: changeAddressField, field: 'city', disabled: disableCity }, { value: neighborhood, title: 'Bairro', onChange: changeAddressField, field: 'neighborhood', disabled: disableNeighborhood }]
  // let fifthLine = [{ value: street, title: 'Endereço', onChange: changeAddressField, field: 'street', disabled: disableStreet }, { value: zipcode, title: 'CEP', onChange: changeAddressField, field: 'zipcode', number: onlyNumber, format: cepMask, disabled: true }]
  // let sixthLine = [{ value: streetNumber, title: 'Número', onChange: changeAddressField, field: 'streetNumber', number: onlyNumber }, { value: complement, title: 'Complemento', onChange: changeAddressField, field: 'complement' }]

  function handleCepSearch(value) {
    if(value.length<=8){
      setCepString(value.replace(/\D/g,""))
      setDisableCity(true)
      setDisableStreet(true)
      setDisableNeighborhood(true)
    }

    if(value.length===8){
      cep(value)
      .then(response => {
        if(response.city==='')setDisableCity(false)
        if(response.street==='')setDisableStreet(false)
        if(response.neighborhood==='')setDisableNeighborhood(false)
        changeAddressField(cepMask(response.cep), 'zipcode')
        changeAddressField(response.city, 'city')
        changeAddressField(response.state, 'state')
        changeAddressField(response.street, 'street')
        changeAddressField(response.neighborhood, 'neighborhood')
      })
      .catch(console.log)
    }
  }

  return (
    <div className="informations">
      <div className="informations__form">
        <Form formsArray={firstLine} twoForms={true} column={true} />
        <Form formsArray={secondLine} twoForms={true} column={true} />
        {/* {_.isEmpty(user.email) && <Form formsArray={thirdLine} twoForms={true} column={true} />} */}
        {/* <div className='informations__form__input'>
          <Input type='2' placeholder='Busque seu CEP aqui' onChange={handleCepSearch} value={cepString}/>
          <Search size={14} />
        </div>
        <Form formsArray={fourthLine} twoForms={true} column={true} />
        <Form formsArray={fifthLine} twoForms={true} column={true} />
        <Form formsArray={sixthLine} twoForms={true} column={true} /> */}
      </div>
    </div>
  )
}

export default Informations
