import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'
import './styles/index.scss'

import CampaignPolicy from './containers/CampaignPolicy'
import CancellationPolicy from './containers/CancellationPolicy'
import LegalOpinion from './containers/LegalOpinion'
import Confirmation from './containers/Confirmation'
import ContactUs from './components/ContactUs'
import Header from './components/Header'
// import Home from './containers/Home'
import Login from './containers/Login'
// import Importer from './containers/Importer'
import Menu from './components/Menu'
import Notifications from './containers/Notifications'
import Notify from './containers/Notify'
import MyPlan from './containers/MyPlan'
import Subscription from './containers/Subscription'
import SubscripitonCancelPlan from './containers/Subscription/components/CancelPlan'
import SubscripitonConfirm from './containers/Subscription/components/SubscripitonConfirm'
import Terms from './containers/Terms'
import Validate from './containers/Validate'
import NewPassword from './containers/NewPassword'
import NewNotify from './containers/NewNotify'
import Profile from './containers/Profile'
import PrivacyPolicy from './containers/PrivacyPolicy'

export default function App() {
  const dispatch = useDispatch()
  const { user: loggedUser } = useSelector(state => state.login)
  const { token } = loggedUser
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    setLogged()
  }, [token])

  useEffect(() => {
    let user = localStorage.getItem('user')

    if (typeof user === 'string') {
      user = JSON.parse(user)
      axios.defaults.headers.common['authentication'] = user.token
      dispatch({ type: 'CHANGE_LOGIN_USER', payload: user })

      if (user.token) {
        axios
          .get('user/fetch-data')
          .then(response => {
            dispatch({ type: 'CHANGE_LOGIN_USER', payload: response.data })
            localStorage.setItem('user', JSON.stringify(response.data))
          })
          .catch(e => {
            console.log(e)
          })
      }
    }

    window.Intercom('boot', {
      app_id: 'l2dabnfe',
    })
  }, [])

  function setLogged() {
    if (token) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }

  return (
    <Router>
      <div className='row'>
        <div className='column'>
          <ContactUs />
          {loggedIn && <Header />}
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login' />
            </Route>
            <Route exact path='/login'>
              {!_.isEmpty(localStorage.getItem('user')) ? (
                <Redirect to='/notificacoes' />
              ) : (
                <Login goto='/notificacoes' />
              )}
            </Route>

            <Route path='/validar'>
              <Validate />
            </Route>
            <Route path='/notificar'>
              <Notify />
            </Route>
            <Route path='/termos-de-uso'>
              <Terms />
            </Route>
            <Route path='/politica-de-cancelamento'>
              <CancellationPolicy />
            </Route>
            <Route path='/aviso-de-privacidade'>
              <PrivacyPolicy />
            </Route>
            <Route path='/legal-opinion'>
              <LegalOpinion />
            </Route>
            <Route path='/regulamento-campanha'>
              <CampaignPolicy />
            </Route>
            <Route path='/perfil'>
              <Profile />
            </Route>
            <Route path='/meu-plano'>
              <MyPlan />
            </Route>
            <Route exact path={['/planos', '/planos?cupom=:couponName']}>
              {/* <Route exact path={["/planos"]}> */}
              <Subscription />
            </Route>
            <Route exact path='/planos/confirmado'>
              <SubscripitonConfirm />
            </Route>
            <Route exact path='/planos/cancelado'>
              <SubscripitonCancelPlan />
            </Route>
            <Route path='/confirmar/:id/:hash'>
              <Confirmation />
            </Route>
            <Route path='/notificacoes'>
              <Notifications />
            </Route>
            {/* <Route path='/notificar-gpt'>
              <NewNotify />
            </Route> */}
            {/* <Route path="/importador">
              <Importer />
            </Route> */}
            <Route exact path={['/nova-senha', '/nova-senha/:email/:password']}>
              <NewPassword />
            </Route>
          </Switch>
        </div>
        <Menu />
      </div>
    </Router>
  )
}
