import React, { useState, useEffect } from 'react'
import './index.scss'

function Checkbox({ isChecked=false, onChange = false, preventIsCheckedClick=false }) {
    const [_isChecked, set_IsChecked] = useState(isChecked)

    useEffect(()=>{
        set_IsChecked(isChecked)
    },[isChecked])

    const handleCheck = () => {
        if(preventIsCheckedClick){
            if(!_isChecked){
                onChange(!_isChecked)
                set_IsChecked(!_isChecked)
            }
        } else {
            onChange(!_isChecked)
            set_IsChecked(!_isChecked)
        }        
    }

    return (
        <div className="checkbox__container" onClick={handleCheck}>
            <div className={_isChecked ? "checkbox__input__checkmark--checked" : "checkbox__input__checkmark"}></div>
        </div>
    )
}

export default Checkbox
