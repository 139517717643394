import axios from 'axios'
import _ from 'lodash'
import React, { useState } from 'react'
import { Check, X } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'

import { email as emailFormat, phone as phoneFormat } from '../../constants/validate'

import Button from '../Button'

import './index.scss'

const ContactUs = () => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [company, setCompany] = useState('')
  const [companyError, setCompanyError] = useState(false)
  const [doubt, setDoubt] = useState('')
  const [doubtError, setDoubtError] = useState(false)
  const [modal, setModal] = useState('contactUs')

  const app = useSelector(state => state.app)
  const dispatch = useDispatch()
  const { contactUsModal, contactUsModalGPT } = app

  function closeModal() {
    dispatch({ type: 'CHANGE_APP_CONTACT_US_MODAL', payload: false })
    setTimeout(() => dispatch({ type: 'CHANGE_APP_CONTACT_US_MODAL_GPT', payload: false }), 700)
  }

  function handleEmail() {
    if (emailFormat(email) === 'Email Inválido' && email !== '') {
      setEmailError(true)
      setEmail('')
    } else {
      setEmailError(false)
    }
  }

  function handlePhone() {
    if (phone.length < 13 && phone.length > 0) {
      setPhoneError(true)
      setPhone('')
    } else {
      setPhoneError(false)
    }
  }

  function handleName(value) {
    setNameError(false)
    setName(value)
  }

  function handleCompany(value) {
    setCompanyError(false)
    setCompany(value)
  }

  function handleDoubt(value) {
    setDoubtError(false)
    setDoubt(value)
  }

  function resetModal() {
    closeModal()
    setName('')
    setPhone('')
    setEmail('')
    setCompany('')
    setDoubt('')
    setTimeout(() => {
      setModal('contactUs')
    }, 1000)
  }

  function sendForm() {
    let html = ''
    let error = false

    if (!_.isEmpty(name)) {
      setNameError(false)
      html = html + `<br>Nome: ${name}</br>`
    } else {
      setNameError(true)
      error = true
    }

    if (!_.isEmpty(email)) {
      setEmailError(false)
      html = html + `<br>Email: ${email}</br>`
    } else {
      setEmailError(true)
      error = true
    }

    if (!_.isEmpty(phone)) {
      setPhoneError(false)
      html = html + `<br>Telefone: ${phone}</br>`
    } else {
      setPhoneError(true)
      error = true
    }

    if (!_.isEmpty(company)) {
      setCompanyError(false)
      html = html + `<br>Empresa: ${company}</br>`
    } else {
      setCompanyError(true)
      error = true
    }

    html = html + `<br>Observação: ${doubt}</br>`

    const url = contactUsModalGPT ? '/leads/gpt' : '/leads/doubt'
    if (!error) {
      axios
        .post(url, { html })
        .then(response => {
          setModal('confirm')
          setTimeout(() => {
            resetModal()
          }, 5000)
        })
        .catch(e => {
          setModal('error')
        })
    }
  }

  const contactUs = (
    <div className='contact-us__modal'>
      <X
        className='contact-us__close'
        size={30}
        onClick={() => {
          closeModal()
        }}
      />
      {!contactUsModalGPT && <h1>Fale conosco!</h1>}
      {contactUsModalGPT && (
        <h1>
          Quero adicionar o <br />
          ChatGPT
        </h1>
      )}
      <h3>Preencha todos os dados abaixo e nossa equipe entrará em contato com você.</h3>
      <input
        className={nameError ? 'contact-us__input contact-us__input--error' : 'contact-us__input'}
        placeholder='Nome Completo'
        onChange={e => handleName(e.target.value)}
        value={name}
      />
      <input
        className={emailError ? 'contact-us__input contact-us__input--error' : 'contact-us__input'}
        placeholder={emailError ? 'E-mail inválido' : 'E-mail'}
        onChange={e => setEmail(e.target.value)}
        value={email}
        onBlur={() => handleEmail()}
      />
      <input
        className={phoneError ? 'contact-us__input contact-us__input--error' : 'contact-us__input'}
        placeholder={phoneError ? 'Telefone inválido' : 'Telefone'}
        onChange={e => setPhone(phoneFormat(e.target.value))}
        value={phone}
        onBlur={() => handlePhone()}
      />
      <input
        className={
          companyError ? 'contact-us__input contact-us__input--error' : 'contact-us__input'
        }
        placeholder='Empresa'
        onChange={e => handleCompany(e.target.value)}
        value={company}
      />
      {!contactUsModalGPT && (
        <textarea
          className={
            doubtError
              ? 'contact-us__text-area contact-us__text-area--error'
              : 'contact-us__text-area'
          }
          placeholder='Observações'
          onChange={e => handleDoubt(e.target.value)}
          value={doubt}
        />
      )}
      <Button
        action={true}
        type='1'
        width='100%'
        chevronRight={true}
        height='50px'
        onClick={() => sendForm()}>
        Enviar e-mail
      </Button>
    </div>
  )

  const confirm = (
    <div className='confirmation__modal'>
      <h1>Enviado!</h1>
      <h3>
        Sua dúvida foi recebida. Em breve nossa equipe entrará em contato via e-mail ou telefone.
        Até logo!
      </h3>
      <Button action={true} type='9' width='100%' height='50px' onClick={() => resetModal()}>
        <Check />
      </Button>
    </div>
  )

  const error = (
    <div className='error__modal'>
      <h1>Ops...</h1>
      <h3>
        Sua dúvida não pôde ser enviado devido à uma falha em nossos servidores. Por favor, volte e
        tente enviar novamente.
      </h3>
      <Button
        action={true}
        type='1'
        chevronLeft={true}
        width='100%'
        height='50px'
        onClick={() => setModal('contactUs')}>
        Tentar novamente
      </Button>
      <X className='error__close' size={30} onClick={() => closeModal()} />
    </div>
  )

  return (
    <div className={contactUsModal ? 'contact-us contact-us--visible' : 'contact-us'}>
      {modal === 'contactUs' ? contactUs : modal === 'confirm' ? confirm : error}
    </div>
  )
}

export default ContactUs
