import React from 'react'
import {
  ArrowDown,
  ArrowRight,
  DownloadCloud,
  UploadCloud,
  ChevronDown,
  ChevronRight,
  Plus,
} from 'react-feather'
import { Link } from 'react-router-dom'
import { Link as ReactScrollLink } from 'react-scroll'

import './index.scss'
import Loading from '../Loading'

/**
 * 
 * @param {*} type 
 * 
 * ```
    -0 transparent
    -1 green
    -2 green
    -3 green
    -4 silver
    -5 green
    -6 green
    -7 red
    -8 yellow
    -9 green
    -10 green
    ````
 */
const Button = ({
  scrollLink = false,
  to = '',
  type = 1,
  width = '100%',
  height = '60px',
  maxWidth = 'auto',
  fontSize = '14px',
  // lineHeight = "16px",
  arrowDown = false,
  arrowRight = false,
  downloadCloud = false,
  uploadCloud = false,
  chevronDown = false,
  chevronRight = false,
  plus = false,
  children,
  onClick,
  loading,
  submit = false,
  green = false,
  action = false,
  disabled = false,
  login = false,
  ...props
}) => {
  let style = {
    width,
    height,
    minHeight: height,
    maxWidth,
    fontSize,
  }

  if (login) {
    return (
      <button
        type='submit'
        style={style}
        className={disabled ? `button button--disabled` : `button button--type-${type}`}
        onClick={onClick}
        {...props}>
        {loading ? (
          <Loading color={green && 'green'} />
        ) : (
          <span>
            {children}
            {arrowDown && <ArrowDown size={30} />}
            {arrowRight && <ArrowRight size={30} />}
            {downloadCloud && <DownloadCloud size={20} />}
            {uploadCloud && <UploadCloud size={20} />}
            {chevronDown && <ChevronDown size={20} />}
            {chevronRight && <ChevronRight size={20} />}
            {plus && <Plus size={20} />}
          </span>
        )}
      </button>
    )
  }

  if (action) {
    return (
      <div
        style={style}
        className={disabled ? `button button--disabled` : `button button--type-${type}`}
        onClick={onClick}
        {...props}>
        {loading ? (
          <Loading color={green && 'green'} />
        ) : (
          <span>
            {children}
            {arrowDown && <ArrowDown size={30} />}
            {arrowRight && <ArrowRight size={30} />}
            {downloadCloud && <DownloadCloud size={20} />}
            {uploadCloud && <UploadCloud size={20} />}
            {chevronDown && <ChevronDown size={20} />}
            {chevronRight && <ChevronRight size={20} />}
            {plus && <Plus size={20} />}
          </span>
        )}
        {submit && <button type='submit'></button>}
      </div>
    )
  }

  if (scrollLink) {
    return (
      <ReactScrollLink
        to={to}
        spy={true}
        smooth={true}
        duration={500}
        style={style}
        className={`button button--type-${type}`}
        onClick={onClick}
        {...props}>
        {loading ? (
          <Loading color={green && 'green'} />
        ) : (
          <span>
            {children}
            {arrowDown && <ArrowDown size={30} />}
            {arrowRight && <ArrowRight size={30} />}
            {downloadCloud && <DownloadCloud size={20} />}
            {uploadCloud && <UploadCloud size={20} />}
            {chevronDown && <ChevronDown size={20} />}
            {chevronRight && <ChevronRight size={20} />}
            {plus && <Plus size={20} />}
          </span>
        )}
        {submit && <button type='submit'></button>}
      </ReactScrollLink>
    )
  }
  return (
    <Link
      to={to}
      style={style}
      className={`button button--type-${type}`}
      onClick={onClick}
      {...props}>
      {loading ? (
        <Loading color={green && 'green'} />
      ) : (
        <span>
          {children}
          {arrowDown && <ArrowDown size={30} />}
          {arrowRight && <ArrowRight size={30} />}
          {downloadCloud && <DownloadCloud size={20} />}
          {uploadCloud && <UploadCloud size={20} />}
          {chevronDown && <ChevronDown size={20} />}
          {chevronRight && <ChevronRight size={20} />}
          {plus && <Plus size={20} />}
        </span>
      )}
      {submit && <button type='submit'></button>}
    </Link>
  )
}

export default Button
