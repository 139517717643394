import axios from 'axios'

export async function updateUserInfo(name, documentNumber, password) {
  const { data } = await axios.put('user/change-user-info', { name, documentNumber, password })

  return data
}

export async function updatePassword(password, newPassword) {
  const { data } = await axios.put('user/update-password', { password, newPassword })

  return data
}

export async function sendCodeEmail(newEmail) {
  const { data } = await axios.post('user/send-code-email', { newEmail })

  return data
}

export async function resendCodeEmail(newEmail) {
  const { data } = await axios.post('user/resend-code-email', { newEmail })

  return data
}

export async function verifyCodeEmail(newEmail, code) {
  const { data } = await axios.post('user/verify-code-email', { newEmail, code })

  return data
}
