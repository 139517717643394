import { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

const useRecaptcha = () => {
  const [capchaToken, setCapchaToken] = useState('')
  const recaptchaRef = useRef()
  const dispatch = useDispatch()

  const handleRecaptcha = useCallback(token => {
    dispatch({
      type: 'CHANGE_SUBSCRIPTION_RECAPTCHA',
      payload: token,
    })
  }, [])

  useEffect(() => {
    const refreshCaptcha = () => {
      if (recaptchaRef.current && capchaToken) {
        recaptchaRef.current.reset()
        setCapchaToken('')
        dispatch({
          type: 'CHANGE_SUBSCRIPTION_RECAPTCHA',
          payload: '',
        })
      }
    }

    let tokenRefreshTimeout

    if (capchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 300000) // 300 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout)
      }
    }
  }, [capchaToken])

  return { capchaToken, setCapchaToken, recaptchaRef, handleRecaptcha }
}

export default useRecaptcha
