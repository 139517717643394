import { combineReducers } from 'redux'

import notifyReducer from './notify'
import appReducer from './app'
import loginReducer from './login'
import notificationReducer from './notifications'
import subscriptionReducer from './subscription'
import importerReducer from './importer'

const reducers = combineReducers({
  app: appReducer,
  notify: notifyReducer,
  login: loginReducer,
  notification: notificationReducer,
  subscription: subscriptionReducer,
  importer: importerReducer
})

export default reducers
