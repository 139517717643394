import React from 'react'

import { ChevronLeft, ChevronRight } from 'lucide-react'
import ReactPaginate from 'react-paginate'

import './index.scss'
// Custom hooks
import { useMedia } from '../../hooks/useMedia'

function Pagination({ currentPage, totalPages, onClickAction }) {
  const isMobile = useMedia('(max-width: 960px)')

  return (
    <ReactPaginate
      forcePage={currentPage - 1}
      previousLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      breakLabel={'...'}
      breakClassName={'pagination__break-me'}
      pageCount={totalPages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={isMobile ? 1 : 5}
      onPageChange={({ selected }) => onClickAction(selected + 1)}
      containerClassName={'pagination'}
      activeClassName={'pagination__active'}
      initialPage={currentPage - 1}
      disableInitialCallback
    />
  )
}

export default Pagination
