const INICIAL_STATE = {
  notifications: [],
  fetch: true,
  index: 1,
  totalNotifications: 0,
  search: '',
  modalVisible: false,
  modalInfos: {},
  filter: {
    sentAt: false,
    receivedAt: false,
    openedAt: false,
    bouncedAt: false,
    confirmedAt: false,
  },
}

export default function (state = INICIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_NOTIFICATIONS_NOTIFICATIONS':
      return { ...state, notifications: action.payload }
    case 'CHANGE_NOTIFICATIONS_FETCH':
      return { ...state, fetch: action.payload }
    case 'CHANGE_NOTIFICATIONS_INDEX':
      return { ...state, index: action.payload }
    case 'CHANGE_NOTIFICATIONS_TOTAL_NOTIFICATIONS':
      return { ...state, totalNotifications: action.payload }
    case 'CHANGE_NOTIFICATIONS_SEARCH':
      return { ...state, search: action.payload }
    case 'CHANGE_NOTIFICATIONS_MODAL':
      return { ...state, modalVisible: action.payload }
    case 'CHANGE_NOTIFICATIONS_INFO':
      return { ...state, modalInfos: action.payload }
    case 'CHANGE_NOTIFICATIONS_FILTER':
      return {
        ...state,
        filter: {
          confirmedAt: action.payload.field === 'confirmedAt' ? action.payload.bool : false,
          sentAt: action.payload.field === 'sentAt' ? action.payload.bool : false,
          receivedAt: action.payload.field === 'receivedAt' ? action.payload.bool : false,
          openedAt: action.payload.field === 'openedAt' ? action.payload.bool : false,
          bouncedAt: action.payload.field === 'bouncedAt' ? action.payload.bool : false,
        },
      }
    case 'RESET_LOGIN':
      return { ...INICIAL_STATE }
    default:
      return state
  }
}
