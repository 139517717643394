import React, { useState, useEffect } from 'react'
import download from 'downloadjs'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import Avatar from '../../../../components/Avatar'
import Spinner from '../../../../components/Spinner'
import { RefreshCcw, Download, MailCheck, MailOpen, MailWarning } from 'lucide-react'
import { formatDate, base64 } from '../../../../constants/validate'
import './index.scss'
import NotifyStatus from './NotifyStatus'

const NotificationsCard = ({ notification, showError }) => {
  const [loadingIcon, setLoadingIcon] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960)
  const { status, sender, recipient, id } = notification
  const dispatch = useDispatch()

  const fullDate = new Date(notification.sentAt)
  const day = fullDate.getDate()
  const month = fullDate.getMonth() + 1
  const year = fullDate.getFullYear()
  const hours = fullDate.getHours()
  const minutes = fullDate.getMinutes()

  function setShowModal(bool) {
    dispatch({ type: 'CHANGE_NOTIFICATIONS_MODAL', payload: bool })
    dispatch({ type: 'CHANGE_NOTIFICATIONS_INFO', payload: notification })
  }

  function downloadCertificate(notificationId) {
    setLoadingIcon('certificate')
    axios
      .post('/notifications/download-last-document', { notificationId })
      .then(response => {
        download(base64(response.data), notificationId, 'application/pdf')
        setLoadingIcon('')
      })
      .catch(e => {
        showError('Algo deu errado, entre em contato com nossos operadores')
        console.log(e)
        setLoadingIcon('')
      })
  }

  function returnStatus() {
    switch (status) {
      case 'Sent':
        return 'Enviado'
      case 'Received':
        return 'Recebido'
      case 'Opened':
        return 'Aberto'
      case 'Bounced':
        return 'Não recebido'
      case 'Confirmed':
        return 'Confirmado'
      default:
        break
    }
  }

  function settingStatusOnTitle() {
    switch (returnStatus()) {
      case 'Confirmado':
        return (
          <span>
            <div
              style={{
                backgroundColor: '#56ca00',
              }}></div>
            Confirmado
          </span>
        )
      case 'Aberto':
        return (
          <span>
            <div
              style={{
                backgroundColor: '#FFB400',
              }}></div>
            Aberto
          </span>
        )
      case 'Recebido':
        return (
          <span>
            <div
              style={{
                backgroundColor: '#B5B5B5',
              }}></div>
            Recebido
          </span>
        )
      case 'Enviado':
        return (
          <span>
            <div
              style={{
                backgroundColor: '#26a69a',
              }}></div>
            Enviado
          </span>
        )
      case 'Não recebido':
        return (
          <span>
            <div
              style={{
                backgroundColor: '#E73E39',
              }}></div>
            Não Recebido
          </span>
        )
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  function checkDate(value) {
    if (value < 10) {
      return `0${value}`
    }
    return value
  }

  return (
    <div className='notifications-card'>
      <div className='notifications-card__details'>
        <div className='notifications-card__details__info'>
          <h3>Notificante</h3>
          <hr />
          <h4>{sender.name}</h4>
          <p>{sender.email}</p>
          <p>{sender.documentNumber}</p>
        </div>
        <div className='notifications-card__details__status'>
          <span></span>
          <NotifyStatus status={returnStatus()} />
          <span></span>
        </div>
        <div
          className='notifications-card__details__info'
          style={{ backgroundColor: '#F0FFFE', border: '2px dotted #00695c' }}>
          <h3>
            Notificado
            {settingStatusOnTitle()}
          </h3>
          <hr />
          <h4>{recipient.name}</h4>
          <p>{recipient.email}</p>
          <p>{recipient.documentNumber}</p>
        </div>
      </div>
      <div className='notifications-card__info-actions'>
        <div className='notifications-card__info-actions__info' style={{ marginRight: 'auto' }}>
          {/* {!isMobile && <Avatar name='Lídia Sousa' />} */}
          <article>
            {/* <p>
              Criado por <b>Lídia Sousa</b>
            </p> */}
            <p>Data de criação</p>
            <span /* style={{ fontSize: '12px' }} */>
              {checkDate(day)}/{checkDate(month)}/{year} {checkDate(hours)}:{checkDate(minutes)}
            </span>
          </article>
        </div>
        <div className='notifications-card__info-actions__actions'>
          {returnStatus() === 'Não recebido' && (
            <button onClick={() => setShowModal(true)}>
              <RefreshCcw size='16' color='#009688' />
              {!isMobile && 'Reenviar'}
            </button>
          )}
          {loadingIcon === 'certificate' ? (
            <button>
              <Spinner type='green' />
              {!isMobile && 'Baixar certificado'}
            </button>
          ) : (
            <button onClick={() => downloadCertificate(id)}>
              <Download size='16' color='#009688' />
              {!isMobile && 'Baixar certificado'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationsCard
