import React from 'react'

import Button from '../../../../components/Button'
import PlanBenefits from '../../../../components/PlanBenefits'
import useWindowWidth from '../../../../hooks/useWindowWidth'

import './index.scss'

const PlanCard = ({ plan, onChangePlan, onCancelPlan }) => {
  const width = useWindowWidth()

  const formatPrice = (price = 0) => {
    if (parseFloat(price) % 1) {
      return String(price).replace('.', ',')
    } else {
      return price
    }
  }

  return (
    <article className='plan-card'>
      <header className='plan-card__header'>
        <div className='plan-card__header__title'>
          <h3>{plan.header}</h3>
          <span>Pacote {plan.numberOfNotifications}</span>
        </div>
        <div className='plan-card__header__action'>
          <Button width={127} height={36} type={3} action={true} onClick={onChangePlan}>
            Trocar plano
          </Button>
        </div>
      </header>
      <main className='plan-card__content'>
        <div className='plan-card__content__value'>
          <small>R$</small>
          {formatPrice(plan.value)}
          <small>/mês</small>
        </div>

        <div>
          <PlanBenefits element={plan} isHorizontal={width >= 720} />
        </div>
      </main>
      <footer className='plan-card__footer'>
        <p>Você pode cancelar sua assinatura quando quiser.</p>
        <Button type={0} action={true} width={127} height={26} onClick={onCancelPlan}>
          Cancelar
        </Button>
      </footer>
    </article>
  )
}

export default PlanCard
