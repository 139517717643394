import React from 'react'
import './index.scss'

import { Check } from 'lucide-react'
import Plane from '../../assets/plane.svg'

function Stepper({ steppersList = [], currentStep = 0, paperplane = false }) {

    const isLastElement = (element) => (steppersList.length - 1 === element) ? true : false

    return (
        <div className="stepper__container">
            {steppersList.map((el, i) => (
                <div key={i} className={`stepper__container__bar ${isLastElement(i) ? "stepper__container__bar--last" : ""}`}>
                    <div className={`stepper__container__step ${currentStep === i ? "stepper__container__step--current" : ""}`}>
                        <div className="stepper__container__step__marker"></div>
                        <p className="stepper__container__step__name">{el}</p>
                    </div>
                    {!isLastElement(i) && <div className="stepper__container__step__dash-line"></div>}
                </div>
            ))}
            {paperplane &&
                <div className="stepper__container__bar">
                    <div className="stepper__container__step__dash-line"></div>
                    <div className="stepper__container__step stepper__container__step--current">
                        <div className="stepper__container__step__marker"></div>
                        <div className={`stepper__container__step__paperplane ${(currentStep === steppersList.length) ? "stepper__container__step__paperplane--flying" : ""}`}>
                            <img src={Plane} alt="step" />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Stepper


export const StepperLinear = ({ steppersList = [], currentStep = 0 }) => {
    return (
        <div className="stepper-linear">
            {steppersList.map((el, i) => ( el &&
                <div key={i} className={`stepper-linear__step`}>
                    <div className={`stepper-linear__step__marker ${currentStep >= i ? "stepper-linear__step__marker--current" : ""}`}></div>
                    <div className={`stepper-linear__step__name ${currentStep >= i ? "stepper-linear__step__name" : "stepper-linear__step__name--next"}`}>{el}</div>
                </div>
            ))}
            <div className={`stepper-linear__step__check ${currentStep > steppersList.length ? "stepper-linear__step__check--current" : ""}`}><Check /></div>
        </div>
    )

}