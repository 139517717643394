import React from "react";
import { useSelector } from 'react-redux'
import './index.scss'

const Warning = () => {
  const app = useSelector(state => state.app)
  const { error, subError, errorTitle } = app

  return (
    <div className={error ? 'warning warning--visible' : 'warning'}>
      {error &&
        <div className='warning__content'>
          {errorTitle && "ERRO"}
          <h1>{error}</h1>
          {/* <p><span>Clique aqui</span> para entrar em contato com a nossa equipe de relacionamento</p> */}
          <p>{subError ? subError : 'Por favor, confira os dados do formulário'}</p>
        </div>}
    </div>
  )
}

export default Warning
