import React, { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import './index.scss'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { email as emailFormat, formatDocument, onlyNumber } from '../../constants/validate'

const Sender = ({ setStep, setError }) => {

  const notify = useSelector(state => state.notify)
  const login = useSelector(state => state.login)
  const dispatch = useDispatch()
  const { senderName, senderDocument, senderEmail } = notify
  const { user } = login

  useEffect(() => {
    if(!_.isEmpty(user.email)){
      setUser(user)
    }
  }, [user])
  
  function setSenderName(name){
    dispatch({type: 'CHANGE_NOTIFY_SENDER_NAME', payload: name})
  }

  function setSenderDocument(document){
    dispatch({type: 'CHANGE_NOTIFY_SENDER_DOCUMENT', payload: document})
  }

  function setSenderEmail(email){
    dispatch({type: 'CHANGE_NOTIFY_SENDER_EMAIL', payload: email})
  }

  function setUser(user){
    dispatch({type: 'CHANGE_NOTIFY_USER', payload: user})
  }

  function handleClick() {
    let allowed = false

    if (senderName.length > 0) {
      if (onlyNumber(senderDocument).length === 11 || onlyNumber(senderDocument).length === 14) {
        if (emailFormat(senderEmail) !== 'Email Inválido') {
          allowed = true
        }
      }
    }
    return allowed
  }

  function handleEmail() {
    if (emailFormat(senderEmail) === 'Email Inválido' && senderEmail !== '') {
      setError('Email inválido')
    }
    else {
      setError('')
    }
  }

  return (
    <form className="sender" onSubmit={() => handleClick() ? setStep(2) && setError(false) : ''}>
      <h2>Quais as informações do remetente?</h2>
      <h3>Informe o nome, email e CPF/CNPJ do remetente, ou seja, a pessoa ou a empresa que está enviando a notificação (Notificante).</h3>
      <Input type={!_.isEmpty(user.email) ? '3' : '2'} placeholder='Escreva aqui o nome do remetente' value={!_.isEmpty(user.email) ? user.name : senderName} onChange={_.isEmpty(user.email) && setSenderName} />
      <Input inputType="email" type={!_.isEmpty(user.email) ? '3' : '2'} placeholder='Escreva aqui o email do remetente' value={!_.isEmpty(user.email) ? user.email : senderEmail} onChange={_.isEmpty(user.email) && setSenderEmail} validate={emailFormat} onBlur={handleEmail} />
      <Input type={!_.isEmpty(user.email) ? '3' : '2'} placeholder='Escreva aqui o CPF/CNPJ do remetente' value={!_.isEmpty(user.email) ? user.document :senderDocument} onChange={_.isEmpty(user.email) && setSenderDocument} format={formatDocument} number={true} />
      <Button scrollLink={true} type={handleClick() ? '1' : '4'} fontSize="16px" arrowRight={true} onClick={() => handleClick() ? setStep(2) && setError(false) : ''}>Ir para a próxima etapa</Button>
    </form>
  )
}

export default Sender
