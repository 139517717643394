import React, { useState, useEffect, useRef } from 'react'

import { ChevronDown, ChevronUp } from 'lucide-react'

import './index.scss'

const PaginationControl = ({
  currentPage,
  notificationsPerPage,
  setNotificationsPerPage,
  totalNotifications,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960)
  const paginationControlRef = useRef(null)

  const options = [5, 10, 15]

  const handleClickOutside = event => {
    if (paginationControlRef.current && !paginationControlRef.current.contains(event.target)) {
      setIsActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  const handleOptionClick = option => {
    setNotificationsPerPage(option)
    localStorage.setItem('userPreferences', JSON.stringify({ notificationsPerPage: option }))

    setIsActive(false)
  }

  return (
    <>
      {!isMobile && (
        <div className='pagination-control'>
          <div className='pagination-control__select' ref={paginationControlRef}>
            <button
              className='pagination-control__button'
              onClick={() => {
                setIsActive(!isActive)
              }}>
              <span>{notificationsPerPage}</span> por página{' '}
              {isActive ? (
                <ChevronUp size='12px' color='#3B3939' />
              ) : (
                <ChevronDown size='12px' color='#3B3939' />
              )}
            </button>
            {isActive && (
              <ul className='pagination-control__content'>
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleOptionClick(option)
                    }}
                    className='pagination-control__content-item'>
                    {option} por página
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='pagination-control__info'>
            <p>
              <span>
                {totalNotifications === 0 ? 0 : (currentPage - 1) * notificationsPerPage + 1}-
                {totalNotifications < currentPage * notificationsPerPage
                  ? totalNotifications
                  : currentPage * notificationsPerPage}{' '}
              </span>
              de {totalNotifications}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default PaginationControl
