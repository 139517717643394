export const getGreeting = () => {
  let now = new Date()
  let hours = now.getHours()

  if(hours < 13) {
    return "Bom dia"
  }
  else if(hours < 18) {
    return "Boa tarde"
  }
  else {
    return "Boa noite"
  }
}


export const logout = () => {
  localStorage.removeItem('user')
  window.location.href = "/"
}


export const rollup = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const freePlan = {
  "discount": {
    "tag": null,
    "originalValue": null,
    "originalPlanId": null
  },
  "description": "Ideal para empresas/escritórios de pequeno porte.",
  "info": [
    true,
    false,
    false,
    false,
    false
  ],
  "isCoupon": false,
  "isPromo": false,
  "isFree": true,
  "visible": true,
  "createdAt": "2021-03-05T14:24:46.709Z",
  "_id": "605d1b241600060603867883",
  "planId": 999,
  "header": "Pacote 3",
  "value": 0,
  "numberOfNotifications": 3,
  "__v": 0
}
    
    

