import { useEffect, useState } from 'react';
// import debounce from 'lodash/debounce';

function useWidthWindow(delay = 700) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    // const debouncedHandleResize = debounce(handleResize, delay);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [delay]);

  return width;
}

export default useWidthWindow;