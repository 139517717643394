import React from "react";

import './index.scss'

import Plane from '../../assets/plane.svg'

const ProgressBar = ({ step }) => {
  return (
    <div className="progress-bar">
      <div className={(step === 1 || step === 5) ? "progress-bar__phase--filled" : "progress-bar__phase"}>
        <div className={(step === 1 || step === 5) ? "circle circle--filled" : "circle"} />
        <p>Remetente</p>
      </div>
      <div className="dashed-line" />
      <div className={(step === 2 || step === 5) ? "progress-bar__phase--filled" : "progress-bar__phase"}>
        <div className={(step === 2 || step === 5) ? "circle circle--filled" : "circle"} />
        <p>Documento</p>
      </div>
      <div className="dashed-line" />
      <div className={(step === 3 || step === 5) ? "progress-bar__phase--filled" : "progress-bar__phase"}>
        <div className={(step === 3 || step === 5) ? "circle circle--filled" : "circle"} />
        <p>Destinatário</p>
      </div>
      <div className="dashed-line" />
      <div className={(step === 4 || step === 5) ? "progress-bar__phase--filled" : "progress-bar__phase"}>
        <div className={(step === 4 || step === 5) ? "circle circle--filled" : "circle"} />
        <p>Verificação</p>
      </div>
      <div className="dashed-line" />
      <div className={step === 5 ? "circle__success circle__success--visible" : "circle__success"}/>
      <img src={Plane} alt="step" className={step === 5 ? "progress-bar__plane progress-bar__plane--flying" : "progress-bar__plane"} />
    </div>
  )
}

export default ProgressBar
