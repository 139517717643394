import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import { X } from 'lucide-react'

import './index.scss'

import Button from '../Button'

const Document = ({ setStep, setError }) => {

  const notify = useSelector(state => state.notify)
  const dispatch = useDispatch()

  function setPdf(file){
    dispatch({type: 'CHANGE_NOTIFY_DOCUMENT_PDF', payload: file})
  }

  const onFileChange = (event) => {
    if (megaByte(event.target.files[0].size) <= 10) {
      setError('')
      setPdf(event.target.files[0])
    }
    else {
      setError('O tamanho do PDF é maior do que 10Mb')
    }
  };

  const fileUploadButton = () => {
    document.getElementById('fileButton').click()
  }

  const megaByte = (size) => {
    return Number((size / 1000000).toFixed(1))
  }

  const { pdf } = notify

  function validFile(doc) {
    if (doc && validTypes.includes(doc.type)) {
      return true
    } else {
      return false
    }
  }

  const validTypes = [
    "application/pdf"
  ]

  return (
    <form className="document" onSubmit={() => pdf && setStep(3)}>
      <h2>Insira o documento a ser anexado</h2>
      <h3>Insira abaixo o documento a ser anexado junto a notificação. Lembre-se o documento precisa ser .PDF e não possuir um tamanho maior que 10mb.</h3>
      <input id="fileButton" type="file" accept={validTypes} hidden onChange={onFileChange} />
      <div className="document__holder">
        {
          pdf ?
            <div className="document__upload">
              <div>.PDF</div>
              <div>
                <span>{pdf.name}</span>
                <span>{`${megaByte(pdf.size)}MB`}</span>
                <X onClick={() => { document.getElementById('fileButton').value = ""; setPdf(null) }} />
              </div>
            </div>
            :
            <Button scrollLink={true} height='50px' type='5' fontSize="16px" downloadCloud={true} onClick={fileUploadButton}>Inserir</Button>
        }
      </div>
      <Button submit={true} scrollLink={true} type={validFile(pdf) ? '1' : '13'} fontSize="16px" arrowRight={true} onClick={() => setStep(3)}>Ir para a próxima etapa</Button>
      <div className="back-button" onClick={() => setStep(1)}>Voltar</div>
    </form>
  )
}

export default Document
