import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import _, { add } from 'lodash'
import axios from 'axios'

import './index.scss'
import CreditCard from './components/CreditCard'
import Warning from '../../components/Warning'
import Plans from './components/Plans'
import Informations from './components/Informations'
import Checkout from './components/Checkout'
//import SubscriptionConfirm from './components/SubscripitonConfirm'
//import CancelPlan from './components/CancelPlan'
import Button from '../../components/Button'
import {
  email as emailFormat,
  onlyNumber,
  validateCpf,
  validateCnpj,
} from '../../constants/validate'
import Footer from '../../components/Footer'
import { StepperLinear } from '../../components/Stepper'

const Subscription = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const subscription = useSelector(state => state.subscription)
  const login = useSelector(state => state.login)
  //const [documentValid,setDocumentValid] = useState(false)
  const { step, plan, card, customer, address, terms, singlePlan, coupon, phone, recaptcha } =
    subscription
  const { user } = login

  const [checkoutLoading, setCheckoutLoading] = useState(false)

  useEffect(() => {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: 'planos' })
    return () => {}
  }, [dispatch])

  function showError(error, subError = false, errorTitle = true) {
    dispatch({ type: 'CHANGE_APP_ERROR', payload: error })
    dispatch({ type: 'CHANGE_APP_ERROR_TITLE', payload: errorTitle })

    if (subError) {
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: subError })
    }

    setTimeout(() => {
      dispatch({ type: 'CHANGE_APP_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_SUB_ERROR', payload: '' })
      dispatch({ type: 'CHANGE_APP_ERROR_TITLE', payload: true })
    }, 5000)
  }

  function changeStep(step) {
    dispatch({ type: 'CHANGE_SUBSCRIPTION_STEP', payload: step })
    window.scrollTo(0, 0)
  }

  function changePlan(plan) {
    dispatch({ type: 'CHANGE_SUBSCRIPTION_PLAN', payload: plan })
    if (_.isEmpty(user.email)) {
      changeStep(1)
    } else if (!_.isEmpty(user.email) && plan.isFree) {
      changeStep(3)
    } else {
      changeStep(2)
    }
  }
  function changeCardField(value, field) {
    dispatch({ type: 'CHANGE_SUBSCRIPTION_CARD_FIELD', field, payload: value })
  }

  function changeCustomerField(value, field) {
    dispatch({
      type: 'CHANGE_SUBSCRIPTION_CUSTOMER_FIELD',
      field,
      payload: value,
    })
    if (field === 'email') {
      dispatch({
        type: 'CHANGE_SUBSCRIPTION_CUSTOMER_FIELD',
        field: 'confirmationCode',
        payload: '',
      })
      dispatch({ type: 'CHANGE_NOTIFY_CODE', payload: '' })
    }
  }

  function changeAddressField(value, field) {
    dispatch({
      type: 'CHANGE_SUBSCRIPTION_ADDRESS_FIELD',
      field,
      payload: value,
    })
  }

  function changePhoneField(value, field) {
    dispatch({
      type: 'CHANGE_SUBSCRIPTION_PHONE_FIELD',
      field,
      payload: value,
    })
  }

  // function changeTerms(terms) {
  //   dispatch({ type: 'CHANGE_SUBSCRIPTION_TERMS', payload: terms })
  // }

  // temp
  function handleFreeSubscription() {
    if (customer.document.length < 14) {
      showError('Documento inválido')
    } else if (customer.document.length === 14 && !validateCpf(customer.document)) {
      showError('CPF inválido.')
      return
    } else if (customer.document.length === 18 && !validateCnpj(customer.document)) {
      showError('CNPJ inválido.')
      return
    } else if (customer.name === '' || customer.email === '' || customer.document === '') {
      showError('Preencha todos os campos.')
      return
    } else if (emailFormat(customer.email) === 'Email Inválido') {
      showError('Email inválido')
      return
    } else if (
      onlyNumber(customer.document).length !== 11 &&
      onlyNumber(customer.document).length !== 14
    ) {
      showError('CPF/CNPJ inválido.')
      return
    }

    setCheckoutLoading(true)
    axios
      .post('user/check-email', {
        email: customer.email,
        document: customer.document,
      })
      .then(response => {
        axios
          .post(
            '/subscription/sign',
            {
              customer,
              plan,
              newUser: true,
            },
            {
              headers: {
                ecommerceAuth:
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MTYyMzE2NTksImV4cCI6MTc0Nzc2NzY1OSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.3P3_2wYN5aZmPy36irt4Xq4z7mx0jTBijEPmp83GXgM',
              },
            }
          )
          .then(response => {
            setCheckoutLoading(false)
            userLogin(response.data.data)
            history.push('/planos/confirmado')
          })
          .catch(e => {
            setCheckoutLoading(false)
            console.log(e)
            showError('Erro no cadastro. Tente novamente')
          })
      })
      .catch(e => {
        switch (e.response.data) {
          case 'This email is already registered':
            showError('E-mail já cadastrado.', 'Favor realizar o login ou usar outro email.')
            break
          case 'This document is already registered':
            showError('Documento já cadastrado.', 'Favor realizar o login.')
            break

          default:
            break
        }
      })
  }

  function handleRegisterSubmit() {
    if (customer.document.length < 14) {
      showError('Documento inválido')
    }
    if (customer.document.length === 14 && !validateCpf(customer.document)) {
      showError('CPF inválido.')
      return
    }
    if (customer.document.length === 18 && !validateCnpj(customer.document)) {
      showError('CNPJ inválido.')
      return
    }

    if (recaptcha.length === 0) {
      showError('Captcha não preenchido')
      return
    }

    if (
      customer.name === '' ||
      customer.email === '' ||
      customer.document === '' ||
      address.street === '' ||
      address.streetNumber === '' ||
      address.zipcode === '' ||
      address.neighborhood === '' ||
      phone === ''
    ) {
      // if (customer.name === '' || customer.email === '' || customer.document === '' || customer.telephone === '' || address.zipcode === '' || address.city === '' || address.neighborhood === '' || address.street === '' || address.streetNumber === '') {
      showError('Preencha todos os campos.')
      return
    }

    // if (_.isEmpty(user.email) && customer.password !== customer.confirmPassword) {
    //   showError('As senhas não são iguais')
    //   return
    // }

    if (emailFormat(customer.email) === 'Email Inválido') {
      showError('Email inválido')
      return
    }
    if (
      onlyNumber(customer.document).length !== 11 &&
      onlyNumber(customer.document).length !== 14
    ) {
      showError('CPF/CNPJ inválido.')
      return
    }

    // if (onlyNumber(address.zipcode).length !== 8) {
    //   showError('CEP inválido.')
    //   return
    // }

    // if (onlyNumber(customer.telephone).length < 10) {
    //   showError('Número de telefone inválido.')
    //   return
    // }

    // if (_.isEmpty(user.email)) {
    axios
      .post('user/check-email', {
        email: customer.email,
        document: customer.document,
      })
      .then(response => {
        changeStep(2)
      })
      .catch(e => {
        switch (e.response.data) {
          case 'This email is already registered':
            showError('E-mail já cadastrado.', 'Favor realizar o login ou usar outro email.')
            break
          case 'This document is already registered':
            showError('Documento já cadastrado.', 'Favor realizar o login.')
            break

          default:
            break
        }
      })
    // }
    // else {
    //   changeStep(3)
    // }
  }

  function handleBackStep() {
    if (_.isEmpty(user.email)) {
      changeStep(1)
    } else {
      changeStep(0)
    }
  }

  function handleBackStepCheckout() {
    if (!_.isEmpty(user.email) && plan.isFree) {
      changeStep(0)
    } else {
      changeStep(2)
    }
  }

  function handlePaymentSubmit() {
    if (recaptcha.length === 0) {
      showError('Captcha não preenchido')
      return
    }

    if (
      card.number === '' ||
      card.holderName === '' ||
      card.expirationDate === '' ||
      card.cvv === '' ||
      address.street === '' ||
      address.streetNumber === '' ||
      address.zipcode === '' ||
      address.neighborhood === '' ||
      phone.number === ''
    ) {
      // if (customer.name === '' || customer.email === '' || customer.document === '' || customer.telephone === '' || address.zipcode === '' || address.city === '' || address.neighborhood === '' || address.street === '' || address.streetNumber === '') {
      showError('Preencha todos os campos.')
      return
    }

    if (
      onlyNumber(card.cvv).length !== 3 ||
      onlyNumber(card.expirationDate).length !== 4 ||
      onlyNumber(card.number).length !== 16
    ) {
      showError('Por favor, revise os dados do cartão.')
      return
    } else {
      changeStep(3)
    }
  }

  function subscribe() {
    setCheckoutLoading(true)
    let newUser = false
    let customerData
    if (_.isEmpty(user.email)) {
      newUser = true
    }

    if (!terms) {
      setCheckoutLoading(false)
      showError('Você precisa aceitar os termos de uso.')
      return
    }

    let postBody = {}
    let url = ''
    if (singlePlan) {
      // compra por pacote

      if (user.email) {
        customerData = user
      } else {
        customerData = customer
      }
      postBody = {
        paymentMethod: 'card',
        pack: {
          id: plan.planId,
          header: plan.header,
          value: plan.value,
          numberOfNotifications: plan.numberOfNotifications,
        },
        card: {
          expirationDate: onlyNumber(card.expirationDate),
          number: onlyNumber(card.number),
          cvv: card.cvv,
          holderName: card.holderName,
        },
        customer: {
          ...customerData,
          phoneNumbers: [],
        },
        address,
        phone,
        'g-recaptcha-response': recaptcha,
        newUser,
      }
      url = 'subscription/buy-package'
    } else {
      // compra recorrente
      if (user.email) {
        customerData = user
      } else {
        customerData = customer
      }
      postBody = {
        customer: customerData,
        card,
        address,
        phone,
        'g-recaptcha-response': recaptcha,
        plan,
        newUser,
        coupon: coupon.name,
      }
      url = 'subscription/sign'
    }
    axios
      .post(url, postBody)
      .then(response => {
        //changeStep(4)
        userLogin(response.data.data)
        history.push('/planos/confirmado')
        // dispatch({type: 'RESET_SUBSCRIPTION'})
        setCheckoutLoading(false)
      })
      .catch(err => {
        setCheckoutLoading(false)
        if (err.response?.status === 412) {
          showError(
            'Você já usou esta promoção.',
            'Aguarde o termino da promoção para comprar esse plano novamente.'
          )
        } else if (err.response?.data === 'User already used a free trial') {
          showError('Você já usou seu período gratuito.', 'Escolha outro plano.')
        } else if (err.response?.data?.response?.errors[0].message) {
          showError(err.response.data.response.errors[0].message)
        } else {
          showError('Erro ao realizar o pagamento. Entre em contato com nosso suporte.')
        }
        changeStep(2)
      })
  }

  function handleCancelPlan() {
    axios
      .delete('/subscription/cancel')
      .then(response => {
        dispatch({ type: 'CHANGE_LOGIN_SUBSCRIPTION' })
        //changeStep(5)
        history.push('/planos/cancelado')
        removeSubscriptionFromStorage(user)
      })
      .catch(e => {
        showError('Erro ao cancelar o plano. Tente novamente mais tarde.')
      })
  }

  function userLogin(user) {
    localStorage.setItem('user', JSON.stringify(user))
    axios.defaults.headers.common['authentication'] = user.token
    dispatch({ type: 'CHANGE_LOGIN_USER', payload: user })
  }

  function removeSubscriptionFromStorage(newUser) {
    newUser.subscription.planId = null
    newUser.subscription.id = null
    newUser.subscription.maxQuota = null
    newUser.subscription.plan = null
    newUser.subscription.status = 'canceled'
    localStorage.setItem('user', JSON.stringify(newUser))
  }

  function openContactModal(bool) {
    dispatch({ type: 'CHANGE_APP_CONTACT_US_MODAL', payload: bool })
  }

  // function resetSubscription() {
  //   dispatch({ type: 'RESET_SUBSCRIPTION' })
  // }

  function stepperList() {
    // if (user?.token?.length > 0)
    // return ['Informações', '', 'Pagamento', 'Checkout']
    return ['Cadastro', 'Pagamento', 'Checkout']
    // else
    // return ['Informações', 'Validação', 'Pagamento', 'Checkout']
  }

  return (
    <div>
      <div className='subscription'>
        {step === 0 && (
          <div className='subscription__plans'>
            <Plans
              coupon={coupon}
              changePlan={changePlan}
              user={user}
              handleCancelPlan={handleCancelPlan}
              openContactModal={openContactModal}
            />
          </div>
        )}
        {step === 1 && (
          <div className='subscription__register'>
            {!plan.isFree ? (
              <div className='subscription__stepper'>
                <StepperLinear currentStep={step - 1} steppersList={stepperList()} />
              </div>
            ) : (
              ''
            )}
            <div
              className={`subscription__register__container ${
                plan.isFree ? 'subscription__register__container--free' : ''
              }`}>
              {_.isEmpty(user.email) ? <h1>Cadastro</h1> : <h1>Confirmar cadastro</h1>}
              <Informations
                customer={customer}
                changeCustomerField={changeCustomerField}
                user={user}
              />
              {/* <Informations customer={customer} changeCustomerField={changeCustomerField} address={address} changeAddressField={changeAddressField} user={user} /> */}
            </div>
            <div className='subscription__register__buttons'>
              <Button action={true} type='2' height='50px' onClick={() => changeStep(0)}>
                Voltar
              </Button>
              {!plan.isFree ? (
                <Button
                  action={true}
                  type='5'
                  height='50px'
                  chevronRight={true}
                  onClick={() => handleRegisterSubmit()}>
                  Continuar
                </Button>
              ) : (
                <Button
                  action={true}
                  type='5'
                  height='50px'
                  chevronRight={true}
                  onClick={() => handleFreeSubscription()}>
                  Finalizar
                </Button>
              )}
            </div>
          </div>
        )}
        {/* {step === 2 &&
          <div className="subscription__verify">
            <div className="subscription__stepper">
              <StepperLinear currentStep={step - 1} steppersList={stepperList()} />
            </div>
            <div className="subscription__verify__container">
              <h1>Validação:</h1>
              <VerifyEmail setStep={changeStep} setError={showError} plansScreen/>
            </div>
          </div>
        } */}
        {step === 2 && (
          <div className='subscription__payment'>
            <div className='subscription__stepper'>
              <StepperLinear currentStep={step - 1} steppersList={stepperList()} />
            </div>
            <div className='subscription__payment__container'>
              {/* {plan.isFree &&
                <>
                  <h1 style={{marginBottom: '9px'}}>Pagamento</h1>
                  <p>Você será cobrado R${plan.discount?.originalValue} após os {plan.discount?.tag}.</p>
                </>
              } */}
              {/* {!plan.isFree && */}
              <h1>Pagamento</h1>
              {/* } */}
              <CreditCard
                changeCardField={changeCardField}
                card={card}
                address={address}
                changeAddressField={changeAddressField}
                phone={phone}
                changePhoneField={changePhoneField}
              />
            </div>
            <div className='subscription__payment__buttons'>
              <Button action={true} type='2' height='50px' onClick={() => handleBackStep()}>
                Voltar
              </Button>
              <Button
                action={true}
                type='5'
                height='50px'
                chevronRight={true}
                loading={checkoutLoading}
                onClick={() => handlePaymentSubmit()}>
                Continuar
              </Button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className='subscription__checkout'>
            {!plan.isFree ? (
              <div className='subscription__stepper'>
                <StepperLinear currentStep={step - 1} steppersList={stepperList()} />
              </div>
            ) : (
              ''
            )}

            <div className='subscription__checkout__section'>
              <div className='subscription__checkout__background'></div>
              <div className='subscription__checkout__container'>
                <h1>Checkout</h1>
                <Checkout />
                <div className='subscription__checkout__buttons'>
                  <Button
                    action={true}
                    type='2'
                    height='50px'
                    onClick={() => {
                      handleBackStepCheckout()
                      setCheckoutLoading(false)
                    }}>
                    Voltar
                  </Button>
                  <Button
                    action={true}
                    loading={checkoutLoading}
                    type='5'
                    height='50px'
                    chevronRight={true}
                    onClick={() => !checkoutLoading && subscribe()}>
                    Continuar
                  </Button>
                </div>
              </div>

              <div className='subscription__checkout__background'></div>
            </div>
          </div>
        )}
        {/* {step === 4 &&
          <div className="subscription__confirm">
            <div className="subscription__stepper">
              <StepperLinear currentStep={step} steppersList={['Informações', 'Pagamento', 'Checkout']} />
            </div>
            <div className="subscription__confirm__container">
              <SubscriptionConfirm />
            </div>
          </div>
        } */}
        {/* {step === 5 &&
          <CancelPlan resetSubscription={resetSubscription} />
        } */}
        <Warning />
      </div>
      <Footer />
    </div>
  )
}

export default Subscription
