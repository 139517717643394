export default function (
  state = {
    error: '',
    subError: '',
    errorTitle: true,
    menu: false,
    page: '',
    contactUsModal: false,
    contactUsModalGPT: false,
    miniBanner: true,
  },
  action
) {
  switch (action.type) {
    case 'CHANGE_APP_ERROR':
      return { ...state, error: action.payload }
    case 'CHANGE_APP_SUB_ERROR':
      return { ...state, subError: action.payload }
    case 'CHANGE_APP_ERROR_TITLE':
      return { ...state, errorTitle: action.payload }
    case 'CHANGE_APP_MENU':
      return { ...state, menu: action.payload }
    case 'CHANGE_APP_PAGE':
      return { ...state, page: action.payload }
    case 'CHANGE_APP_CONTACT_US_MODAL':
      return { ...state, contactUsModal: action.payload }
    case 'CHANGE_APP_CONTACT_US_MODAL_GPT':
      return { ...state, contactUsModalGPT: action.payload }
    case 'CHANGE_APP_MINIBANNER':
      return { ...state, miniBanner: action.payload }
    default:
      return state
  }
}
