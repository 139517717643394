import React from 'react'

import Modal from '../Modal'
import Button from '../Button'
import { formatDate } from '../../constants/validate'

export const ModalCancelPlan = ({ show, setShow, onCancelPlan, cicleDate }) => {
  return (
    <Modal
      show={show}
      title='Deseja mesmo cancelar seu plano?'
      titleWidth={256}
      renderFooter={
        <div>
          <Button action={true} type={6} onClick={() => setShow(false)}>
            Voltar
          </Button>
          <Button action={true} type={7} onClick={onCancelPlan}>
            Cancelar plano
          </Button>
        </div>
      }>
      <p>
        O cancelamento passa a vigorar ao fim desse ciclo de cobrança:{' '}
        <strong>{formatDate(cicleDate, false, true)}.</strong>
      </p>
      <br />
      <p>Você ainda poderá enviar notificações não utilizadas neste mês.</p>
    </Modal>
  )
}
