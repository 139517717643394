import React from 'react'
import './index.scss'

import { X } from 'lucide-react'

function Modal({ show = false, close, title, titleWidth = 256, modalWidth = 452, children, renderFooter, backdropColor }) {

    const handleCloseModal = () => close()

    return (
        <div className={`modal ${show && 'modal--visible'}`} style={{backgroundColor: backdropColor}}>
            <div className={`modal__container ${show && "modal__container--visible"}`} style={{ maxWidth: modalWidth }}>
                {title &&
                    <div className="modal__container__header">
                        <div className="modal__container__title" style={{ width: titleWidth }}>
                            <h1>{title}</h1>
                        </div>
                        {close && <X className="modal__container__close" size={30} onClick={handleCloseModal} />}
                    </div>
                }

                <div className="modal__container__content">
                    {children}
                </div>

                {renderFooter&&
                    <div className="modal__container__footer">
                        {renderFooter}
                    </div>
                }
            </div>
        </div>
    )
}

export default Modal
