import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// pdfMake.fonts = {
//   Roboto: {
//     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//   }
// }

const TEMPLATES = (text) => {
  return {
    notification: {
      pageSize: "A4",
      pageOrientation: "portrait",
      pageMargins: [5, 40],
      content: [
        {
          alignment: "center",
          width: 160,
          marginBottom: 20,
          svg: `<svg width="651" height="160" viewBox="0 0 651 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M160 135.097V24H140.657L96.8134 99.8835L20.7316 24H0V135.097H160ZM140.558 62.9833V115.655H110.105L140.558 62.9833ZM85.2077 115.655H19.442V50.088L85.2077 115.655Z" fill="#009688"/>
          <path d="M270.3 40.1V120.4H258.3L223.8 71.3V120.4H208V40.1H220L254.4 89.1V40.1H270.3Z" fill="#3B3B3B"/>
          <path d="M280.4 91.7C280.4 74.6 293.8 61.4 310.7 61.4C327.6 61.4 341.1 74.6 341.1 91.7C341.1 108.8 327.6 122 310.7 122C293.8 122 280.4 108.8 280.4 91.7ZM326.3 91.7C326.3 82.4 319.5 75.9 310.7 75.9C302 75.9 295.2 82.4 295.2 91.7C295.2 101 302 107.5 310.7 107.5C319.5 107.6 326.3 101 326.3 91.7Z" fill="#3B3B3B"/>
          <path d="M364 77.3V101.2C364 107.4 368.5 107.5 377 107.1V120.5C356.2 122.8 349.2 116.7 349.2 101.2V51.5L364 47V63.1H377V77.3H364Z" fill="#3B3B3B"/>
          <path d="M384.5 47.1C384.5 42.2 388.6 37.9 393.6 37.9C398.6 37.9 402.8 42.1 402.8 47.1C402.8 52 398.7 56.2 393.6 56.2C388.7 56.2 384.5 52 384.5 47.1ZM386.2 63.1H401V120.5H386.2V63.1Z" fill="#3B3B3B"/>
          <path d="M427.7 62.5V63.1H439.8V77.2999H427.6V120.4H412.8V62.5C412.8 46.7 421.5 37.4 439.8 38.5V52.7C432.1 52.2 427.7 54.7 427.7 62.5Z" fill="#3B3B3B"/>
          <path d="M447.3 47.1C447.3 42.2 451.4 37.9 456.4 37.9C461.4 37.9 465.6 42.1 465.6 47.1C465.6 52 461.5 56.2 456.4 56.2C451.4 56.2 447.3 52 447.3 47.1ZM449 63.1H463.8V120.5H449V63.1Z" fill="#3B3B3B"/>
          <path d="M471.4 91.7C471.4 74.6 484.2 61.4 501.7 61.4C512.9 61.4 522.7 67.4 527.4 76.2L514.7 83.7C512.4 79 507.5 76 501.6 76C492.8 76 486.2 82.5 486.2 91.7C486.2 100.8 492.7 107.3 501.6 107.3C507.6 107.3 512.5 104.4 514.8 99.7L527.6 107C522.7 115.9 512.9 121.9 501.7 121.9C484.3 122 471.4 108.8 471.4 91.7Z" fill="#3B3B3B"/>
          <path d="M530.4 91.7C530.4 74.6 543.2 61.4 560.7 61.4C571.9 61.4 581.7 67.4 586.4 76.2L573.7 83.7C571.4 79 566.5 76 560.6 76C551.8 76 545.2 82.5 545.2 91.7C545.2 100.8 551.7 107.3 560.6 107.3C566.6 107.3 571.5 104.4 573.8 99.7L586.6 107C581.7 115.9 571.9 121.9 560.7 121.9C543.2 122 530.4 108.8 530.4 91.7Z" fill="#3B3B3B"/>
          <path d="M650.7 63.1V120.5H635.9V113.7C631.8 118.9 625.6 122.1 617.2 122.1C601.9 122.1 589.3 108.9 589.3 91.8C589.3 74.7 601.9 61.5 617.2 61.5C625.6 61.5 631.8 64.7 635.9 69.9V63.1H650.7ZM635.9 91.7C635.9 82.1 629.1 75.5 620 75.5C610.9 75.5 604.2 82 604.2 91.7C604.2 101.3 611 107.9 620 107.9C629.1 107.9 635.9 101.4 635.9 91.7Z" fill="#3B3B3B"/>
          </svg>
        `,
        },
        {
          text: text,
          style: "title",
        },
      ],
      styles: {
        title: {
          fontSize: 14,
          alignment: "left",
          bold: false,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 14,
          font: "Roboto",
        },
      },
    },
  };
};

export function usePDF(template = "notification") {
  const createPDF = (data = {}, fileName = "", title = "") => {
    return new Promise((resolve, reject) => {
      const docDef = { ...TEMPLATES(data, title)[template] };
      pdfMake.createPdf(docDef).getBlob((result) => {
        result.name = fileName;
        resolve(result);
      });
    });
  };
  return { createPDF };
}
