import React from "react";
import Footer from "../../components/Footer";
import { legalOpinion } from './constant'
import './index.scss'

const LegalOpinion = () => {

  return (
    <div className="legal-opinion">
      <div className='legal-opinion__title'>
        <div className='legal-opinion__title__content'>Legal Opinion</div>
      </div>
      {legalOpinion.map((item, index) => {
        let char = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n']
          return (
            <div className="legal-opinion__text">
              {item.header&&<div className="legal-opinion__header">{item.header}</div>}
              {item.paragraphs &&
                item.paragraphs.map(text => {
                  return (<div className="legal-opinion__paragraph" dangerouslySetInnerHTML={{ __html: text}}></div>)
                })}
              {item.list &&
                item.list.map((text, i) => {
                  return (<div className="legal-opinion__paragraph">({char[i]})&nbsp;{text}</div>)
                })}
              {item.dots &&
                item.dots.map((dot) => {
                  return (<li className="legal-opinion__dots">{dot}</li>)
                })}
              {item.subparagraphs &&
                item.subparagraphs.map(text => {
                  return (<div className="legal-opinion__subparagraph" dangerouslySetInnerHTML={{ __html: text}}></div>)
                })}
            </div>
          )
      })}
      <Footer/>
    </div>

  )
}

export default LegalOpinion
