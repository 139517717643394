import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import _ from "lodash";
import { usePDF } from "../../../../hooks/usePDF";
import { BounceLoader } from "react-spinners";
import "./index.scss";

import Button from "../../../../components/Button";
import { Repeat } from "react-feather";
import useWindowWidth from "../../../../hooks/useWindowWidth";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pdf = ({ name, setStep, pdf, setPdf, text }) => {
  const inputRef = useRef();
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const { createPDF } = usePDF();
  const width = useWindowWidth()

  useEffect(() => {
    const fetchPdf = async () => {
      setPdf(await createPDF(text, `Notificação extrajudicial para fulano`));
    };

    fetchPdf();
  }, []);

  function onDocumentLoadSuccess({ numPages, ...all }) {
    setNumPages(numPages);
    setTimeout(function () {}, 1000);
    setLoading(false);
  }
  const megaByte = (size) => Number((size / 1000000).toFixed(1));
  const fileUploadButton = () => inputRef.current.click();

  const onFileChange = (evt) => {
    if (megaByte(evt.target.files[0].size) <= 10) {
      setPdf(evt.target.files[0]);
    } else {
      //setError('O tamanho do file é maior do que 10Mb')
    }
  };

  return (
    <div className="pdf">
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={onFileChange}
        accept={"application/pdf"}
      />
      <header>Documento</header>
      <h3>
        Revise o documento da notificação. Você pode substituí-lo clicando no
        botão “Substituir documento”.
      </h3>
      <div className="pdf__change-document" onClick={fileUploadButton}>
        <Repeat height={18} /> Substituir documento
      </div>
      <div
        className={
          loading ? "pdf__document pdf__document--loading" : "pdf__document"
        }
      >
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ workerSrc: "/pdf.worker.js" }}
          loading={() => <BounceLoader />}
        >
          {Array(numPages)
            .fill(null)
            .map((e, i) => (
              /* Why 52? PaddingLeft = 24px, PaddingRight = 24px, Scrollbar = 4px */
              <Page
                pageNumber={i + 1}
                key={i}
                width={Math.min(width - 52, 595)}
              />
            ))}
        </Document>
      </div>
      <div className="pdf__buttons">
        <Button
          height="44px"
          fontSize="16px"
          action={true}
          type={3}
          // onClick={fileUploadButton}
          onClick={() => setStep(3)}
        >
          Voltar
        </Button>
        <Button
          height="44px"
          fontSize="16px"
          action={true}
          onClick={() => setStep(5)}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default Pdf;
