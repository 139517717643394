import React from "react"
import { Document, Page, pdfjs } from 'react-pdf'
import { useSelector, useDispatch } from 'react-redux'
import { X } from 'lucide-react'
import './index.scss'
import { formatDocument } from '../../constants/validate'
import Button from '../Button'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Confirm = ({ onClick }) => {

  const notify = useSelector(state => state.notify)
  const dispatch = useDispatch()
  const { senderEmail, senderName, senderDocument, recipientDocument, recipientEmail,
    recipientName, pdf, loading, term, confirmModal } = notify

  function setConfirmModal(bool) {
    dispatch({ type: 'CHANGE_NOTIFY_CONFIRM_MODAL', payload: bool })
  }

  function setTerm(bool) {
    dispatch({ type: 'CHANGE_NOTIFY_TERM', payload: bool })
  }

  return (
    <div className={confirmModal ? "confirm-visible" : "confirm"}>
      <div className="confirm__background">
      </div>
      <div className="confirm__modal">
        <X className='confirm__modal__exit' color={'white'} onClick={() => setConfirmModal(false)} />
        <h1>Confirmação</h1>
        <div className='confirm__form'>
          <div className='confirm__form__document'>
            DOCUMENTO
            <Document file={pdf}
              onLoadError={e => console.log(e)}>
              <Page pageNumber={1} style={{ width: '40' }} />
            </Document>
          </div>
          <span />
          <div className='confirm__form__participants'>
            <div className='participant'>
              <p>NOTIFICANTE</p>
              <h5>{senderName}</h5>
              <h6>{senderEmail}</h6>
              <h6>{formatDocument(senderDocument)}</h6>
            </div>
            <div className='participant'>
              <p>NOTIFICADO</p>
              <h5>{recipientName}</h5>
              <h6>{recipientEmail}</h6>
              <h6>{formatDocument(recipientDocument)}</h6>
            </div>
          </div>
        </div>
        <div className="confirmation__terms" onClick={() => setTerm(!term)}>
          <span className='confirmation__terms__checkBox' onClick={() => setTerm(!term)}>
            <span className='confirmation__terms__checkBox--selected' style={{ display: term ? 'unset' : 'none' }} />
          </span>
          <p>Concordo com os&nbsp;<a href="https://www.notificca.com.br/termos-de-uso" target="_blank" rel="noopener noreferrer">termos de uso</a><p>&nbsp;da aplicação Notificca</p></p>
        </div>
        <Button scrollLink={true} loading={loading} green={true} type={term ? '3' : '13'} fontSize='16px' width='80%' onClick={() => term && onClick()}>Confirmar e enviar</Button>
      </div>
    </div>
  )
}

export default Confirm
