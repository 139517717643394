import React, { useCallback, useEffect, useRef, useState } from 'react'

import './index.scss'

const getTimeRemaining = e => {
  const total = Date.parse(e) - Date.parse(new Date())
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / 1000 / 60 / 60) % 24)
  return {
    total,
    hours,
    minutes,
    seconds,
  }
}

const ResendCodeTimer = ({ hasResentCode, changeHasResentCode }) => {
  const [timer, setTimer] = useState('00:00:00')
  const Ref = useRef(null)

  /* Call resent code timer when component render */
  useEffect(() => {
    if (!hasResentCode) {
      changeHasResentCode(true)
    }
  }, [changeHasResentCode, hasResentCode])

  const startTimer = e => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }

  const clearTimer = useCallback(e => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('00:01:30')

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 1000)
    Ref.current = id
  }, [])

  const getDeadTime = () => {
    let deadline = new Date()

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 90)
    return deadline
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime())

    /* Clear interval when component unmount */
    return () => {
      if (Ref.current) clearInterval(Ref.current)
    }
  }, [clearTimer])

  if (!hasResentCode) return null

  return <span className='timer'>({timer})</span>
}

export default ResendCodeTimer
