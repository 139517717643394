import axios from 'axios'
import _ from 'lodash'
import { CreditCard, Home, Mail, Shield, User, X } from 'lucide-react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '../Button'

import './index.scss'

const Menu = () => {
  const app = useSelector(state => state.app)
  const login = useSelector(state => state.login)
  const [out, setOut] = useState(false)
  const dispatch = useDispatch()
  const { user } = login
  const { menu, page } = app
  let history = useHistory()
  function setMenuVisible(bool) {
    dispatch({ type: 'CHANGE_APP_MENU', payload: bool })
  }

  function switchPage(page) {
    dispatch({ type: 'CHANGE_APP_PAGE', payload: page })
    history.push(`/${page}`)
    setMenuVisible(false)
  }

  function setUser(user) {
    dispatch({ type: 'CHANGE_LOGIN_USER', payload: user })
  }

  function logout() {
    setOut(true)
    axios
      .delete('/user/logout')
      .then(response => {
        //history.push("/")
        localStorage.removeItem('user')
        window.location = '/login' //fix: close MiniBar when logout
        setUser({ subscription: {} })
        setOut(false)
        setMenuVisible(false)
      })
      .catch(e => {
        console.log(e)
        window.location = '/'
        localStorage.removeItem('user')
      })
  }

  function changeSubscriptionStep(step) {
    dispatch({ type: 'CHANGE_SUBSCRIPTION_STEP', payload: step })
  }

  return (
    <div className={!menu ? 'menu' : out ? 'menu menu--loading' : 'menu menu--visible'}>
      {out ? (
        <div />
      ) : (
        <div className={menu ? 'menu__form menu__form--visible' : 'menu__form'}>
          <div className='menu__back' onClick={() => setMenuVisible(false)}>
            <X color={'#5AD9AC'} size='30' />
          </div>
          {!_.isEmpty(user.email) && (
            <div>
              <div
                className={
                  page === 'notificacoes'
                    ? 'menu__form__page menu__form__page--selected'
                    : 'menu__form__page'
                }
                onClick={() => switchPage('notificacoes')}>
                <Mail size='24' /> Notificações
              </div>
              {/* <div
                className={
                  page === "importador"
                    ? "menu__form__page menu__form__page--selected"
                    : "menu__form__page"
                }
                onClick={() => switchPage("importador")}
              >
                <Layers size="24" /> Importador
              </div> */}
            </div>
          )}
          {/* <div className={page === 'planos' ? 'menu__form__page menu__form__page--selected' : 'menu__form__page'} onClick={() => {switchPage('planos?cupom=NOTIFICCA3');changeSubscriptionStep(0)}}> */}
          <div
            className={
              page === 'planos' ? 'menu__form__page menu__form__page--selected' : 'menu__form__page'
            }
            onClick={() => {
              switchPage('planos')
              changeSubscriptionStep(0)
            }}>
            <CreditCard size='24' /> Planos
          </div>
          {!_.isEmpty(user.email) && (
            <div
              className={
                page === 'perfil'
                  ? 'menu__form__page menu__form__page--selected'
                  : 'menu__form__page'
              }
              onClick={() => {
                switchPage('perfil')
              }}>
              <User size='24' /> Perfil
            </div>
          )}
          <div
            className={
              page === 'validar'
                ? 'menu__form__page menu__form__page--selected'
                : 'menu__form__page'
            }
            onClick={() => switchPage('validar')}>
            <Shield size='24' /> Validar Certificado
          </div>
          <div
            className={
              !_.isEmpty(user.email)
                ? 'menu__form__footer menu__form__footer--logged'
                : 'menu__form__footer'
            }>
            {/* <div className='menu__form__footer__contacts'>
                <div className='menu__form__footer__social-media'>
                  <a href="https://www.linkedin.com/company/sem-processo" target="_blank" rel="noopener noreferrer"><Linkedin size={20} /></a>
                  <a href="https://www.instagram.com/semprocesso/?hl=pt-br" target="_blank" rel="noopener noreferrer"><Instagram size={20} /></a>
                </div>
              </div> */}
            <Button
              action={true}
              type='1'
              width='59%'
              height='40px'
              onClick={() => switchPage('notificar')}>
              Quero notificar
            </Button>
            <Button type='2' width='25%' height='40px' onClick={() => logout()}>
              Sair
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Menu
