import React, { useState, useEffect, useRef } from 'react'

import { ChevronDown, ChevronUp } from 'lucide-react'

import './index.scss'

const Dropdown = ({ select, setSelect, options, placeholder = 'Label' }) => {
  const [isActive, setIsActive] = useState(false)
  const dropdownRef = useRef(null)

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOptionClick = option => {
    if (select === option) {
      setIsActive(false)
      return
    }

    setSelect(option)
    setIsActive(false)
  }

  return (
    <div className='dropdown' ref={dropdownRef}>
      <button
        className='dropdown__button'
        onClick={() => {
          setIsActive(!isActive)
        }}>
        {select === '' ? placeholder : options.find(opt => opt.value === select).label}
        {isActive ? <ChevronUp size='16' /> : <ChevronDown size='16' />}
      </button>
      {isActive && (
        <ul className='dropdown__content'>
          {options.map((option, index) => (
            <li
              key={index}
              className='dropdown__content-item'
              onClick={() => {
                handleOptionClick(option.value)
              }}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
