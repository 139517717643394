const INICIAL_STATE = {
  senderName: '',
  senderDocument: '',
  senderEmail: '',
  pdf: null,
  recipientName: '',
  recipientDocument: '',
  recipientEmail: '',
  step: 1,
  loading: false,
  notificationId: '',
  certification: null,
  certificationName: '',
  code: '',
  confirmModal: false,
  term: false,

  importer: {
    recipientList: [],
    file: null,
  } 
}

export default function (state = INICIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_NOTIFY_SENDER_NAME':
      return { ...state, senderName: action.payload }
    case 'CHANGE_NOTIFY_SENDER_DOCUMENT':
      return { ...state, senderDocument: action.payload }
    case 'CHANGE_NOTIFY_SENDER_EMAIL':
      return { ...state, senderEmail: action.payload }
    case 'CHANGE_NOTIFY_DOCUMENT_PDF':
      return { ...state, pdf: action.payload }
    case 'CHANGE_NOTIFY_RECIPIENT_NAME':
      return { ...state, recipientName: action.payload }
    case 'CHANGE_NOTIFY_RECIPIENT_DOCUMENT':
      return { ...state, recipientDocument: action.payload }
    case 'CHANGE_NOTIFY_RECIPIENT_EMAIL':
      return { ...state, recipientEmail: action.payload }
    case 'CHANGE_NOTIFY_STEP':
      return { ...state, step: action.payload }
    case 'CHANGE_NOTIFY_LOADING':
      return { ...state, loading: action.payload }
    case 'CHANGE_NOTIFY_NOTIFICATION_ID':
      return { ...state, notificationId: action.payload }
    case 'CHANGE_NOTIFY_CERTIFICATION':
      return { ...state, certification: action.payload }
    case 'CHANGE_NOTIFY_CERTIFICATION_NAME':
      return { ...state, certificationName: action.payload }
    case 'CHANGE_NOTIFY_CODE':
      return { ...state, code: action.payload }
    case 'CHANGE_NOTIFY_CONFIRM_MODAL':
      return { ...state, confirmModal: action.payload }
    case 'CHANGE_NOTIFY_TERM':
      return { ...state, term: action.payload }
    case 'CHANGE_NOTIFY_USER':
      return { ...state, senderName: action.payload.name, senderEmail: action.payload.email, senderDocument: action.payload.document  }
    case 'CHANE_IMPORTER_RECIPIENTLIST':
      return { ...state, importer: { ...state.importer, recipientList: action.payload } }
    case 'RESET_NOTIFY':
      return { ...INICIAL_STATE }
    default:
      return state
  }
}
