import React from 'react'
import './index.scss'

import Logo from '../../assets/logo.svg'

const links = {
  cancellationPolicy: '/politica-de-cancelamento',
  TermsOfUse: '/termos-de-uso',
  legalOpinion: '/legal-opinion',
  privacyPolicy: '/aviso-de-privacidade',
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=https://www.notificca.com.br',
  linkedin:
    'https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.notificca.com.br/v1',
  twitter: 'https://twitter.com/share?url=https://www.notificca.com.br',
}
const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__top'>
        <div className='footer__container'>
          <div className='footer__top__left'>
            <a href={links.privacyPolicy} target='_blank' rel='noopener noreferrer'>
              Aviso de privacidade
            </a>
            <a href={links.cancellationPolicy} target='_blank' rel='noopener noreferrer'>
              Política de Cancelamento
            </a>
            <a href={links.TermsOfUse} target='_blank' rel='noopener noreferrer'>
              Termos de Uso
            </a>
            <a href={links.legalOpinion} target='_blank' rel='noopener noreferrer'>
              Legal Opinion
            </a>
          </div>
          <div className='footer__top__right'>
            <img src={Logo} alt='Logo Notificca' className='footer__top__logo' />
            <div className='footer__top__separador'></div>
            <div className='footer__top__social'>
              <span>Compartilhe nas redes sociais:</span>
              <div className='footer__top__social__links'>
                <a href={links.linkedin} target='_blank' rel='noopener noreferrer'>
                  Linkedin
                </a>
                <div className='footer__top__social__links__separator'></div>
                <a href={links.twitter} target='_blank' rel='noopener noreferrer'>
                  X(Twitter)
                </a>
                <div className='footer__top__social__links__separator'></div>
                <a href={links.facebook} target='_blank' rel='noopener noreferrer'>
                  Facebook
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__bottom'>
        <div className='footer__container'>
          <p>© Copyright 2023 Sem Processo, Inc. Todos os direitos reservados.</p>
          <p>
            Rua Professor Álvaro Rodrigues, 352 - 22280-040 - Rio de Janeiro, RJ | CNPJ:
            24.463.567/0001-62
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
