import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'

import Button from '../../components/Button'
import { CodeInput } from '../CodeInput'

import './index.scss'

const VerifyEmail = ({ setStep, setError, plansScreen = false }) => {
  const dispatch = useDispatch()
  const notify = useSelector(state => state.notify)

  const { pdf, notificationId, senderEmail, code } = notify

  //#region only plansScreen = true
  const { customer } = useSelector(state => state.subscription)

  const handlePlansScreen = {
    sendUserCode(showMessage = true) {
      const { name, email } = customer
      if (_.isEmpty(customer.confirmationCode)) {
        axios
          .post(`/verification/create`, { name, email })
          .then(response => {
            showMessage && setError('Código enviado', 'Por favor, verifique o email.', '', false)
          })
          .catch(err => {
            console.error(err)
            setError('Ocorreu um erro')
          })
      } else {
        setError('Seu email já foi validado', '', false)
      }
    },
    confirmUserCode() {
      const { email } = customer
      if (_.isEmpty(customer.confirmationCode)) {
        axios
          .post(`/verification/validate`, {
            code: String(code).toUpperCase(),
            email,
          })
          .then(response => {
            dispatch({
              type: 'CHANGE_SUBSCRIPTION_CUSTOMER_FIELD',
              field: 'confirmationCode',
              payload: code,
            })
            dispatch({ type: 'CHANGE_SUBSCRIPTION_STEP', payload: 3 })
            //showMessage&&setError("Código enviado","Por favor, verifique o email.")
          })
          .catch(err => {
            console.error(err)
            switch (err.response.status) {
              case 404:
                setError('Código não encontrado', '')
                break
              case 403:
                setError('Código incorreto', '')
                break
              default:
                setError('Ocorreu um erro', '')
                break
            }
          })
      } else {
        dispatch({ type: 'CHANGE_SUBSCRIPTION_STEP', payload: 3 })
      }
    },
  }

  useEffect(() => {
    if (plansScreen) {
      if (_.isEmpty(customer.confirmationCode)) {
        handlePlansScreen.sendUserCode(false)
      }
    }
  }, [customer, handlePlansScreen, plansScreen])

  //#endregion

  function setLoading(bool) {
    dispatch({ type: 'CHANGE_NOTIFY_LOADING', payload: bool })
  }

  function setCertification(certification) {
    dispatch({ type: 'CHANGE_NOTIFY_CERTIFICATION', payload: certification })
  }

  function setCertificationName(certificationName) {
    dispatch({
      type: 'CHANGE_NOTIFY_CERTIFICATION_NAME',
      payload: certificationName,
    })
  }

  function setCode(code) {
    dispatch({ type: 'CHANGE_NOTIFY_CODE', payload: code })
  }

  function setConfirmModal(bool) {
    dispatch({ type: 'CHANGE_NOTIFY_CONFIRM_MODAL', payload: bool })
  }

  async function sendForm() {
    setLoading(true)
    let formData = new FormData()
    let document = pdf

    formData.append('notificationId', notificationId)
    formData.append('document', document)
    formData.append('verificationCode', code)

    axios
      .post('/notifications/send', formData)
      .then(response => {
        setConfirmModal(false)
        setStep(5)
        setCertification(response.data.certification)
        setCertificationName(response.data.name)
        setLoading(false)
      })
      .catch(e => {
        if (e.response.status === 403) {
          setError('Inserir texto de erro de cotas aqui')
        } else if (e.response.status === 401) {
          setError('Código de verificação incorreto')
        } else {
          setError('Ocorreu um erro')
        }
        setLoading(false)
      })
  }

  async function resendCode() {
    try {
      await axios.post('/notifications/send-verification', { notificationId })
    } catch (e) {
      console.log(e)
      setError('Ocorreu um erro')
    }
  }

  function handleChange(e) {
    const value = e.target.value
    if (code.length >= CODE_LENGTH.length) {
      return null
    } else {
      setCode((code + value).slice(0, CODE_LENGTH.length))
    }
  }

  function handleKeyUp(e) {
    if (e.key === 'Backspace') {
      setCode(code.slice(0, code.length - 1))
    }
  }

  const CODE_LENGTH = new Array(4).fill(0)

  // if (confirmModal) {
  //   return (
  //     <Confirm confirmModal={confirmModal} pdf={pdf} senderDocument={senderDocument} senderEmail={senderEmail} senderName={senderName} recipientName={recipientName} recipientEmail={recipientEmail} recipientDocument={recipientDocument} onClick={sendForm} loading={loading} setConfirmModal={setConfirmModal} term={term} setTerm={setTerm} />
  //   )
  // }

  return (
    <form className='verify-email' onSubmit={() => code.length === 4 && sendForm()}>
      <h2>Verifique o email {senderEmail}</h2>
      <h3>
        Enviamos um email com um código de confirmação. Por favor, verifique o email "
        {senderEmail || customer.email}" e insira o código.
      </h3>
      <CodeInput
        codeLength={CODE_LENGTH}
        code={code}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      {plansScreen && (
        <>
          <div className='verify-email__resend' onClick={() => handlePlansScreen.sendUserCode()}>
            Reenviar código
          </div>
          <div className='verify-email__buttons'>
            <Button action={true} type='2' height='50px' onClick={() => setStep(1)}>
              Voltar
            </Button>
            <Button
              action={true}
              type={code.length === 4 ? 1 : 4}
              height='50px'
              chevronRight={true}
              onClick={() => code.length === 4 && handlePlansScreen.confirmUserCode()}>
              Continuar
            </Button>
          </div>
        </>
      )}
      {!plansScreen && (
        <>
          <div className='verify-email__resend' onClick={() => resendCode()}>
            Reenviar código
          </div>
          <Button
            scrollLink={true}
            type={code.length === 4 ? 1 : 4}
            width='90%'
            fontSize='16px'
            onClick={() => code.length === 4 && setConfirmModal(true)}>
            Enviar notificação
          </Button>
          <div className='back-button' onClick={() => setStep(3)}>
            Voltar
          </div>
        </>
      )}
    </form>
  )
}

export default VerifyEmail
