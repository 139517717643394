import React, { useEffect, useState, useRef } from "react"

import "./index.scss"

const TextArea = ({
  value = "",
  label = "Sample",
  placeholder = "Sample",
  required = false,
  error = false,
  disabled = false,
  maxLength = 1000,
  autoComplete = true,
  resizeVertical = false,
  resizeHorizontal = false,
  hasLabel = false,
  autoResize = false,
  onBlur = (value = "") => true,
  onChange = (value = "") => {},
  onPressEnter = () => {},
  style,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [hasError, setHasError] = useState(error)
  const [hasValue, setHasValue] = useState(false)
  const textAreaRef = useRef(null)

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = "0px"
      let scrollHeight = textAreaRef.current.scrollHeight
      if(scrollHeight < 52) {
        scrollHeight = 52
      }
      textAreaRef.current.style.height = scrollHeight + "px"
      textAreaRef.current.style.height = `${Math.min(scrollHeight, 100)}px`
      if (scrollHeight > 100) {
        textAreaRef.current.style.overflowY = "scroll"
      } else {
        textAreaRef.current.style.overflowY = "hidden"
      }
    }
  }, [value])

  useEffect(() => setHasError(error), [error])

  function handleOnBlur(evt) {
    const value = evt.target.value
    if (onBlur) {
      const response = onBlur(value)
      if (response === false) {
        setHasError(true)
        textAreaRef.current.setCustomValidity("Valor inválido")
      }
    }
    setIsFocus(false)
  }

  function handleOnChange(evt) {
    const value = evt.target.value

    onChange && onChange(value)
    setHasError(error)
    textAreaRef.current.setCustomValidity("")
  }

  function handlePressEnter(evt) {
    if (evt.key === "Enter") onPressEnter && onPressEnter()
  }

  function returnLabelClassName() {
    let currentClassName = "textarea__label"
    if (isFocus || hasValue || value)
      currentClassName += " textarea__label--open"
    if (isFocus) currentClassName += " textarea__label--focus"
    return currentClassName
  }

  function returnClassName() {
    let currentClassName = "textarea__wrapper"
    if (hasError) currentClassName += " textarea__wrapper--error"
    if (disabled) currentClassName += " textarea__wrapper--disabled"
    if (resizeVertical)
      currentClassName += " textarea__wrapper--resize-vertical"
    if (resizeHorizontal)
      currentClassName += " textarea__wrapper--resize-horizontal"
    if (!hasLabel) currentClassName += " textarea__wrapper--without-label"
    if (autoResize) currentClassName += " textarea__wrapper--auto-resize"
    return currentClassName
  }

  return (
    <div className={returnClassName()}>
      {hasLabel && (
        <span className={returnLabelClassName()}>
          {label}
          {required && " *"}
        </span>
      )}
      <div className="textarea__wrapper__separator" />
      <textarea
        ref={textAreaRef}
        className="textarea"
        value={value}
        placeholder={placeholder}
        style={{ ...style, borderRadius: "8px" }}
        onMouseDown={() => setIsFocus(true)}
        onClick={() => setIsFocus(true)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => setIsFocus(true)}
        onKeyPress={handlePressEnter}
        onAnimationStart={(e) =>
          e.animationName === "onAutoFillStart"
            ? setHasValue(true)
            : setHasValue(false)
        }
        required={required}
        onInvalid={() => setHasError(true)}
        maxLength={maxLength === 'unset' ? 524288 : maxLength}
        autoComplete={autoComplete ? "on" : "none"}
      />
    </div>
  )
}

export default TextArea
