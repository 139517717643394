import React from 'react'
import { useSelector } from 'react-redux'

import Button from '../../../../../../components/Button'
import PlanBenefits from '../../../../../../components/PlanBenefits'
// import PlansIcon from '../../../../../../assets/plansIcon.svg'

import './index.scss'

function SlideInfo({ element, showMonth, handleSelectPlan, user }) {
  const e = element || {
    id: 1,
    header: '',
    description: '',
    value: '',
    numberOfNotifications: '',
    info: [false, false, false, false, false],
    discount: { tag: '', originalValue: '' },
    recommended: false,
  }
  const { singlePlan } = useSelector(state => state.subscription)

  const isCurrentPlan = id => (Number(user?.subscription?.planId) === id ? true : false)

  const isProduct = () => {
    if (e.planId === 'premium') {
      return !isCurrentPlan(e.planId) ? 'Fale conosco ' : 'Cancelar'
    } else if (e.isFree) {
      return !isCurrentPlan(e.planId) ? 'Comece, é grátis ' : 'Cancelar'
    }
    return !isCurrentPlan(e.planId) ? 'Selecionar ' : 'Cancelar'
  }

  const formatPrice = (price = 0) => {
    if (parseFloat(price) % 1) {
      return String(price).replace('.', ',')
    } else {
      return price
    }
  }

  const Separator = ({ premium }) => (
    <div
      className={
        premium
          ? 'step-plans__swiper__slide__info__separator step-plans__swiper__slide__info__separator--premium'
          : 'step-plans__swiper__slide__info__separator'
      }></div>
  )

  return (
    <article>
      <header className='step-plans__swiper__slide__info__section step-plans__swiper__slide__info__header'>
        {e.planId === 'premium' && <span>Via contato</span>}
        {e.planId === 'premium' && (
          <div className='step-plans__swiper__slide__info__header__recommended'>Recomendado</div>
        )}
        <h2>{e.header}</h2>
      </header>

      <Separator premium={e.planId === 'premium'} />

      <section className='step-plans__swiper__slide__info__section step-plans__swiper__slide__info__price'>
        {e.planId !== 'premium' && (
          <>
            <h3>
              R$ <strong>{formatPrice(e.value)}</strong>
              {!singlePlan && <small>/mês</small>}
            </h3>
          </>
        )}
        {e.planId === 'premium' && <h4>{e.value}</h4>}
      </section>

      <Separator premium={e.planId === 'premium'} />

      <section className='step-plans__swiper__slide__info__section step-plans__swiper__slide__info__content'>
        <PlanBenefits element={element} />
      </section>

      <section className='step-plans__swiper__slide__info__section step-plans__swiper__slide__info__action'>
        <Button
          action={true}
          width='100%'
          height='36px'
          type={isCurrentPlan(e.planId) ? 7 : 1}
          onClick={() => handleSelectPlan(e)}>
          {isProduct()}
        </Button>
      </section>

      <Separator premium={e.planId === 'premium'} />

      <footer className='step-plans__swiper__slide__info__section step-plans__swiper__slide__info__footer'>
        <p>
          {singlePlan
            ? 'As notificações compradas de forma unitária são acumulativas.'
            : 'Você pode cancelar sua assinatura mensal quando quiser.'}
        </p>
      </footer>
    </article>
  )
}

export default SlideInfo
